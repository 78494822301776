<script setup lang="ts">
import { computed, inject, ref } from 'vue';
import starGoldImageUrl from '@/assets/icons/star-gold.svg';
import starEmptyImageUrl from '@/assets/icons/star-empty.svg';
import { userRatingsApi } from '@/api/user-ratings';
import successModal from './success-modal.vue';

interface Props {
  productBooking: ProductBooking
}

const props = defineProps<Props>();

const user = inject<User>('user');

const receiver = computed(() => (
  props.productBooking.clientAccount.id === user?.clientAccountId ?
    { account: props.productBooking.partnerAccount, accountType: 'partner' } :
    { account: props.productBooking.clientAccount, accountType: 'client' }));

const stars = ref(0);
const description = ref();
function setStars(value:number) {
  stars.value = value;
}

const showSuccessModal = ref(false);
function toggleSuccessModal() {
  showSuccessModal.value = !showSuccessModal.value;
}

function createRating() {
  const data = {
    stars: stars.value,
    description: description.value,
    senderId: user?.id,
    receiverId: receiver.value.account.userId,
    productBookingId: props.productBooking.id,
  };
  userRatingsApi.create(data).then(() => {
    toggleSuccessModal();
  });
}

function handleSuccess() {
  window.location.href = '/';
}

</script>
<template>
  <the-titlebar :title="`Calificar al ${$t(`accounts.${receiver.accountType}`)}`" />
  <div class="flex flex-col items-center space-y-4 px-6 py-4">
    <img
      v-if="receiver.account.pictureUrl"
      :src="receiver.account.pictureUrl"
      class="h-24 w-24 self-center rounded-full object-cover"
    >
    <img
      v-else
      src="@/assets/icons/user.svg"
      class="h-24 w-24 self-center rounded-full border border-z-gray-200 bg-z-gray-100"
    >
    <div class="flex justify-center space-x-2">
      <button
        v-for="i in 5"
        :key="i"
        @click="setStars(i)"
      >
        <img :src="i <= stars ? starGoldImageUrl : starEmptyImageUrl">
      </button>
    </div>
    <p>
      Bríndale una opinión a {{ receiver.account.firstName }}
    </p>
    <base-input
      v-model="description"
      placeholder="Comentario adicional"
      label="Comentario adicional"
      name="description"
      type="textarea"
    />
    <base-button
      class="w-full"
      :disabled="stars === 0"
      @click="createRating"
    >
      Enviar opinión
    </base-button>
    <base-button
      class="w-full"
      variant="secondary"
      href="/"
    >
      Omitir
    </base-button>
    <success-modal
      :open="showSuccessModal"
      description="Se realizó de forma correcta"
      @confirm="handleSuccess"
    />
  </div>
</template>
