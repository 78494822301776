<script setup lang="ts">

interface Props {
  clientAccount: ClientAccount
  open: boolean
}

defineProps<Props>();

const emit = defineEmits<{
  (e: 'close'): void
}>();

function closeModal() {
  emit('close');
}
</script>
<template>
  <base-modal
    :open="open"
    @close="closeModal"
  >
    <div class="flex w-full flex-col items-center space-y-4">
      <button
        type="button"
        class="absolute self-end"
        @click="closeModal"
      >
        <img
          src="@/assets/icons/close.svg"
          class="h-5 w-5"
        >
      </button>
      <img
        v-if="clientAccount.pictureUrl"
        :src="clientAccount.pictureUrl"
        class="h-24 w-24 self-center rounded-full object-cover"
      >
      <img
        v-else
        src="@/assets/icons/default-profile.svg"
        class="h-24 w-24 rounded-full object-cover"
      >
      <p class="text-lg font-bold text-z-gray-900">
        {{ clientAccount.firstName }} {{ clientAccount.lastName }}
      </p>
    </div>
  </base-modal>
</template>
