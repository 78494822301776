<script setup lang="ts">
import { camelCase } from 'lodash';
import { computed, inject, ref } from 'vue';
import { format } from 'date-fns';
import { currency } from '@/utils/currency';
import { dateDiffInDays } from '@/utils/date-diff-in-days';
import partnerDetailsModal from '../partner-details-modal.vue';
import clientDetailsModal from '../client-details-modal.vue';
import beginDeliveryModal from '../begin-delivery-modal.vue';
import beginReturnModal from '../begin-return-modal.vue';
import newDeliveryModal from '../new-delivery-modal.vue';
import newReturnModal from '../new-return-modal.vue';

interface Props {
  productBooking: ProductBooking;
  approvalTimeLimit: number,
  paymentTimeLimit: number,
  paidTimeLimit: number,
  deliveryToday: boolean,
  returnToday: boolean,
}

const props = defineProps<Props>();

const user = inject<User>('user');

const userType = computed(() =>
  (props.productBooking.partnerAccount.id === user?.partnerAccountId ? 'partner' : 'client'),
);

const otherUserType = computed(() => (userType.value === 'partner' ? 'client' : 'partner'));

const selectedPictureUrl = ref(props.productBooking.productPictures[0].picture.webpMd.url);

const showAddress = computed(() => ['paid', 'delivered', 'complete'].includes(props.productBooking.currentStatus));

function setSelectedPictureUrl(pictureUrl: string) {
  selectedPictureUrl.value = pictureUrl;
}

function handleBack() {
  window.history.back();
}

const blocksNumber = computed(() =>
  dateDiffInDays(new Date(props.productBooking.startDate), new Date(props.productBooking.endDate)) + 1,
);

const showPartnerDetailsModal = ref(false);
function togglePartnerDetailsModal() {
  showPartnerDetailsModal.value = !showPartnerDetailsModal.value;
}

const showClientDetailsModal = ref(false);
function toggleClientDetailsModal() {
  showClientDetailsModal.value = !showClientDetailsModal.value;
}

const showBeginDeliveryModal = ref(false);
function toggleBeginDeliveryModal() {
  showBeginDeliveryModal.value = !showBeginDeliveryModal.value;
}

const showBeginReturnModal = ref(false);
function toggleBeginReturnModal() {
  showBeginReturnModal.value = !showBeginReturnModal.value;
}

function handleViewMore() {
  if (otherUserType.value === 'partner') {
    togglePartnerDetailsModal();
  } else {
    toggleClientDetailsModal();
  }
}

const showClientDeliveryModal = ref(false);
function toggleClientDeliveryModal() {
  showClientDeliveryModal.value = !showClientDeliveryModal.value;
}

function handleClientDeliverySuccess() {
  window.location.href = '/perfil/solicitudes_reservas/cliente#entregados';
}

const showPartnerReturnModal = ref(false);
function togglePartnerReturnModal() {
  showPartnerReturnModal.value = !showPartnerReturnModal.value;
}
</script>
<template>
  <the-layout
    :active-menu="3"
    hide-navbar
  >
    <div class="relative bg-white md:mx-auto md:my-6 md:h-[calc(100vh-72px-24px-24px)] md:max-w-md md:overflow-y-scroll md:rounded">
      <the-titlebar
        :title="`Reserva de ${productBooking.product.name}`"
        class="md:rounded"
        @back="handleBack"
      />
      <div
        class="mb-32 divide-y-2 divide-z-gray-100"
      >
        <div class="flex w-full flex-col px-6 py-4">
          <p class="w-fit self-end rounded bg-z-turquoise-50 px-2 py-0.5 text-sm text-z-turquoise-600">
            {{ $t(`productBooking.status.${camelCase(productBooking.currentStatus)}`) }}
          </p>
          <base-tip
            v-if="deliveryToday && productBooking.productBookingDelivery?.status !== 'complete'"
            title="Hoy es la entrega"
            variant="bold"
            class="mt-3"
          >
            {{ userType === 'partner' ?
              'Hoy debes realizar la entrega de este producto.' :
              'Hoy será la entrega de este producto.' }}
          </base-tip>
          <base-tip
            v-if="returnToday && !['complete', 'reported'].includes(productBooking.productBookingReturn?.status ?? '')"
            title="Hoy es la devolución"
            variant="bold"
            class="mt-3"
          >
            {{ userType === 'partner' ?
              'Hoy es la devolución de este producto.' :
              'No olvides que hoy debes devolver el producto.' }}
          </base-tip>
          <div class="mt-3 flex justify-between rounded-lg bg-z-yellow-50 px-4 py-3 text-xs font-medium text-z-gray-900">
            <p>Desde: <span class="font-normal text-z-gray-800">{{ format(new Date(productBooking.startDate), 'dd/MM/yy') }}</span></p>
            <p>Hasta: <span class="font-normal text-z-gray-800">{{ format(new Date(productBooking.endDate), 'dd/MM/yy') }}</span></p>
          </div>
          <img
            :src="selectedPictureUrl"
            class="mt-3 aspect-square w-full rounded-lg object-cover"
          >
          <div class="mt-4 flex gap-4 overflow-x-scroll scrollbar-hide">
            <button
              v-for="picture in productBooking.productPictures"
              :key="picture.id"
              type="button"
              class="aspect-square w-full shrink-0 basis-[30%]"
              @click="setSelectedPictureUrl(picture.picture.webpMd.url)"
            >
              <img
                :src="picture.picture.webpSm.url"
                class="aspect-square w-full rounded-lg object-cover"
              >
            </button>
          </div>
          <p class="mt-4 font-medium">
            {{ productBooking.product.name }}
          </p>
        </div>
        <div class="px-6 py-4 text-z-gray-900">
          <p class="font-bold">
            Información del precio
          </p>
          <div class="mt-4 flex justify-between">
            <p class="font-medium">
              {{ currency(productBooking.product.dailyPrice) }} x
              {{ blocksNumber }} {{ blocksNumber > 1 ? 'días' : 'día' }}
            </p>
            <p>
              {{ currency(productBooking.amount) }}
            </p>
          </div>
          <div class="mt-1 flex justify-between">
            <p>
              Garantía
            </p>
            <p>
              {{ currency(productBooking.depositAmount) }}
            </p>
          </div>
          <div
            v-if="userType === 'client'"
            class="mt-1 flex justify-between"
          >
            <p>
              Costo por servicio
            </p>
            <p>
              {{ currency(productBooking.serviceCost) }}
            </p>
          </div>
        </div>

        <div class="px-6 py-4">
          <div class="flex items-start gap-2 rounded-lg bg-z-turquoise-50 p-3">
            <img src="@/assets/icons/chat-active.svg">
            <a
              class="flex flex-col"
              :href="`/chats/${productBooking.chatId}`"
            >
              <p class="font-medium text-z-gray-900">
                Envía un mensaje al {{ $t(`accounts.${otherUserType}`).toLowerCase() }}
              </p>
              <p class="text-z-gray-800">
                {{ productBooking[`${otherUserType}Account`].firstName }}
              </p>
            </a>
          </div>
        </div>
        <div class="flex w-full justify-between px-6 py-4">
          <div class="flex flex-col">
            <p class="font-bold text-z-gray-900">
              {{ $t(`accounts.${otherUserType}`) }}: {{ productBooking[`${otherUserType}Account`].firstName }}
            </p>
            <p class="mt-4 font-medium text-z-gray-800">
              Acerca del {{ $t(`accounts.${otherUserType}`).toLowerCase() }}
            </p>
            <button
              class="w-fit text-z-gray-900 underline"
              @click="handleViewMore"
            >
              Ver más
            </button>
          </div>
          <img
            :src="productBooking[`${otherUserType}Account`].pictureUrl"
            class="aspect-square size-10 rounded-full"
          >
        </div>
        <div class="flex w-full flex-col px-6 py-4">
          <p class="font-bold text-z-gray-900">
            Entrega
          </p>
          <p class="mt-4 font-medium text-z-gray-800">
            Tipo de entrega
          </p>
          <p class="text-z-gray-900">
            {{ $t(`product.shippingMethod.${userType}Account.${camelCase(productBooking.shippingMethod)}`) }}
          </p>
          <p class="mt-4 font-medium text-z-gray-800">
            Dirección
          </p>
          <p
            v-if="showAddress || userType === 'partner'"
            class="text-z-gray-900"
          >
            {{ productBooking.address?.displayName }}
          </p>
          <p
            v-else
            class="text-z-gray-900"
          >
            Podrás ver la dirección exacta una vez esté confirmada la reserva.
          </p>
        </div>
        <div
          class="px-6 pb-6 pt-4 text-z-gray-900"
        >
          <p class="font-bold text-z-gray-900">
            Descripción
          </p>
          <p class="mt-4 italic text-z-gray-900">
            {{ productBooking.product.description }}
          </p>
          <p class="mt-4 font-medium text-z-gray-900">
            Categoría
          </p>
          <p
            class="flex text-z-gray-800"
          >
            {{ productBooking.product.category.name }}
          </p>
          <p class="mt-4 font-medium text-z-gray-900">
            Subcategoría
          </p>
          <p
            class="flex text-z-gray-800"
          >
            {{ productBooking.product.subcategory.name }}
          </p>
          <p class="mt-4 font-medium text-z-gray-900">
            Marca
          </p>
          <p
            class="flex text-z-gray-800"
          >
            {{ productBooking.product.brand }}
          </p>
          <p class="mt-4 font-medium text-z-gray-900">
            Modelo
          </p>
          <p
            class="flex text-z-gray-800"
          >
            {{ productBooking.product.model }}
          </p>
          <p class="mt-4 font-medium text-z-gray-900">
            Peso
          </p>
          <p
            class="flex text-z-gray-800"
          >
            {{ $t(`product.weight.${camelCase(productBooking.product.weight)}`) }}
          </p>
        </div>
      </div>
      <template v-if="productBooking.productBookingReports.length === 0 && !productBooking.canceled">
        <div
          v-if="userType === 'partner' && productBooking.currentStatus === 'paid'"
          class="fixed bottom-16 flex w-full flex-col space-y-3 border-t border-z-gray-200 bg-white px-6 py-4 md:bottom-0"
        >
          <base-button
            v-if="!productBooking.productBookingDelivery"
            @click="toggleBeginDeliveryModal"
          >
            Realizar la entrega
          </base-button>
          <base-button
            v-else
            disabled
          >
            Esperando al cliente
          </base-button>
          <base-button
            variant="danger"
            :href="`/perfil/solicitudes_reservas/${productBooking.id}/reportar`"
          >
            Reportar
          </base-button>
        </div>
        <div
          v-if="userType === 'client' && productBooking.productBookingDelivery?.status === 'ongoing'"
          class="fixed bottom-16 flex w-full flex-col space-y-3 border-t border-z-gray-200 bg-white px-6 py-4 md:bottom-0"
        >
          <base-button
            @click="toggleClientDeliveryModal"
          >
            Aceptar la entrega
          </base-button>
          <base-button
            variant="danger"
            :href="`/perfil/solicitudes_reservas/${productBooking.id}/reportar`"
          >
            Reportar
          </base-button>
        </div>
        <div
          v-if="userType === 'client' && productBooking.currentStatus === 'delivered'"
          class="fixed bottom-16 flex w-full flex-col space-y-3 border-t border-z-gray-200 bg-white px-6 py-4 md:bottom-0"
        >
          <base-button
            v-if="!productBooking.productBookingReturn"
            @click="toggleBeginReturnModal"
          >
            Realizar la devolución
          </base-button>
          <base-button
            v-else
            disabled
          >
            Esperando al socio
          </base-button>
        </div>
        <div
          v-if="userType === 'partner' && productBooking.productBookingReturn?.status === 'ongoing'"
          class="fixed bottom-16 flex w-full flex-col space-y-3 border-t border-z-gray-200 bg-white px-6 py-4 md:bottom-0"
        >
          <base-button
            @click="togglePartnerReturnModal"
          >
            Realizar la devolución
          </base-button>
        </div>
      </template>
    </div>
    <partner-details-modal
      v-if="showPartnerDetailsModal"
      :open="showPartnerDetailsModal"
      :partner-account="productBooking.partnerAccount"
      @close="togglePartnerDetailsModal"
    />
    <client-details-modal
      v-if="showClientDetailsModal"
      :open="showClientDetailsModal"
      :client-account="productBooking.clientAccount"
      @close="toggleClientDetailsModal"
    />
    <begin-delivery-modal
      v-if="showBeginDeliveryModal"
      :open="showBeginDeliveryModal"
      :product-booking-id="productBooking.id"
      :chat-id="productBooking.chatId"
      @close="toggleBeginDeliveryModal"
    />
    <begin-return-modal
      v-if="showBeginReturnModal"
      :open="showBeginReturnModal"
      :product-booking-id="productBooking.id"
      :chat-id="productBooking.chatId"
      :address="productBooking.address as AddressData"
      @close="toggleBeginReturnModal"
    />
    <new-delivery-modal
      v-if="showClientDeliveryModal"
      :product-booking="productBooking"
      :open="showClientDeliveryModal"
      skip-accept
      @success="handleClientDeliverySuccess"
    />
    <new-return-modal
      v-if="showPartnerReturnModal"
      :product-booking="productBooking"
      :open="showPartnerReturnModal"
      skip-accept
    />
  </the-layout>
</template>
