<script setup lang="ts">
import { addressesApi } from '@/api/addresses';
import { inject, ref } from 'vue';

interface Props {
  isOpen: boolean
}

defineProps<Props>();

const emit = defineEmits<{
  (e: 'close'): void,
  (e: 'success'): void,
}>();

function closeModal() {
  emit('close');
}

const user = inject<User>('user');

const alias = ref();
const place = ref<google.maps.places.PlaceResult>();
const errorEmpty = ref(false);
const error = ref(false);
function createAddress() {
  if (place.value) {
    try {
      const addressData = {
        street: '',
        streetNumber: '',
        commune: '',
        city: '',
        region: '',
        latitude: 0.0,
        longitude: 0.0,
        alias: alias.value,
      };

      addressData.latitude = place.value.geometry?.location?.lat() ?? 0.0;
      addressData.longitude = place.value.geometry?.location?.lng() ?? 0.0;

      place.value.address_components?.forEach((addressComponent) => {
        switch (addressComponent.types[0]) {
        case 'street_number':
          addressData.streetNumber = addressComponent.long_name;
          break;
        case 'route':
          addressData.street = addressComponent.long_name;
          break;
        case 'locality':
          addressData.commune = addressComponent.long_name;
          break;
        case 'administrative_area_level_1':
          addressData.region = addressComponent.long_name;
          break;
        default:
          // Do nothing
        }
      });

      addressesApi.create({ ...addressData, userId: user?.id }).then(() => emit('success'));
    } catch (e) {
      error.value = true;
    }
  } else {
    errorEmpty.value = true;
  }
}
</script>
<template>
  <base-modal
    :open="isOpen"
  >
    <div class="flex w-full flex-col">
      <div class="flex justify-between">
        <p class="font-medium text-z-gray-900">
          Agregar nueva dirección
        </p>
        <button
          type="button"
          @click="closeModal"
        >
          <img
            src="@/assets/icons/close.svg"
            class="h-5 w-5"
          >
        </button>
      </div>
      <div class="mt-6 space-y-4">
        <base-input
          v-model="alias"
          name="alias"
          placeholder="Casa, oficina, etc. (opcional)"
          label="Casa, oficina, etc. (opcional)"
        />
        <base-google-autocomplete
          v-model="place"
          name="place"
          placeholder="Nueva dirección"
          :error="errorEmpty"
        />
      </div>
      <base-button
        class="mt-6"
        type="button"
        @click="createAddress"
      >
        Agregar dirección
      </base-button>
    </div>
  </base-modal>
</template>
