<script setup lang="ts">
import { onMounted, ref } from 'vue';
import QRCode from 'qrcode';

interface Props {
  url: string,
  size: number,
}

const props = defineProps<Props>();

const qrCanvas = ref();

onMounted(() => {
  QRCode.toCanvas(qrCanvas.value, props.url, {
    errorCorrectionLevel: 'L',
    width: props.size,
    margin: 0,
  });
});
</script>
<template>
  <canvas
    ref="qrCanvas"
    class="h-min max-w-sm object-contain"
  />
</template>
