import { api } from './index';

export const productBookingReturnsApi = {
  create(productBookingId: number, picture?: File) {
    const path = '/api/internal/product_booking_returns';

    return api({
      method: 'post',
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      url: path,
      data: { productBookingId, picture },
    }).then(response => response.data.productBookingReturn as ProductBookingReturn);
  },
  update(productBookingReturnId:number, data:Partial<ProductBookingReturn>, picture?: File) {
    const path = `/api/internal/product_booking_returns/${productBookingReturnId}`;

    return api({
      method: 'patch',
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      url: path,
      data: { ...data, picture },
    });
  },
};
