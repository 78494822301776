<script setup lang="ts">
interface Props {
  isOpen: boolean
}

const emit = defineEmits<{
  (e: 'close'): void
}>();

function closeModal() {
  emit('close');
}

defineProps<Props>();
</script>
<template>
  <base-modal
    :open="isOpen"
  >
    <div class="flex flex-col">
      <div class="flex justify-between">
        <p class="font-bold text-z-gray-900">
          ¿Cómo subir mis fotos?
        </p>
        <button
          type="button"
          @click="closeModal"
        >
          <img
            src="@/assets/icons/close.svg"
            class="h-5 w-5"
          >
        </button>
      </div>
      <div class="mt-8 max-h-[90%] flex-col space-y-6 overflow-y-scroll">
        <div>
          <div class="grid grid-cols-2 gap-2">
            <img src="@/assets/images/picture-modal/image1.png">
            <img src="@/assets/images/picture-modal/image2.png">
          </div>
          <p class="mt-2 font-medium text-z-gray-900">
            La foto principal
          </p>
          <p class="mt-2 text-sm text-z-gray-800">
            La foto debe ser lo más clara posible y sin ningún tipo de objeto que distraiga la vista.
            <br>
            Si vas a utilizar un fondo no olvides que el producto sigue siendo lo más importante.
          </p>
        </div>
        <div>
          <div class="grid grid-cols-2 gap-2">
            <img src="@/assets/images/picture-modal/image3.png">
            <img src="@/assets/images/picture-modal/image4.png">
          </div>
          <p class="mt-2 font-medium text-z-gray-900">
            La marca es importante
          </p>
          <p class="mt-2 text-sm text-z-gray-800">
            Te pedimos que en las fotografías puedas mostrar la marca o la etiqueta del producto,
            para así reafirmar la confianza que tendrán los usuarios a la hora de revisar el producto.
          </p>
        </div>
        <div>
          <div class="grid grid-cols-2 gap-2">
            <img src="@/assets/images/picture-modal/image5.png">
            <img src="@/assets/images/picture-modal/image6.png">
          </div>
          <p class="mt-2 font-medium text-z-gray-900">
            Más fotos, mejor. ¡Todo suma!
          </p>
          <p class="mt-2 text-sm text-z-gray-800">
            Mientras más fotos ocupes, mejor. Idealmente te pedimos que muestres tu producto, su forma de
            utilización y cómo se verá. Todo esto aumentará las posibilidades de que sea arrendado en nuestra plataforma
          </p>
        </div>
      </div>
    </div>
  </base-modal>
</template>
