<script setup lang="ts">
import { useActionCable } from '@/hooks/actioncable';
import { convertKeys } from '@/utils/case-converter';
import { useStore } from '@/store/store';
import { inject, ref } from 'vue';
import defaultProfileIcon from '@/assets/icons/default-profile.svg';
import notificationCard from './notification-card.vue';

interface ChatNotification {
  message: string,
  chatId: number,
  user: {
    name: string,
    pictureUrl?: string,
  }
}

const newNotifications = ref<UserNotification[]>([]);
const newChatNotifications = ref<ChatNotification[]>([]);
const user = inject<User>('user');

const store = useStore();

if (user) {
  useActionCable(
    { channel: 'NotificationsChannel', id: user.id },
    {
      received(eventNewResource:Record<string, unknown>) {
        const newNotification = convertKeys(eventNewResource, 'camelize');

        newNotifications.value.push(newNotification as unknown as UserNotification);
        setTimeout(() => {
          newNotifications.value.pop();
        // eslint-disable-next-line no-magic-numbers
        }, 7000);
      },
    },
  );

  useActionCable(
    { channel: 'ChatNotificationsChannel', id: user.id },
    {
      received(eventNewResource:Record<string, unknown>) {
        const newChatNotification = convertKeys(eventNewResource, 'camelize') as unknown as ChatNotification;

        if (window.location.pathname !== `/chats/${newChatNotification.chatId}`) {
          newChatNotifications.value.push(newChatNotification as unknown as ChatNotification);
          store.setHasNewMessages(true);
          setTimeout(() => {
            newChatNotifications.value.pop();
          // eslint-disable-next-line no-magic-numbers
          }, 5000);
        }
      },
    },
  );
}

function removeNotification(index:number) {
  newNotifications.value.splice(index, 1);
}
</script>
<template>
  <div
    v-if="newNotifications.length > 0"
    class="fixed z-50 w-full space-y-2 p-4 md:right-0 md:max-w-sm md:p-6"
    :class="user?.partnerAccountId ? 'top-0 md:top-[72px]' : 'top-10 md:top-[112px]'"
  >
    <TransitionGroup name="notification-list">
      <notification-card
        v-for="notification, index in newNotifications"
        :key="notification.id"
        :notification="notification"
        class="border border-z-gray-500"
        mode="floating"
        @hide="removeNotification(index)"
      />
    </TransitionGroup>
  </div>
  <TransitionGroup name="chat-notification-list">
    <a
      v-for="chatNotification, index in newChatNotifications"
      :key="index"
      class="fixed left-0 top-0 z-50 flex h-16 w-full bg-white p-3 shadow-md"
      :href="`/chats/${chatNotification.chatId}`"
    >
      <img
        :src="chatNotification.user.pictureUrl ?? defaultProfileIcon"
        class="h-10 w-10 rounded-full bg-z-gray-700"
      >
      <div class="ml-3 flex-col">
        <p class="text-sm font-medium text-z-gray-800">
          {{ chatNotification.user.name }}
        </p>
        <p class="text-sm text-z-gray-900">
          {{ chatNotification.message }}
        </p>
      </div>
    </a>
  </TransitionGroup>
</template>
<style scoped>
.notification-list-move,
.notification-list-enter-active,
.notification-list-leave-active {
  transition: all 0.5s ease;
}
.notification-list-enter-from,
.notification-list-leave-to
{
  opacity: 0;
  transform: translateX(30px);
}
.notification-list-leave-active {
  position: absolute;
}

.chat-notification-list-enter-active,
.chat-notification-list-leave-active {
  transition: 0.5s ease;
}

.chat-notification-list-enter-from,
.chat-notification-list-leave-to {
  transform: translateY(-64px)
}
</style>
