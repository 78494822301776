<script setup lang="ts">
import { computed, ref } from 'vue';
import { Dialog, DialogPanel } from '@headlessui/vue';

interface Props {
  lat: number,
  lng: number,
  zoom: number,
}
const props = defineProps<Props>();

const mapAdjustLat = 0.002;

const randomizedCircleCenter = computed(() => {
  const min = -0.0008;
  const max = 0.0008;
  const randomLat = props.lat + Math.random() * (max - min) + min;
  const randomLng = props.lng + Math.random() * (max - min) + min;

  return { lat: randomLat, lng: randomLng };
});

const randomizedMapCenter = computed(() => (
  { lat: randomizedCircleCenter.value.lat - mapAdjustLat, lng: randomizedCircleCenter.value.lng }
));

const mapOptions = {
  styles: [
    {
      'featureType': 'administrative.land_parcel',
      'elementType': 'labels',
      'stylers': [
        {
          'visibility': 'off',
        },
      ],
    },
    {
      'featureType': 'poi',
      'elementType': 'labels.text',
      'stylers': [
        {
          'visibility': 'off',
        },
      ],
    },
    {
      'featureType': 'poi.attraction',
      'elementType': 'labels.icon',
      'stylers': [
        {
          'visibility': 'off',
        },
      ],
    },
    {
      'featureType': 'poi.business',
      'stylers': [
        {
          'visibility': 'off',
        },
      ],
    },
    {
      'featureType': 'poi.medical',
      'stylers': [
        {
          'visibility': 'off',
        },
      ],
    },
    {
      'featureType': 'poi.park',
      'elementType': 'labels.icon',
      'stylers': [
        {
          'visibility': 'off',
        },
      ],
    },
    {
      'featureType': 'poi.place_of_worship',
      'elementType': 'labels.icon',
      'stylers': [
        {
          'visibility': 'off',
        },
      ],
    },
    {
      'featureType': 'poi.school',
      'stylers': [
        {
          'visibility': 'off',
        },
      ],
    },
    {
      'featureType': 'poi.sports_complex',
      'elementType': 'labels.icon',
      'stylers': [
        {
          'visibility': 'off',
        },
      ],
    },
    {
      'featureType': 'road',
      'elementType': 'labels.icon',
      'stylers': [
        {
          'visibility': 'off',
        },
      ],
    },
    {
      'featureType': 'road.local',
      'elementType': 'labels',
      'stylers': [
        {
          'visibility': 'off',
        },
      ],
    },
    {
      'featureType': 'transit',
      'stylers': [
        {
          'visibility': 'off',
        },
      ],
    },
  ],
  zoomControl: false,
  mapTypeControl: false,
  scaleControl: false,
  streetViewControl: false,
  rotateControl: false,
  fullscreenControl: false,

};

const circleOptions = {
  strokeColor: '#4479FF',
  strokeOpacity: 0.4,
  strokeWeight: 1,
  fillColor: '#4479FF',
  fillOpacity: 0.2,
};

const showMapDialog = ref(false);

function toggleMapDialog() {
  showMapDialog.value = !showMapDialog.value;
}
</script>

<template>
  <button
    class="block h-28 w-full overflow-y-hidden rounded-lg"
    @click="toggleMapDialog"
  >
    <GMapMap
      :center="randomizedMapCenter"
      :zoom="zoom"
      :options="mapOptions"
      map-type-id="terrain"
      class="pointer-events-none h-[140px] w-full"
    >
      <GMapCircle
        :radius="300"
        :center="randomizedCircleCenter"
        :options="circleOptions"
      />
    </GMapMap>
  </button>
  <Dialog
    v-if="showMapDialog"
    :open="showMapDialog"
    class="fixed inset-0 z-50 flex h-full w-full items-center justify-center md:mx-auto md:max-w-md"
    @close="toggleMapDialog"
  >
    <div
      class="fixed inset-0 bg-black opacity-80"
    />
    <DialogPanel class="absolute left-0 top-0 m-4 flex h-[calc(100%-32px)] w-[calc(100%-32px)] overflow-y-hidden rounded-md bg-white">
      <GMapMap
        :center="randomizedMapCenter"
        :zoom="zoom"
        :options="mapOptions"
        map-type-id="terrain"
        class="h-[calc(100%+28px)] w-full"
      >
        <GMapCircle
          :radius="300"
          :center="randomizedCircleCenter"
          :options="circleOptions"
        />
      </GMapMap>
      <button
        class="absolute right-3 top-3 rounded-full border border-z-gray-200 bg-white p-2 shadow-sm"
        @click="toggleMapDialog"
      >
        <img src="@/assets/icons/close.svg">
      </button>
    </DialogPanel>
  </Dialog>
</template>
