import { api } from './index';

function update(userId:number, data: Partial<UserFormData>, picture?: File) {
  const path = `/api/internal/users/${userId}`;

  return api({
    method: 'patch',
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    url: path,
    data: { ...data, picture },
  });
}
export { update };
