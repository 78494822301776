<script setup lang="ts">
import { computed, ref } from 'vue';
import { add, format } from 'date-fns';
import { flatten } from 'lodash';
import type { DatePickerInstance } from '@vuepic/vue-datepicker';

interface Props {
  open: boolean
  minRange: number
  maxRange: number | null
  disabledDates: Date[]
}

const props = defineProps<Props>();

const bookingRange = ref();

const emit = defineEmits<{
  (e: 'updateRange', value: Date[]): void,
  (e: 'close'): void
}>();

const allowSameDay = import.meta.env.VITE_ALLOW_SAME_DAY === 'true';
// eslint-disable-next-line no-magic-numbers
const minimumDate = add(new Date(), { days: allowSameDay ? 0 : 2 });

function handleRange(val:Date[]|Date) {
  let flatDateArray = flatten([val]);

  if (flatDateArray.length === 1) {
    flatDateArray = [...flatDateArray, ...flatDateArray];
  }

  emit('updateRange', flatDateArray);
}

const rangeSize = ref(props.minRange);

const rangeOptions = computed(() => (rangeSize.value === 1 ? false : { autoRange: rangeSize.value - 1 }));

const startTime = computed(() => {
  const timeOption = { hours: 12, minutes: 0, seconds: 0 };
  if (rangeSize.value === 1) {
    return timeOption;
  }

  return [timeOption, timeOption];
});

const datePicker = ref<DatePickerInstance>(null);
function selectDate() {
  if (datePicker.value) datePicker.value.selectDate();
}
</script>
<template>
  <base-modal
    :open="open"
    @close="$emit('close')"
  >
    <div class="relative flex w-full flex-col">
      <p class="text-lg font-bold text-z-gray-800">
        Paso 1
      </p>
      <p class="font-medium">
        ¿Cuántos días deseas arrendar?
      </p>
      <div class="mt-2 flex w-full justify-between text-sm text-z-gray-600">
        <p>Mínimo: <span class="font-medium text-z-gray-700">{{ minRange }}</span></p>
        <p>Máximo: <span class="font-medium text-z-gray-700">{{ maxRange === 0 || !maxRange ? 'sin límite' : maxRange }}</span></p>
      </div>
      <base-counter
        v-model="rangeSize"
        name="rangeSize"
        :min="props.minRange"
        :max="props.maxRange ? props.maxRange : undefined"
        label="Selecciona la cantidad de días de arriendo"
        unit="días"
        hide-label
      />
      <p class="mt-4 text-lg font-bold text-z-gray-800">
        Paso 2
      </p>
      <p class="font-medium">
        Selecciona la fecha
      </p>

      <div class="relative flex h-[340px] w-full flex-col items-center">
        <VueDatePicker
          ref="datePicker"
          v-model="bookingRange"
          inline
          :range="rangeOptions"
          :no-today="!allowSameDay"
          locale="es"
          :enable-time-picker="false"
          :min-date="minimumDate"
          :disabled-dates="disabledDates"
          :day-names="['L', 'M', 'M', 'J','V','S','D']"
          :start-time="startTime"
          class="mt-4"
          vertical
          @update:model-value="(val:Date[]|Date) => handleRange(val)"
        >
          <template #month-year="{month, year}">
            <p class="ml-3">
              <span class="capitalize">{{ format(new Date().setMonth(month), 'MMMM') }}</span> de {{ year }}
            </p>
          </template>
          <template #action-row />
        </VueDatePicker>
      </div>
      <div class="flex w-full">
        <base-button
          class="w-full"
          @click="selectDate"
        >
          Seleccionar fecha
        </base-button>
      </div>
    </div>
  </base-modal>
</template>
<style>
.dp__menu {
  border: 0 !important;
}
.dp__theme_light {
  --dp-primary-color: #00a48f;
  --dp-range-between-dates-background-color: var(--dp-primary-color);
  --dp-range-between-dates-text-color: var(--dp-primary-text-color);
  --dp-text-color: #202020;
  --dp-disabled-color-text: #9f9f9f;
  --dp-border-color: #f2f2f2;
}
:root {
  --dp-cell-border-radius: 100px;
  --dp-font-family: 'Poppins', sans-serif;
  --dp-row-margin: 8px 0;
  --dp-cell-size: 100%;
  --dp-menu-padding: 0;
  --dp-action-row-padding: 0;
}
.dp__calendar_row {
  height: 32px;
}
.dp__calendar_item {
  height: 32px;
  width: 100%;
}
.dp__cell_inner {
  border: 0px;
}
.dp__range_start {
  border-end-start-radius: 100px;
  border-start-start-radius: 100px;
}
.dp__range_end {
  border-end-end-radius: 100px;
  border-start-end-radius: 100px;
}
.dp__calendar_header {
  font-weight: normal;
  height: 32px;
}
.dp__main {
  justify-content: center;
  position: relative;
}
.dp__action_row {
  max-width: 10px;
}
</style>
