<script setup lang="ts">
import { computed, onMounted, ref } from 'vue';
import formatDistanceToNow from 'date-fns/formatDistanceToNow';
import { userRatingsApi } from '@/api/user-ratings';

interface Props {
  partnerAccount: PartnerAccount
  open: boolean
}

const props = defineProps<Props>();

const emit = defineEmits<{
  (e: 'close'): void
}>();

function closeModal() {
  emit('close');
}

const ratings = ref<UserRating[]>();

const ratingsWithDescription = computed(() => ratings.value?.filter((rating) => !!rating.description));

onMounted(() => {
  userRatingsApi.index(props.partnerAccount.userId)
    .then(response => {
      ratings.value = response;
    });
});
</script>
<template>
  <base-modal
    :open="open"
    @close="closeModal"
  >
    <div class="flex w-full flex-col items-center space-y-4">
      <button
        type="button"
        class="absolute self-end"
        @click="closeModal"
      >
        <img
          src="@/assets/icons/close.svg"
          class="h-5 w-5"
        >
      </button>
      <img
        v-if="partnerAccount.pictureUrl"
        :src="partnerAccount.pictureUrl"
        class="h-24 w-24 self-center rounded-full object-cover"
      >
      <img
        v-else
        src="@/assets/icons/default-profile.svg"
        class="h-24 w-24 rounded-full object-cover"
      >
      <p class="text-lg font-bold text-z-gray-900">
        {{ partnerAccount.firstName }} {{ partnerAccount.lastName }}
      </p>
      <div class="space-y-2">
        <p class="text-center text-z-gray-900">
          Calificaciones ({{ ratings?.length ?? 0 }})
        </p>
        <base-stars :value="partnerAccount.ratingAverage" />
      </div>
      <p class="text-center">
        Opiniones ({{ ratingsWithDescription?.length ?? 0 }})
      </p>
      <div class="w-full space-y-4 overflow-y-scroll scrollbar-hide">
        <div
          v-for="rating in ratingsWithDescription"
          :key="rating.id"
          class="flex space-x-6 rounded-lg border border-z-gray-100 px-4 py-3"
        >
          <img
            v-if="rating.senderPicture"
            :src="rating.senderPicture"
            class="h-10 w-10 self-center rounded-full object-cover"
          >
          <img
            v-else
            src="@/assets/icons/default-profile.svg"
            class="h-10 w-10 rounded-full object-cover"
          >
          <div class="flex flex-col space-y-1">
            <p class="text-sm font-medium italic text-z-gray-800">
              "{{ rating.description }}"
            </p>
            <p class="text-sm font-medium text-z-gray-500">
              {{ rating.senderName }}
            </p>
            <p class="text-sm font-light text-z-gray-500">
              hace {{ formatDistanceToNow(new Date(rating.createdAt)) }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </base-modal>
</template>
