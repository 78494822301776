import { api } from './index';

export const productBookingDeliveriesApi = {
  create(productBookingId: number) {
    const path = '/api/internal/product_booking_deliveries';

    return api({
      method: 'post',
      url: path,
      data: { productBookingId },
    }).then(response => response.data.productBookingDelivery as ProductBookingDelivery);
  },
  update(productBookingDeliveryId:number, data:Partial<ProductBookingDelivery>, picture?: File) {
    const path = `/api/internal/product_booking_deliveries/${productBookingDeliveryId}`;

    return api({
      method: 'patch',
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      url: path,
      data: { ...data, picture },
    });
  },
};
