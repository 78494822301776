<script setup lang="ts">
import { computed } from 'vue';

interface Props {
  title?: string;
  variant?: 'normal' | 'bold'
  color?: 'yellow' | 'red'
}
const props = withDefaults(defineProps<Props>(), {
  title: 'Tip',
  variant: 'normal',
  color: 'yellow',
});

const titleClasses = computed(() => {
  const colors = {
    normal: 'text-z-gray-900',
    bold: props.color === 'yellow' ? 'text-z-yellow-600 font-medium' : 'text-z-red font-medium',
  };

  return colors[props.variant];
});
const bgClasses = computed(() => {
  const colors = {
    yellow: 'bg-z-yellow-50',
    red: 'bg-z-cerise-50',
  };

  return colors[props.color];
});
</script>
<template>
  <div
    class="flex items-start space-x-2 rounded-xl p-4"
    :class="bgClasses"
  >
    <slot name="icon">
      <img
        src="@/assets/icons/idea.svg"
        class="h-6 w-6"
      >
    </slot>
    <div class="flex flex-col space-y-2">
      <p
        v-if="title"
        :class="titleClasses"
      >
        {{ title }}
      </p>

      <p class="text-sm leading-5 text-z-gray-800">
        <slot />
      </p>
    </div>
  </div>
</template>
