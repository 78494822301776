<script setup lang="ts">
import { productBookingDeliveriesApi } from '@/api/product-booking-deliveries';
import { ref } from 'vue';

interface Props {
  open: boolean;
  productBookingId:number;
  chatId:number;
}
const props = defineProps<Props>();

const emit = defineEmits<{
  (e: 'close'): void
}>();

function closeModal() {
  emit('close');
  window.location.reload();
}
const showAwaitingMessage = ref(false);

function beginDelivery() {
  productBookingDeliveriesApi.create(props.productBookingId).then(() => {
    showAwaitingMessage.value = true;
  });
}
</script>
<template>
  <base-modal
    :open="open"
  >
    <div
      v-if="showAwaitingMessage"
      class="flex w-full flex-col items-center"
    >
      <img
        src="@/assets/icons/information-circle.svg"
        class="w-24 "
      >
      <p class="mt-2 font-bold text-z-gray-900">
        A la espera del cliente
      </p>
      <p class="mt-2 text-center text-z-gray-800">
        Para que se complete la entrega, el cliente debe aceptar la <span class="font-medium">solicitud de entrega</span>.
      </p>
      <base-button
        class="mt-8 w-full"
        :href="`/chats/${chatId}`"
      >
        Ir al chat
      </base-button>
    </div>
    <div
      v-else
      class="flex w-full flex-col items-center"
    >
      <img
        src="@/assets/icons/information-circle.svg"
        class="w-24 "
      >
      <p class="mt-2 font-bold text-z-gray-900">
        Importante
      </p>
      <p class="mt-2 text-center text-z-gray-800">
        Debes estar en el punto de entrega con el cliente
      </p>
      <base-button
        class="mt-8 w-full"
        @click="beginDelivery"
      >
        Hacer la entrega
      </base-button>
      <base-button
        variant="danger"
        class="mt-3 w-full"
        @click="closeModal"
      >
        Cancelar
      </base-button>
    </div>
  </base-modal>
</template>
