<script setup lang="ts">
import { camelCase } from 'lodash';
import { formatDate } from '@/utils/format-date';
import { currency } from '@/utils/currency';
import { computed, inject, ref } from 'vue';
import partnerDetailsModal from '../partner-details-modal.vue';

interface Props {
  productBooking: ProductBooking;
  accountType: 'partner' | 'client'
  hideTags?: boolean
}

const props = defineProps<Props>();

const showPartnerDetailsModal = ref(false);
function togglePartnerDetailsModal() {
  showPartnerDetailsModal.value = !showPartnerDetailsModal.value;
}
const partnerServiceCost = inject<number>('partnerServiceCost');

// eslint-disable-next-line no-magic-numbers
const partnerEarnings = computed(() => props.productBooking.amount * (100 - (partnerServiceCost ?? 0)) / 100);
</script>
<template>
  <div class="flex flex-col py-4">
    <div
      v-if="!hideTags"
      class="flex"
      :class="productBooking.currentStatus === 'paid' ? 'justify-between' : 'justify-end'"
    >
      <template v-if="productBooking.currentStatus === 'paid'">
        <div
          v-if="productBooking.productBookingReports.length > 0"
          class="flex items-center space-x-1 self-start rounded bg-z-cerise-200 px-2 py-0.5 text-center text-xs text-z-cerise-600"
        >
          <img src="@/assets/icons/warning-alt.svg">
          <p>
            Reportado
          </p>
        </div>
        <div
          v-else-if="productBooking.canceled"
          class="flex space-x-1 self-start rounded bg-z-cerise-200 px-2 py-0.5 text-center text-xs text-z-cerise-600"
        >
          <img
            src="@/assets/icons/cross-circle.svg"
            class="w-4"
          >
          <p>
            Cancelado
          </p>
        </div>
        <div
          v-else
          class="flex space-x-1 self-start rounded bg-z-turquoise-50 px-2 py-0.5 text-center text-xs text-z-turquoise-600"
        >
          <img src="@/assets/icons/check-circle-alt.svg">
          <p>
            Aceptado
          </p>
        </div>
      </template>
      <template v-if="['delivered', 'complete'].includes(productBooking.currentStatus)">
        <a
          v-if="productBooking.productBookingReports.length > 0"
          class="flex items-center space-x-1 self-start rounded bg-z-cerise-200 px-2 py-0.5 text-center text-xs text-z-cerise-600"
          :href="`/reportes/${productBooking.productBookingReports[0].id}`"
        >
          <img src="@/assets/icons/warning-alt.svg">
          <p>
            Reportado
          </p>
          <img src="@/assets/icons/chevron-right-red.svg">
        </a>
      </template>
      <template v-else>
        <p class="w-fit self-end rounded bg-z-turquoise-50 px-2 py-0.5 text-center text-xs text-z-turquoise-600">
          {{ $t(`product.condition.${camelCase(productBooking.product.condition)}`) }}
        </p>
      </template>
    </div>
    <div class="flex space-x-4 pt-3">
      <img
        :src="productBooking.productPictures[0].picture.webpSm.url"
        class="h-[72px] w-[72px] rounded-lg border border-z-gray-50 object-cover"
      >
      <div class="flex w-full flex-col">
        <p class="line-clamp-1 font-medium">
          {{ productBooking.product.name }}
        </p>
        <p class="mt-1 text-xs text-z-gray-800">
          {{ formatDate(productBooking.startDate) }} - {{ formatDate(productBooking.endDate) }}
        </p>
        <p
          v-if="accountType === 'partner'"
          class="mt-0.5 text-sm font-medium text-z-gray-900"
        >
          ganarás {{ currency(partnerEarnings) }}
        </p>
        <a
          class="mt-1 flex text-xs text-z-gray-800 underline underline-offset-2"
          :href="`/perfil/solicitudes_reservas/${productBooking.id}`"
        >
          <p>Ver más</p>
          <img
            src="@/assets/icons/chevron-right.svg"
            class="mt-0.5 w-3 brightness-50 grayscale"
          >
        </a>
        <div class="mt-3 flex w-full items-center">
          <div
            v-if="accountType === 'partner'"
            class="flex items-center"
          >
            <img
              :src="productBooking.clientAccount.pictureUrl"
              class="h-6 w-6 rounded-full object-cover"
            >
            <p class="ml-2 text-xs">
              {{ productBooking.currentStatus === 'awaiting_approval' ? 'Solicitado' : 'Reservado' }}
              por
              {{ productBooking.clientAccount.firstName }}
            </p>
          </div>
          <button
            v-else
            class="flex items-center"
            @click="togglePartnerDetailsModal"
          >
            <img
              :src="productBooking.partnerAccount.pictureUrl"
              class="h-6 w-6 rounded-full object-cover"
            >
            <p class="ml-2 text-xs">
              Dueño:
              {{ productBooking.partnerAccount.firstName }}
            </p>
          </button>
          <a
            class="ml-1"
            :href="`/chats/${productBooking.chatId}`"
          >
            <img
              src="@/assets/icons/chat-active.svg"
              class="h-5"
            >
          </a>
        </div>
      </div>
    </div>
  </div>
  <partner-details-modal
    v-if="showPartnerDetailsModal"
    :open="showPartnerDetailsModal"
    :partner-account="productBooking.partnerAccount"
    @close="togglePartnerDetailsModal"
  />
</template>
