<script setup lang="ts">
import { Disclosure, DisclosureButton, DisclosurePanel } from '@headlessui/vue';
import { forEach, groupBy, sortBy } from 'lodash';
import { computed } from 'vue';

interface Props {
  questions: FrequentlyAskedQuestion[]
}
const props = defineProps<Props>();

const groupedQuestions = computed(() => {
  const groups = groupBy(props.questions, 'section');

  forEach(groups, (group, key) => {
    groups[key] = sortBy(group, 'position');
  });

  return groups;
});
</script>
<template>
  <the-layout :active-menu="0">
    <div class="flex flex-col px-6 pb-4 pt-6 md:order-last md:mx-[16%] md:mt-12 md:rounded md:bg-white md:pt-8">
      <p class="text-xl font-medium text-z-gray-900">
        Preguntas Frecuentes
      </p>
      <div
        v-for="group, key in groupedQuestions"
        :key="key"
        class="mt-8 flex flex-col pb-2"
      >
        <p class="text-lg font-medium text-z-gray-900">
          {{ $t(`frequentlyAskedQuestion.section.${key}`) }} Zirkular
        </p>
        <Disclosure
          v-for="question, index in group"
          :key="question.id"
          v-slot="{ open }"
        >
          <DisclosureButton class="my-2 flex items-start justify-between rounded-lg bg-z-turquoise-50 p-3 font-medium text-z-turquoise-600">
            <p class="text-left">
              {{ index + 1 }}. {{ question.question }}
            </p>
            <img
              src="@/assets/icons/chevron-right.svg"
              class="w-6"
              :class="open ? '-rotate-90' : 'rotate-90'"
            >
          </DisclosureButton>
          <DisclosurePanel class="mb-6 text-sm text-z-gray-800 md:px-3 md:text-base">
            <p>{{ question.answer }}</p>
          </DisclosurePanel>
        </Disclosure>
      </div>
    </div>
  </the-layout>
</template>
