<script setup lang="ts">
import { productBookingsApi } from '@/api/product-bookings';
import { add, formatDistanceToNowStrict } from 'date-fns';
import { computed, inject, ref } from 'vue';

interface Props {
  productBooking: ProductBooking
}

const props = defineProps<Props>();

const approvalTimeLimit = inject<number>('approvalTimeLimit');

const remainingTime = computed(() => formatDistanceToNowStrict(add(
  new Date(props.productBooking.updatedAt),
  { hours: approvalTimeLimit },
)));

const loading = ref(false);
const error = ref(false);

const showCancelModal = ref(false);

function toggleCancelModal() {
  showCancelModal.value = !showCancelModal.value;
}

async function cancelBooking() {
  try {
    loading.value = true;
    await productBookingsApi.update(props.productBooking.id, { canceled: true, canceledBy: 'client' })
      .then(() => {
        window.location.reload();
      });
  } catch (e) {
    error.value = true;
  } finally {
    loading.value = false;
  }
}
</script>
<template>
  <div class="flex flex-col">
    <base-tip
      title="En espera"
      variant="bold"
    >
      El socio tiene <span class="font-semibold">{{ remainingTime }}</span> restantes para responder tu solicitud.
      Si no lo hace, será cancelada automáticamente.
    </base-tip>
    <base-button
      variant="danger"
      size="sm"
      class="mt-3"
      @click="toggleCancelModal"
    >
      Cancelar solicitud
    </base-button>
    <base-modal :open="showCancelModal">
      <div class="relative flex w-full flex-col items-center">
        <button
          class="absolute right-0 top-0"
          @click="toggleCancelModal"
        >
          <img src="@/assets/icons/close.svg">
        </button>
        <img
          src="@/assets/icons/warning-alt-2.svg"
          class="h-20 w-20"
        >
        <p class="mt-2 text-center text-lg font-bold text-z-gray-900">
          ¿Estás seguro que deseas cancelar tu solicitud?
        </p>
        <base-button
          class="mt-4 w-full"
          variant="danger"
          @click="cancelBooking"
        >
          Cancelar solicitud
        </base-button>
      </div>
    </base-modal>
  </div>
</template>
