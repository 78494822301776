<script setup lang="ts">
interface Props {
  addresses?: AddressData[]
}

defineProps<Props>();

function handleBack() {
  window.history.back();
}
</script>
<template>
  <the-layout
    hide-navbar
    :active-menu="3"
  >
    <div
      class="flex w-full flex-col bg-white md:mx-auto md:h-[calc(100vh-72px-24px-24px)] md:max-w-md md:overflow-y-scroll md:rounded"
    >
      <the-titlebar
        title="Mis direcciones"
        @back="handleBack"
      />
      <div
        v-if="!addresses"
        class="flex w-full flex-col p-6"
      >
        <div class="flex flex-col items-center rounded-lg bg-z-gray-25 p-4">
          <img
            src="@/assets/icons/location.svg"
            class="h-6 w-6"
          >
          <p class="mt-2 text-z-gray-900">
            No hay una dirección vinculada
          </p>
          <base-button
            class="mt-6 w-full space-x-2"
            href="/perfil/direcciones/crear"
          >
            <p>Vincular dirección</p>
            <img
              src="@/assets/icons/arrow-small-right.svg"
              class="h-6 w-6"
            >
          </base-button>
        </div>
      </div>
      <div
        v-else
        class="flex flex-col"
      >
        <div class="mb-20 divide-y divide-z-gray-100">
          <div
            v-for="address in addresses"
            :key="address.id"
            class="flex w-full flex-col space-y-4 p-6"
          >
            <div class="space-y-1">
              <p
                v-if="address.alias"
                class="w-fit rounded bg-z-gray-100 p-2 text-xs"
              >
                {{ address.alias }}
              </p>
              <p class="line-clamp-1 text-z-gray-800">
                {{ address.displayName }}
              </p>
            </div>
            <div class="rounded-xl bg-z-gray-100 p-4 text-sm text-z-gray-800">
              <p v-if="address.productsCount > 0">
                Hay
                <span class="font-medium">{{ address.productsCount }} {{ address.productsCount === 1 ? 'producto' : 'productos' }}</span>
                asociados a esta dirección.
              </p>
              <p v-else>
                Esta dirección no tiene productos asociados.
              </p>
              <a
                v-if="address.productsCount > 0"
                class="mt-2 font-medium underline"
                :href="`/perfil/direcciones/${address.id}`"
              >
                ver más
              </a>
            </div>
            <base-button
              class="h-8 w-fit px-6 text-sm"
              :href="`/perfil/direcciones/${address.id}/editar`"
            >
              Editar dirección
            </base-button>
          </div>
        </div>
        <div class="fixed bottom-16 w-full flex-col bg-white px-6 py-2 md:bottom-0 md:max-w-md">
          <base-button
            class="w-full"
            href="/perfil/direcciones/crear"
          >
            Agregar nueva dirección
          </base-button>
        </div>
      </div>
    </div>
  </the-layout>
</template>
