import { api } from './index';

export const bankEntitiesApi = {
  index() {
    const path = '/api/internal/bank_entities';

    return api({
      method: 'get',
      url: path,
    });
  },
};
