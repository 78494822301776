import { api } from './index';

export const bankAccountsApi = {
  create(data: BankAccountFormData) {
    const path = '/api/internal/bank_accounts';

    return api({
      method: 'post',
      url: path,
      data,
    });
  },
  update(id:number, data: Partial<BankAccountFormData>) {
    const path = `/api/internal/bank_accounts/${id}`;

    return api({
      method: 'patch',
      url: path,
      data,
    });
  },
};
