<script setup lang="ts">
import { productBookingsApi, type ProductBookingFormData } from '@/api/product-bookings';
import { add, formatDistanceToNowStrict } from 'date-fns';
import { computed, inject, ref } from 'vue';
import { object, string } from 'yup';
import { formatDate } from '@/utils/format-date';
import { Dialog, DialogPanel } from '@headlessui/vue';

const REJECT_OPTIONS = [
  { id: 'product_unavailable', name: 'El artículo no está disponible en esa fecha' },
  { id: 'partner_unavailable', name: 'Yo no estoy disponible para la entrega' },
  { id: 'product_does_not_exist', name: 'El producto ya no existe' },
  { id: 'other', name: 'Otro' },
];

interface Props {
  productBooking: ProductBooking
}

const user = inject<User>('user');

const props = defineProps<Props>();

const approvalTimeLimit = inject<number>('approvalTimeLimit');

const loading = ref(false);
const error = ref(false);

const showApprovedModal = ref(false);
const showRejectedModal = ref(false);
const showBankAccountModal = ref(false);
const showRejectForm = ref(false);

function toggleApprovedModal() {
  showApprovedModal.value = !showApprovedModal.value;
}
function toggleRejectedModal() {
  showRejectedModal.value = !showRejectedModal.value;
}
function toggleBankAccountModal() {
  showBankAccountModal.value = !showBankAccountModal.value;
}
function toggleRejectForm() {
  showRejectForm.value = !showRejectForm.value;
}

async function updateBooking(data:Partial<ProductBookingFormData>) {
  try {
    loading.value = true;
    await productBookingsApi.update(props.productBooking.id, data);
  } catch (e) {
    error.value = true;
  } finally {
    loading.value = false;
  }
}
function approveBooking() {
  if (user?.hasBankAccount && user?.verified) {
    try {
      updateBooking({ currentStatus: 'partner_approved' })
        .then(() => toggleApprovedModal());
    } catch (e) {
      error.value = true;
    }
  } else {
    toggleBankAccountModal();
  }
}

function handleApproved() {
  window.location.replace('#esperando-pago');
  window.location.reload();
}

function handleRejected() {
  toggleRejectedModal();
  toggleRejectForm();
}

const remainingTime = computed(() => formatDistanceToNowStrict(
  add(new Date(props.productBooking.updatedAt), { hours: approvalTimeLimit }),
));

const rejectReason = ref('0');
const rejectMessage = ref('');

const formRules = object().shape({
  rejectReason: string().required('Debes seleccionar un rango')
    .oneOf(REJECT_OPTIONS.map((el) => el.id), 'Debes seleccionar un rango'),
  rejectMessage: string().notRequired(),
});

const showRejectSuccessModal = ref(false);
function toggleRejectSuccessModal() {
  showRejectSuccessModal.value = !showRejectSuccessModal.value;
}

function handleRejectSuccess() {
  window.location.reload();
}
function rejectBooking() {
  try {
    updateBooking({
      currentStatus: 'partner_rejected',
      rejectMessage: {
        reason: rejectReason.value,
        message: rejectMessage.value,
      },
    })
      .then(() => toggleRejectSuccessModal());
  } catch (e) {
    error.value = true;
  }
}
</script>
<template>
  <div class="flex flex-col">
    <base-tip
      title="Tiempo restante"
      variant="bold"
    >
      <template #icon>
        <img
          src="@/assets/icons/warning.svg"
          class="h-6 w-6"
        >
      </template>
      Tienes <span class="font-semibold">{{ remainingTime }}</span> restantes para aceptar o rechazar la solicitud.
    </base-tip>
    <base-button
      size="sm"
      class="mt-3"
      @click="approveBooking"
    >
      Aceptar
    </base-button>
    <base-button
      variant="danger"
      size="sm"
      class="mt-3"
      @click="toggleRejectedModal"
    >
      Rechazar
    </base-button>
    <base-modal
      v-if="showBankAccountModal"
      :open="showBankAccountModal"
    >
      <div class="flex w-full flex-col items-center">
        <img
          src="@/assets/icons/information-circle.svg"
          class="h-20 w-20"
        >
        <p class="mt-2 text-lg font-bold text-z-gray-900">
          Antes de ello...
        </p>
        <template v-if="user?.verified">
          <p class="mt-2 text-center text-z-gray-800">
            Es importante que configures una cuenta para realizar los depósitos.
          </p>
          <base-button
            href="/perfil/datos_bancarios?redirect=true"
            class="mt-4 w-full"
          >
            <p>
              Ir a configurar mis pagos
            </p>
            <img
              src="@/assets/icons/arrow-small-right.svg"
              class="ml-2"
            >
          </base-button>
        </template>
        <template v-else>
          <p class="mt-2 text-center text-z-gray-800">
            Por motivos de seguridad, debes verificar tu identidad.
          </p>
          <base-button
            href="/perfil/verificar?from=awaiting_approval&redirect_to=/perfil/solicitudes_reservas/socio"
            class="mt-4 w-full"
          >
            <p>
              Verificar
            </p>
            <img
              src="@/assets/icons/arrow-small-right.svg"
              class="ml-2"
            >
          </base-button>
        </template>
      </div>
    </base-modal>
    <base-modal
      v-if="showApprovedModal"
      :open="showApprovedModal"
    >
      <div class="flex w-full flex-col items-center">
        <img
          src="@/assets/icons/check-circle.svg"
          class="h-20 w-20"
        >
        <p class="mt-2 text-lg font-bold text-z-gray-900">
          ¡Excelente!
        </p>
        <p class="mt-2 text-z-gray-800">
          Has aceptado la solicitud.
        </p>
        <base-button
          class="mt-4 w-full"
          @click="handleApproved"
        >
          Aceptar
        </base-button>
      </div>
    </base-modal>
    <base-modal
      v-if="showRejectSuccessModal"
      :open="showRejectSuccessModal"
    >
      <div class="flex w-full flex-col items-center">
        <img
          src="@/assets/icons/check-circle.svg"
          class="h-20 w-20"
        >
        <p class="mt-2 text-lg font-bold text-z-gray-900">
          ¡Excelente!
        </p>
        <p class="mt-2 text-z-gray-800">
          Has rechazado la solicitud.
        </p>
        <base-button
          class="mt-4 w-full"
          @click="handleRejectSuccess"
        >
          Aceptar
        </base-button>
      </div>
    </base-modal>
    <base-modal
      v-if="showRejectedModal"
      :open="showRejectedModal"
    >
      <div class="relative flex w-full flex-col items-center">
        <button
          class="absolute right-0 top-0"
          @click="toggleRejectedModal"
        >
          <img src="@/assets/icons/close.svg">
        </button>
        <img
          src="@/assets/icons/information-circle.svg"
          class="h-20 w-20"
        >
        <p class="mt-2 text-center text-lg font-bold text-z-gray-900">
          ¿Estás seguro que deseas rechazar la solicitud?
        </p>
        <base-button
          class="mt-4 w-full"
          variant="danger"
          @click="handleRejected"
        >
          Rechazar
        </base-button>
      </div>
    </base-modal>
    <Dialog
      v-if="showRejectForm"
      :open="showRejectForm"
      class="fixed inset-0 z-40 h-[calc(100%-64px)] w-full bg-white"
    >
      <DialogPanel>
        <the-titlebar
          title="Rechazar solicitud"
          @back="toggleRejectForm"
        />
        <div class="flex space-x-4 border-b-2 border-z-gray-100 px-6 pb-6 pt-3">
          <img
            :src="productBooking.productPictures[0].picture.webpSm.url"
            class="h-[72px] w-[72px] rounded-lg border border-z-gray-50 object-cover"
          >
          <div class="flex flex-col">
            <p class="font-medium">
              {{ productBooking.product.name }}
            </p>
            <p class="mt-1 text-xs text-z-gray-800">
              {{ formatDate(productBooking.startDate) }} - {{ formatDate(productBooking.endDate) }}
            </p>
            <div class="mt-3 flex items-center space-x-2">
              <img
                :src="productBooking.clientAccount.pictureUrl"
                class="h-6 w-6 rounded-full object-cover"
              >
              <p class="text-xs">
                Solicitud por {{ productBooking.clientAccount.firstName }}
              </p>
            </div>
          </div>
        </div>
        <v-form
          v-slot="{ meta, handleSubmit, errors }"
          class="mt-4 px-6"
          :validation-schema="formRules"
        >
          <p class="text-lg font-medium text-z-gray-900">
            Cuéntanos un poco más
          </p>
          <base-select
            v-model="rejectReason"
            placeholder="¿Por qué deseo rechazar?"
            label="¿Por qué deseo rechazar?"
            hide-label
            name="rejectReason"
            :options="REJECT_OPTIONS"
            class="mt-8"
            :error="meta.touches && !!errors.rejectReason"
          />
          <base-input
            v-model="rejectMessage"
            name="rejectMessage"
            type="textarea"
            label="Comentario adicional"
            placeholder="Comentario adicional"
            class="mt-3"
            :error="meta.touches && !!errors.rejectMessage"
          />
          <div class="fixed bottom-16 left-0 w-full px-6 py-3">
            <base-button
              class="w-full"
              @click="handleSubmit($event, rejectBooking)"
            >
              Rechazar solicitud
            </base-button>
          </div>
        </v-form>
      </DialogPanel>
    </Dialog>
  </div>
</template>
