<script setup lang="ts">
import { computed } from 'vue';

const TOTAL_STARS = 5;

interface Props {
  value: number
}
const props = defineProps<Props>();

const fullStars = computed(() => Math.min(Math.round(props.value), TOTAL_STARS));
const emptyStars = computed(() => TOTAL_STARS - fullStars.value);
</script>
<template>
  <div class="flex space-x-2">
    <img
      v-for="number in fullStars"
      :key="number"
      src="@/assets/icons/star-gold.svg"
    >
    <img
      v-for="number in emptyStars"
      :key="number"
      src="@/assets/icons/star-empty.svg"
    >
  </div>
</template>
