<script setup lang="ts">
import { format } from 'date-fns';
import { computed, inject, ref } from 'vue';
import { supportChatsApi } from '@/api/support-chats';

interface Props {
  productBookings:ProductBooking[];
}

const props = defineProps<Props>();

const user = inject<User>('user');

const showSupportModal = ref(false);
function toggleSupportModal() {
  showSupportModal.value = !showSupportModal.value;
}

const bookingOptions = computed(() => props.productBookings.map((pb) => ({
  id: pb.id,
  name: `${pb.product.name} (${format(new Date(pb.startDate), 'd LLL')} - ${format(new Date(pb.endDate), 'd LLL')})`,
})));

const selectedBookingId = ref(0);

function createSupportChat() {
  const formValues = {
    productBookingId: selectedBookingId.value,
    userId: user?.id ?? 0,
  };

  supportChatsApi.create(formValues)
    .then((res) => {
      window.location.href = `/soporte/${res.data.supportChat.id}`;
    });
}
</script>
<template>
  <button
    class="w-16"
    @click="toggleSupportModal"
  >
    <img
      src="@/assets/icons/help.svg"
      class="w-16"
    >
  </button>
  <base-modal
    :open="showSupportModal"
    @close="toggleSupportModal"
  >
    <div class="flex w-full flex-col">
      <div class="flex justify-between">
        <p class="font-medium text-z-gray-900">
          Contactar a soporte
        </p>
        <button
          type="button"
          @click="toggleSupportModal"
        >
          <img
            src="@/assets/icons/close.svg"
            class="h-5 w-5"
          >
        </button>
      </div>
      <p class="mt-4">
        ¿Con qué arriendo tuviste problemas?
      </p>
      <base-select
        v-model="selectedBookingId"
        :options="bookingOptions"
        label="Arriendo"
        placeholder="Selecciona el arriendo"
        name="booking"
        hide-label
        class="mt-1"
      />
      <base-button
        class="mt-4"
        :disabled="!selectedBookingId"
        @click="createSupportChat"
      >
        Iniciar chat con soporte
      </base-button>
    </div>
  </base-modal>
</template>
