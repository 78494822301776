<script setup lang="ts">
import { useStore } from '@/store/store';
import { isEmpty } from 'lodash';
import { ref } from 'vue';
import { string, object } from 'yup';

interface Props {
  summaryMode?: boolean
}

defineProps<Props>();

const emit = defineEmits<{
  (e: 'continue', value: string): void
}>();

const store = useStore();

const name = ref(store.productFormData?.name ?? '');

const formRules = object().shape({
  name: string().required('Debes ingresar el nombre'),
});

function handleContinue() {
  emit('continue', name.value);
}
</script>
<template>
  <p class="text-center text-lg font-medium leading-6 text-z-gray-800">
    {{ $t('newProductForm.step1.description') }}
  </p>
  <v-form
    v-slot="{ meta, handleSubmit, errors }"
    :validation-schema="formRules"
  >
    <div class="flex flex-col space-y-6">
      <div
        v-if="!isEmpty(errors) && meta.touched"
        class="flex h-12 items-center justify-center rounded-lg bg-z-cerise-50 px-4 py-2 text-center text-sm text-z-red"
      >
        Ingresa los datos para continuar
      </div>
      <base-input
        v-model="name"
        name="name"
        placeholder="Nombre del producto"
        label="Nombre del producto"
        :error="meta.touched && !!errors.name"
      />
      <base-tip>
        {{ $t('newProductForm.step1.tip') }}
      </base-tip>
      <base-button
        class="fixed bottom-0 gap-2"
        type="button"
        @click="handleSubmit($event, handleContinue)"
      >
        {{ summaryMode ? 'Ver resumen' : 'Continuar' }}
        <img src="@/assets/icons/arrow-small-right.svg">
      </base-button>
    </div>
  </v-form>
</template>
