<script setup lang="ts">
import { useField } from 'vee-validate';
import { ref } from 'vue';

interface Props {
  modelValue?: File;
  label?: string;
  name: string;
  required?: boolean;
  error?: boolean;
  accept?: string;
  multiple?: boolean;
  capture?: 'user' | 'environment';
}

const props = withDefaults(defineProps<Props>(), {
  label: '',
  modelValue: undefined,
  hideLabel: false,
  disabled: false,
  accept: '*',
  capture: undefined,
});

const {
  value: inputValue,
  handleBlur,
  handleChange,
} = useField(props.name, undefined, {
  initialValue: props.modelValue,
});

const uploadedFile = ref<File|undefined>(props.modelValue ?? undefined);
const fileInput = ref();

function onChange() {
  uploadedFile.value = fileInput.value.files[0];
  handleChange(uploadedFile.value);
}
</script>
<template>
  <label
    for="fileInput"
    :class="$attrs.class"
  >
    <slot />
  </label>
  <input
    id="fileInput"
    ref="fileInput"
    type="file"
    :multiple="multiple"
    :name="name"
    class="absolute overflow-hidden opacity-0"
    :accept="accept"
    :capture="capture"
    @change="onChange"
    @blur="handleBlur"
  >
</template>
