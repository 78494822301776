import { api } from './index';

export const chatMessagesApi = {
  create(data: Partial<ChatMessage>) {
    const path = '/api/internal/chat_messages';

    return api({
      method: 'post',
      url: path,
      data,
    });
  },
};
