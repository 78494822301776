<script setup lang="ts">
interface Props {
  productBookingReport: ProductBookingReport
  productName: string
}

defineProps<Props>();

function handleBack() {
  window.history.back();
}
</script>
<template>
  <the-layout
    :active-menu="3"
    hide-navbar
  >
    <the-titlebar
      title="Reporte de daños"
      @back="handleBack"
    />
    <div class="mb-16 flex w-full flex-col py-4">
      <p class="px-6 font-semibold text-z-gray-900">
        {{ productName }}
      </p>
      <div class="border-b px-6 py-4">
        <p class="font-medium">
          {{ productBookingReport.reportType === 'normal' ? 'Motivo del reporte' : 'Estado del producto' }}
        </p>
        <p class="mt-1 text-z-gray-800">
          {{ productBookingReport.reason }}
        </p>
        <p class="mt-6 font-medium">
          Comentario adicional
        </p>
        <p class="mt-1 text-z-gray-800">
          {{ productBookingReport.comment ??
            `El ${$t(`accounts.${productBookingReport.reportedBy}`)} no dejó ningún comentario` }}
        </p>
      </div>
      <div class="px-6 py-4">
        <p class="font-medium">
          Fotos y videos
        </p>
        <div class="divide-y divide-z-gray-200">
          <div
            v-for="picture in productBookingReport.reportPictures"
            :key="picture.id"
            class="py-4"
          >
            <img

              :src="picture.picture.webpMd.url"
              class="w-full rounded object-cover"
            >
          </div>
        </div>
      </div>
    </div>
    <div class="fixed bottom-16 w-full border-t bg-white px-6 py-4">
      <base-button
        class="w-full"
        href="/"
      >
        Aceptar
      </base-button>
    </div>
  </the-layout>
</template>
