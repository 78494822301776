<script setup lang="ts">
import { inject, ref } from 'vue';
import { supportChatsApi } from '@/api/support-chats';
import { productBookingReturnsApi } from '@/api/product-booking-returns';
import { convertKeys } from '@/utils/case-converter';
import { useActionCable } from '@/hooks/actioncable';
import profileProductBookingCard from './profile/profile-product-booking-card.vue';

interface Props {
  productBooking: ProductBooking
  open?: boolean
  skipAccept?: boolean
}

const props = defineProps<Props>();

const emit = defineEmits<{
  (e: 'success'): void
}>();

const user = inject<User>('user');

const automaticReturnMinutes = inject<number>('automaticReturnMinutes');

const CONDITION_OPTIONS = [
  { id: 'perfect', name: 'Buen estado' },
  { id: 'repair_needed', name: 'Daño reparable' },
  { id: 'damaged', name: 'Daño irreparable' },
];

const condition = ref<'perfect'|'repair_needed'|'damaged'>();

const showSuccess = ref(false);
function toggleSuccess() {
  showSuccess.value = !showSuccess.value;
}

function handleReturn() {
  if (condition.value === 'perfect') {
    productBookingReturnsApi.update(
      props.productBooking.productBookingReturn?.id ?? 0, { status: 'complete', condition: condition.value })
      .then(() => {
        toggleSuccess();
      });
  } else {
    productBookingReturnsApi.update(
      props.productBooking.productBookingReturn?.id ?? 0, { condition: condition.value })
      .then(() => {
        window.location.href = `/perfil/solicitudes_reservas/${props.productBooking.id}/reportar_daños`;
      });
  }
}

const showConditionForm = ref(props.skipAccept);
function toggleConditionForm() {
  showConditionForm.value = !showConditionForm.value;
}

function handleLocation() {
  emit('success');
  window.location.href = `/perfil/solicitudes_reservas/${props.productBooking.id}/ubicacion`;
}

const omitGeolocation = ref(props.productBooking.productBookingReturn?.omitGeolocation);

function handleOmit() {
  productBookingReturnsApi.update(props.productBooking.productBookingReturn?.id ?? 0, { omitGeolocation: true })
    .then(() => {
      omitGeolocation.value = true;
    })
  ;
}

function handleSupportChat() {
  if (props.productBooking.supportChatId) {
    window.location.href = `/soporte/${props.productBooking.supportChatId}`;
  } else {
    const formValues = {
      productBookingId: props.productBooking.id,
      userId: user?.id ?? 0,
    };

    supportChatsApi.create(formValues)
      .then((res) => {
        window.location.href = `/soporte/${res.data.supportChat.id}`;
      });
  }
}

const automaticReturnComplete = ref(false);
useActionCable(
  { channel: 'AutomaticReturnChannel', id: props.productBooking.productBookingReturn?.id },
  {
    received(eventNewResource:Record<string, unknown>) {
      const automaticReturn = convertKeys(eventNewResource, 'camelize') as unknown as ProductBookingReturn;
      if (automaticReturn.status === 'complete') {
        automaticReturnComplete.value = true;
        toggleSuccess();
      }
    },
  },
);
</script>
<template>
  <base-modal
    :open="open"
  >
    <div
      v-if="showSuccess"
      class="flex w-full flex-col items-center"
    >
      <img
        src="@/assets/icons/check-circle.svg"
        class="w-20 self-center"
      >
      <p class="mt-4 text-center font-medium text-z-gray-900">
        Excelente!
      </p>
      <p class="mt-6 text-center text-z-gray-800">
        Se ha completado la devolución <span class="font-bold">automática</span> del producto {{ productBooking.product.name }}
      </p>
      <base-button
        class="mt-4 w-full"
        :href="`/perfil/solicitudes_reservas/${props.productBooking.id}/opiniones/crear`"
      >
        Califica a {{ productBooking.clientAccount.firstName }}
      </base-button>
    </div>
    <div
      v-else-if="showConditionForm"
      class="flex w-full flex-col items-center"
    >
      <p class="w-full font-bold text-z-gray-900">
        Estado del producto
      </p>
      <div class="mt-4 flex w-full flex-col space-y-4 rounded bg-z-turquoise-50 px-4 py-3">
        <div
          v-for="conditionOption in CONDITION_OPTIONS"
          :key="conditionOption.id"
          class="relative flex space-x-3 text-z-gray-800"
        >
          <v-field
            v-model="condition"
            name="addressId"
            type="radio"
            :value="conditionOption.id"
            class="peer mt-0.5 h-5 w-5 shrink-0 appearance-none rounded-full border-2 border-z-turquoise-600 checked:bg-z-turquoise-600"
          />
          <img
            src="@/assets/icons/check.svg"
            class="pointer-events-none absolute -left-2.5 top-[5px] w-[15px] opacity-0 peer-checked:opacity-100"
          >
          <img
            src="@/assets/icons/check.svg"
            class="pointer-events-none absolute left-[-9px] top-[5px] w-[15px] opacity-0 peer-checked:opacity-100"
          >
          <div class="space-y-1">
            <p class="line-clamp-1">
              {{ conditionOption.name }}
            </p>
          </div>
        </div>
      </div>
      <base-button
        class="mt-4 w-full"
        :disabled="!condition"
        @click="handleReturn"
      >
        Aceptar
      </base-button>
    </div>
    <div
      v-else-if="productBooking.productBookingReturn?.status === 'ongoing'"
      class="flex w-full flex-col items-center"
    >
      <img
        src="@/assets/icons/information-circle.svg"
        class="w-24 "
      >
      <p class="mt-2 font-bold text-z-gray-900">
        Confirmar devolución
      </p>
      <profile-product-booking-card
        :product-booking="productBooking"
        account-type="partner"
        hide-tags
      />
      <p class="text-center text-sm text-z-gray-800">
        Debes confirmar la devolución teniendo el producto en tu poder.
        Si no confirmas en {{ automaticReturnMinutes }} minutos, la devolución se considera conforme.
      </p>
      <base-button
        class="mt-8 w-full"
        @click="toggleConditionForm"
      >
        Confirmar devolución
      </base-button>
      <base-button
        class="mt-3 w-full"
        variant="danger"
        @click="handleSupportChat"
      >
        Contactar a soporte
      </base-button>
    </div>
    <div
      v-else-if="productBooking.productBookingReturn?.status === 'awaiting_geolocation'"
      class="flex w-full flex-col items-center"
    >
      <img
        src="@/assets/icons/information-circle.svg"
        class="w-24 "
      >
      <p class="mt-2 font-bold text-z-gray-900">
        Alerta de devolución
      </p>
      <p class="mt-2 text-center text-z-gray-800">
        El cliente ha indicado que está en el punto de devolución.<br>
        Para continuar, confirma tu georreferencia en el mapa.<br>
        Si tienes problemas, contacta a soporte.
      </p>
      <base-button
        v-if="omitGeolocation"
        class="mt-3 w-full"
        disabled
      >
        Esperando al cliente...
      </base-button>
      <template v-else>
        <base-button
          class="mt-8 w-full"
          size="sm"
          @click="handleLocation"
        >
          Ir al mapa y compartir ubicación
        </base-button>
        <base-button
          class="mt-3 w-full"
          variant="secondary"
          size="sm"
          @click="handleOmit"
        >
          Continuar sin georreferencia
        </base-button>
        <base-button
          class="mt-3 w-full"
          variant="secondary"
          size="sm"
          @click="handleSupportChat"
        >
          Contactar a soporte
        </base-button>
      </template>
    </div>
  </base-modal>
</template>
