<script setup lang="ts">
import { computed, ref } from 'vue';
import { productBookingReportsApi } from '@/api/product-booking-reports';
import { arrayToSelectOption } from '@/utils/select-option-tools';
import successModal from '../success-modal.vue';

interface Props {
  productBooking: ProductBooking
}

const props = defineProps<Props>();

function handleBack() {
  window.history.back();
}

const damageOptions = arrayToSelectOption([
  'Faltan partes o piezas',
  'Está roto',
  'Falla de funcionamiento',
  'Otros',
]);

const selectedDamage = ref();
const comment = ref();

const showSuccessModal = ref(false);
function toggleSuccessModal() {
  showSuccessModal.value = !showSuccessModal.value;
}

const pictures = ref<File[]>([]);

const nonNullPictures = computed(() => pictures.value.filter(el => el !== null && el !== undefined));

function handleSuccess() {
  window.location.href = `/perfil/solicitudes_reservas/${props.productBooking.id}/opiniones/crear`;
}

function createReport() {
  const formValues = {
    productBookingId: props.productBooking.id,
    reportedBy: 'partner',
    reason: selectedDamage.value,
    comment: comment.value,
    reportType: 'damage',
  } as Partial<ProductBookingReport>;

  productBookingReportsApi.create(formValues, nonNullPictures.value)
    .then(() => {
      toggleSuccessModal();
    });
}

</script>
<template>
  <div
    class="md:mx-auto md:my-6 md:max-w-md md:rounded md:bg-white"
  >
    <the-titlebar
      title="Reportar devolución con daños"
      class="md:rounded"
      @back="handleBack"
    />
    <div class="space-y-4 px-6 py-4">
      <p class="font-semibold text-z-gray-900">
        {{ productBooking.product.name }}
      </p>
      <base-select
        v-model="selectedDamage"
        name="reason"
        label="Estado del producto"
        hide-label
        :options="damageOptions"
        placeholder="Estado del producto"
        required
        overflow="wrap"
      />
      <base-input
        v-model="comment"
        name="comment"
        type="textarea"
        label="Comentario adicional"
        placeholder="Comentario adicional"
      />
    </div>
    <div
      class="mb-24 px-6"
    >
      <p class="font-medium text-z-gray-900">
        Fotos del producto
      </p>
      <p class="text-sm text-z-gray-800">
        Toma como mínimo 3 fotos para poder analizar de forma correcta el daño
      </p>
      <base-drag-and-drop
        v-for="i in [0,1,2]"
        :key="i"
        v-model="pictures[i]"
        label="Foto de referencia"
        hide-label
        name="picture"
        max="1"
        wide
        class="mt-4"
      />
    </div>
    <div class="fixed bottom-0 w-full border-t bg-white px-6 py-4">
      <base-button
        class="w-full"
        :disabled="!selectedDamage || nonNullPictures.length !== 3"
        @click="createReport"
      >
        Finalizar devolución
      </base-button>
    </div>
  </div>
  <success-modal
    :open="showSuccessModal"
    title="Devolución finalizada"
    description="Enviaremos el reporte de daños al cliente y resolveremos tu garantía a la brevedad."
    :button-label="`Califica a ${productBooking.clientAccount.firstName}`"
    @confirm="handleSuccess"
  />
</template>
