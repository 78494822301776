import { api } from './index';

export const userRatingsApi = {
  create(data: Partial<UserRating>) {
    const path = '/api/internal/user_ratings';

    return api({
      method: 'post',
      url: path,
      data,
    }).then(response => response.data.userRating as UserRating);
  },
  index(receiverId: number) {
    const path = `/api/internal/users/${receiverId}/user_ratings`;

    return api({
      method: 'get',
      url: path,
    }).then(response => response.data.userRatings as UserRating[]);
  },
};
