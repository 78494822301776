import { api } from './index';

export const supportChatsApi = {
  create(data: { productBookingId:number, userId: number}) {
    const path = '/api/internal/support_chats';

    return api({
      method: 'post',
      url: path,
      data,
    });
  },
};
