import { api } from './index';

export const productBookingReportsApi = {
  create(data: Partial<ProductBookingReport>, pictures?: File[]) {
    const path = '/api/internal/product_booking_reports';

    return api({
      method: 'post',
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      url: path,
      data: { ...data, pictures },
    }).then(response => response.data.productBookingReport as ProductBookingReport);
  },
};
