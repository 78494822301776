<script setup lang="ts">
import { useActionCable } from '@/hooks/actioncable';
import { convertKeys } from '@/utils/case-converter';
import { computed, inject, ref } from 'vue';
import newDeliveryModal from './new-delivery-modal.vue';
import newReturnModal from './new-return-modal.vue';
import successModal from './success-modal.vue';
import reportAlertModal from './report-alert-modal.vue';

const PATHS_WITHOUT_ALERTS = [/\/chats.*/, /\/perfil\/solicitudes_reservas\/[0-9]+.*/, /\/soporte.*/];

interface Props {
  bookingsForDelivery: ProductBooking[]
  bookingsForReturn: ProductBooking[]
}
const props = defineProps<Props>();

const user = inject<User>('user');

const allBookingsForDelivery = ref<ProductBooking[]>(props.bookingsForDelivery);
const allBookingsForReturn = ref<ProductBooking[]>(props.bookingsForReturn);

const showModalForNewBooking = ref(false);

const showDeliverySuccessMessage = ref(false);
function toggleDeliverySuccessMessage() {
  showDeliverySuccessMessage.value = !showDeliverySuccessMessage.value;
}

const showReturnSuccessMessage = ref(false);
function toggleReturnSuccessMessage() {
  showReturnSuccessMessage.value = !showReturnSuccessMessage.value;
}

const productBookingId = ref();

const showReportAlertModal = ref(false);
function toggleReportAlertModal() {
  showReportAlertModal.value = !showReportAlertModal.value;
}

const productBookingReport = ref<ProductBookingReport>();

// Client ActionCable
useActionCable(
  { channel: 'DeliveryClientChannel', id: user?.clientAccountId },
  {
    received(eventNewResource:Record<string, unknown>) {
      const newBookingForDelivery = convertKeys(eventNewResource, 'camelize');
      allBookingsForDelivery.value.push(newBookingForDelivery as unknown as ProductBooking);
      showModalForNewBooking.value = true;
    },
  },
);

useActionCable(
  { channel: 'ReturnClientChannel', id: user?.clientAccountId },
  {
    received(eventNewResource:Record<string, unknown>) {
      const completeBooking = convertKeys(eventNewResource, 'camelize') as unknown as ProductBooking;
      productBookingId.value = completeBooking.id;
      toggleReturnSuccessMessage();
    },
  },
);

// Partner ActionCable
if (user?.partnerAccountId) {
  useActionCable(
    { channel: 'ReturnPartnerChannel', id: user?.partnerAccountId },
    {
      received(eventNewResource:Record<string, unknown>) {
        const newBookingForReturn = convertKeys(eventNewResource, 'camelize');
        allBookingsForReturn.value.push(newBookingForReturn as unknown as ProductBooking);
        showModalForNewBooking.value = true;
      },
    },
  );
  useActionCable(
    { channel: 'DeliveryPartnerChannel', id: user?.partnerAccountId },
    {
      received(eventNewResource:Record<string, unknown>) {
        const deliveredBooking = convertKeys(eventNewResource, 'camelize') as unknown as ProductBooking;
        productBookingId.value = deliveredBooking.id;
        toggleDeliverySuccessMessage();
      },
    },
  );
}

// UserActionCable
useActionCable(
  { channel: 'AlertChannel', id: user?.id },
  {
    received(eventNewResource:Record<string, unknown>) {
      productBookingReport.value = convertKeys(eventNewResource, 'camelize') as unknown as ProductBookingReport;
      toggleReportAlertModal();
    },
  },
);
const showModal = computed(() => !PATHS_WITHOUT_ALERTS.some(
  path => path.test(window.location.pathname)) || showModalForNewBooking.value);

function goToRating() {
  window.location.href = `/perfil/solicitudes_reservas/${productBookingId.value}/opiniones/crear`;
}

function goToDelivered(userType: 'partner'|'client') {
  const userRoute = userType === 'partner' ? 'socio' : 'cliente';

  if (window.location.pathname === `/perfil/solicitudes_reservas/${userRoute}`) {
    window.location.replace(`/perfil/solicitudes_reservas/${userRoute}#entregados`);
    window.location.reload();
  } else {
    window.location.assign(`/perfil/solicitudes_reservas/${userRoute}#entregados`);
  }
}

function handleDeliverySuccess() {
  allBookingsForDelivery.value.shift();
  goToDelivered('client');
}
function handleReturnSuccess() {
  allBookingsForReturn.value.shift();
}

function reload() {
  window.location.reload();
}
</script>
<template>
  <report-alert-modal
    v-if="showReportAlertModal && productBookingReport"
    :open="showReportAlertModal"
    :product-booking-report="productBookingReport"
    @confirm="reload"
  />
  <template
    v-else
  >
    <new-delivery-modal
      v-if="allBookingsForDelivery.length > 0"
      :product-booking="allBookingsForDelivery[0]"
      :open="showModal"
      @success="handleDeliverySuccess"
    />
    <new-return-modal
      v-if="allBookingsForReturn.length > 0"
      :product-booking="allBookingsForReturn[allBookingsForReturn.length - 1]"
      :open="showModal"
      @success="handleReturnSuccess"
    />
    <success-modal
      v-if="showReturnSuccessMessage"
      :open="showReturnSuccessMessage"
      description="El socio ha aceptado la devolución"
      @confirm="goToRating"
    />
    <success-modal
      v-if="showDeliverySuccessMessage"
      :open="showDeliverySuccessMessage"
      description="El cliente ha aceptado la entrega"
      @confirm="goToDelivered('partner')"
    />
  </template>
</template>
