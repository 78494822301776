<script setup lang="ts">
import { useField } from 'vee-validate';
import { ref } from 'vue';
import { generateURL } from '@/utils/generate-url';

interface Props {
  modelValue?: File;
  label?: string;
  name: string;
  required?: boolean;
  hideLabel?: boolean;
  placeholder?: string;
  error?: boolean;
  wide?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  label: '',
  modelValue: undefined,
  hideLabel: false,
  disabled: false,
  placeholder: 'Sube una foto',
});

const {
  value: inputValue,
  handleBlur,
  handleChange,
} = useField(props.name, undefined, {
  initialValue: props.modelValue,
});

const isDragging = ref(false);
const uploadedFile = ref<File|undefined>(props.modelValue ?? undefined);
const fileInput = ref();

function handleDragover() {
  isDragging.value = true;
}
function handleDragleave() {
  isDragging.value = false;
}
function onChange() {
  uploadedFile.value = fileInput.value.files[0];
  handleChange(uploadedFile.value);
}
function handleDrop(e:DragEvent) {
  fileInput.value.files = e.dataTransfer?.files;
  onChange();
  isDragging.value = false;
}
function removeFile() {
  uploadedFile.value = undefined;
  handleChange(null);
}
</script>
<template>
  <div>
    <base-label
      v-if="!hideLabel"
      :label="label"
      :name="name"
      :required="required"
    />
    <div
      v-if="!uploadedFile"
      class="relative flex aspect-square w-full flex-col items-center justify-center space-y-2"
      :class="{
        'rounded-lg border border-z-red': error,
        'bg-dashed-border': !error,
        'aspect-square': !wide,
        'h-[104px]': wide,
      }"
      @drop.prevent="handleDrop"
      @dragover.prevent="handleDragover()"
      @dragleave.prevent="handleDragleave()"
    >
      <img src="@/assets/icons/image-add.svg">
      <p>{{ placeholder }}</p>
      <input
        id="fileInput"
        ref="fileInput"
        type="file"
        name="file"
        class="absolute h-full w-full cursor-pointer overflow-hidden opacity-0"
        accept="image/*"
        @change="onChange"
        @blur="handleBlur"
      >
    </div>
    <div
      v-else
      class="relative flex aspect-square w-full flex-col items-center justify-center rounded-lg"
      :class="wide ? 'h-[104px]' : 'aspect-square'"
    >
      <img
        :src="generateURL(inputValue)"
        class="aspect-square w-full rounded-lg object-cover"
        :class="wide ? 'h-[104px]' : 'aspect-square'"
      >
      <button
        class="absolute right-2 top-2 flex h-8 items-center justify-center rounded bg-z-turquoise-50 px-2 text-z-turquoise-600"
        @click="removeFile"
      >
        <img src="@/assets/icons/trash.svg">
      </button>
    </div>
  </div>
</template>
