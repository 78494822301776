<script setup lang="ts">
import { computed, onBeforeMount, onMounted, provide, ref } from 'vue';
import { partition, reverse } from 'lodash';
import { productBookingsApi } from '@/api/product-bookings';
import ProfileProductBookingCard from './profile-product-booking-card.vue';
import ProfileProductBookingPartnerAwaitingActions from './profile-product-booking-partner-awaiting-actions.vue';
import ProfileProductBookingClientAwaitingActions from './profile-product-booking-client-awaiting-actions.vue';
import ProfileProductBookingPaymentActions from './profile-product-booking-payment-actions.vue';
import ProfileProductBookingPartnerPaidActions from './profile-product-booking-partner-paid-actions.vue';
import ProfileProductBookingClientPaidActions from './profile-product-booking-client-paid-actions.vue';
import ProfileProductBookingClientDeliveredActions from './profile-product-booking-client-delivered-actions.vue';
import ProfileProductBookingPartnerDeliveredActions from './profile-product-booking-partner-delivered-actions.vue';
import SupportButton from '../support-button.vue';

interface Props {
  approvalTimeLimit: number,
  paymentTimeLimit: number,
  paidTimeLimit: number,
}

const props = defineProps<Props>();

const productBookings = ref<ProductBooking[]>([]);
const loading = ref(false);

function handleBack() {
  window.history.back();
}

const statusTabs = [
  {
    status: 'awaiting_approval',
    displayName: { partner: 'Solicitados', client: 'Solicitados' },
    showTabs: false,
    hash: '#solicitados',
    showSupport: false,
  },
  {
    status: 'partner_approved',
    displayName: { partner: 'Esperando pago', client: 'Por pagar' },
    showTabs: false,
    hash: '#esperando-pago',
    showSupport: false,
  },
  {
    status: 'paid',
    displayName: { partner: 'Reservados', client: 'Reservados' },
    showTabs: true,
    hash: '#reservados',
    showSupport: true,
  },
  {
    status: 'delivered',
    displayName: { partner: 'Entregados', client: 'Entregados' },
    showTabs: false,
    hash: '#entregados',
    showSupport: true,
  },
  {
    status: 'complete',
    displayName: { partner: 'Completados', client: 'Completados' },
    showTabs: false,
    hash: '#completados',
    showSupport: true,
  },
];

const activeTab = ref(0);
const bookedTab = ref(0);

const accountType = window.location.pathname === '/perfil/solicitudes_reservas/cliente' ? 'client' : 'partner';

function getBookings() {
  loading.value = true;
  productBookingsApi.index(statusTabs[activeTab.value].status, accountType)
    .then((response) => {
      productBookings.value = response;
      loading.value = false;
    });
}

function setActiveTab(index:number) {
  activeTab.value = index;
  history.replaceState('', '', statusTabs[activeTab.value].hash);
  getBookings();
}

function setBookedTab(index:number) {
  bookedTab.value = index;
  getBookings();
}

const bookedBookings = computed(() => reverse(partition(productBookings.value, 'canceled')));

onBeforeMount(() => {
  provide('approvalTimeLimit', props.approvalTimeLimit);
  provide('paymentTimeLimit', props.paymentTimeLimit);
  provide('paidTimeLimit', props.paidTimeLimit);
});

onMounted(() => {
  const hash = window.location.hash;
  if (!hash) return;

  const tabIndex = statusTabs.findIndex(tab => tab.hash === hash);

  if (tabIndex !== -1) {
    setActiveTab(tabIndex);
  }

  getBookings();
});

</script>
<template>
  <the-layout
    :active-menu="3"
    hide-navbar
  >
    <div class="flex">
      <div class="relative w-full bg-white md:mx-auto md:my-6 md:h-[calc(100vh-72px-24px-24px)] md:max-w-md md:overflow-y-scroll md:rounded">
        <the-titlebar
          :title="`${statusTabs[activeTab].displayName[accountType]} (${bookedBookings[0].length ?? 0})`"
          no-border
          @back="handleBack"
        />
        <div class="sticky top-0 flex space-x-1 overflow-x-scroll border-b border-z-gray-200 px-6 py-3 scrollbar-hide">
          <button
            v-for="tab, index in statusTabs"
            :key="index"
            class="h-8 shrink-0 rounded px-3"
            :class="activeTab === index ? 'bg-z-turquoise-50 text-z-turquoise-600' : 'bg-z-gray-25 text-z-gray-600'"
            @click="setActiveTab(index)"
          >
            {{ tab.displayName[accountType] }}
          </button>
        </div>
        <div
          v-if="loading"
          class="flex h-64 w-full items-center justify-center"
        >
          <img
            src="@/assets/icons/loading.svg"
            class="w-16 animate-spin-slow"
          >
        </div>
        <div
          v-else-if="productBookings.length === 0"
          class="flex w-full flex-col items-center gap-6 px-6 py-4"
        >
          <img
            src="@/assets/icons/task-search.svg"
            class="h-16 w-16"
          >
          <p>No hay elementos aquí</p>
        </div>
        <template v-else>
          <div
            v-if="statusTabs[activeTab].showTabs"
            class="grid grid-cols-2"
          >
            <button
              class="h-12 border-b text-sm"
              :class="bookedTab === 0 ? ' border-z-turquoise-600' : 'border-z-gray-200'"
              @click="setBookedTab(0)"
            >
              Aceptados
            </button>
            <button
              class="h-12 border-b text-sm"
              :class="bookedTab === 1 ? ' border-z-turquoise-600' : 'border-z-gray-200'"
              @click="setBookedTab(1)"
            >
              Cancelados
            </button>
          </div>
          <div
            v-if="bookedBookings[bookedTab].length === 0"
            class="flex w-full flex-col items-center gap-6 px-6 py-4"
          >
            <img
              src="@/assets/icons/task-search.svg"
              class="h-16 w-16"
            >
            <p>No hay elementos aquí</p>
          </div>
          <div
            v-else
            class="flex flex-col divide-y divide-z-gray-100"
          >
            <div
              v-for="productBooking in bookedBookings[bookedTab]"
              :key="productBooking.id"
              class="px-6"
            >
              <profile-product-booking-card
                :product-booking="productBooking"
                :account-type="accountType"
              />
              <template
                v-if="bookedTab === 0"
              >
                <profile-product-booking-partner-awaiting-actions
                  v-if="activeTab === 0 && accountType === 'partner'"
                  :product-booking="productBooking"
                />
                <profile-product-booking-client-awaiting-actions
                  v-else-if="activeTab === 0 && accountType === 'client'"
                  :product-booking="productBooking"
                />
                <profile-product-booking-payment-actions
                  v-else-if="activeTab === 1 && accountType === 'client'"
                  :product-booking="productBooking"
                />
                <profile-product-booking-partner-paid-actions
                  v-else-if="activeTab === 2 && accountType === 'partner'"
                  :product-booking="productBooking"
                />
                <profile-product-booking-client-paid-actions
                  v-else-if="activeTab === 2 && accountType === 'client'"
                  :product-booking="productBooking"
                />
                <profile-product-booking-client-delivered-actions
                  v-else-if="activeTab === 3 && accountType === 'client'"
                  :product-booking="productBooking"
                />
                <profile-product-booking-partner-delivered-actions
                  v-else-if="activeTab === 3 && accountType === 'partner'"
                  :product-booking="productBooking"
                />
              </template>
            </div>
          </div>
        </template>
        <div class="fixed bottom-20 right-6 md:bottom-6">
          <support-button
            v-if="statusTabs[activeTab].showSupport"
            :product-bookings="bookedBookings[bookedTab]"
          />
        </div>
      </div>
    </div>
  </the-layout>
</template>
