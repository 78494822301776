<script setup lang="ts">
import { computed, inject, ref } from 'vue';
import menuHomeUrl from '@/assets/icons/menu-home.svg';
import menuHomeActiveUrl from '@/assets/icons/menu-home-active.svg';
import bagAddUrl from '@/assets/icons/bag-add.svg';
import bagAddActiveUrl from '@/assets/icons/bag-add-active.svg';
import userUrl from '@/assets/icons/user.svg';
import userActiveUrl from '@/assets/icons/user-active.svg';
import chatUrl from '@/assets/icons/chat.svg';
import chatActiveUrl from '@/assets/icons/chat-active.svg';
import defaultProfileUrl from '@/assets/icons/default-profile.svg';
import { useStore } from '@/store/store';
import { storeToRefs } from 'pinia';

interface Props {
  activeItem?: number;
}
const props = withDefaults(defineProps<Props>(), {
  activeItem: -1,
});

const user = inject<User>('user');

const store = useStore();

const activeIndex = ref(props.activeItem);

function goToHome() {
  if (window.location.pathname !== '/') {
    window.location.href = '/';
  }
}

function goToPartnerStart() {
  const pathnames = ['/productos/crear', '/como_subir'];
  if (!pathnames.includes(window.location.pathname)) {
    window.location.href = '/como_subir';
  }
}

function goToProfile() {
  const path = user ? '/perfil' : '/usuarios/iniciar_sesion';

  if (window.location.pathname !== '/perfil') {
    window.location.href = path;
  }
}

function goToChats() {
  if (window.location.pathname !== '/chats') {
    window.location.href = '/chats';
  }
}

const { hasNewMessages } = storeToRefs(store);

// eslint-disable-next-line complexity
const menuItems = computed(() => [
  {
    iconNormal: menuHomeUrl,
    iconActive: menuHomeActiveUrl,
    action: goToHome,
    alt: 'Ícono de hogar',
    ariaLabel: 'Ir a la página principal',
    label: 'Inicio',
  },
  {
    iconNormal: bagAddUrl,
    iconActive: bagAddActiveUrl,
    action: goToPartnerStart,
    alt: 'Ícono de bolsa con signo más',
    ariaLabel: 'Subir producto',
    label: 'Subir',
  },
  {
    iconNormal: chatUrl,
    iconActive: chatActiveUrl,
    action: goToChats,
    alt: 'Ícono de mensaje',
    ariaLabel: 'Ir a chats',
    label: 'Chats',
    showBadge: user?.hasUnreadChats || hasNewMessages.value,
  },
  {
    iconNormal: user ? user.picture.webpSm.url || defaultProfileUrl : userUrl,
    iconActive: user ? user.picture.webpSm.url || defaultProfileUrl : userActiveUrl,
    action: goToProfile,
    alt: 'Ícono de perfil o persona',
    ariaLabel: 'Ir al perfil',
    label: user ? 'Mi perfil' : 'Iniciar sesión',
  },
]);

</script>
<template>
  <div class="fixed bottom-0 flex h-16 w-full border-t border-z-gray-100 bg-white md:hidden">
    <button
      v-for="(item, index) in menuItems"
      :key="index"
      class="flex w-full flex-col items-center justify-center"
      :class="{ 'border-b-2 border-z-turquoise-600': activeIndex === index }"
      :aria-label="item.ariaLabel"
      @click="activeIndex = index; item.action()"
    >
      <div class="relative h-6 w-6">
        <img
          class="h-6 w-6 rounded-full"
          :class="{ 'box-content border-2 border-z-turquoise-600': index === 4 && activeIndex === index }"
          :src="activeIndex === index ? item.iconActive : item.iconNormal"
          :alt="item.alt"
        >
        <template v-if="item.showBadge">
          <div
            class="absolute -right-0.5 -top-0.5 h-3 w-3 shrink-0 rounded-full border border-white bg-z-yellow-500"
          />
          <div
            class="absolute -right-0.5 -top-0.5 h-3 w-3 shrink-0 animate-ping rounded-full bg-z-yellow-500/40"
          />
        </template>
      </div>
      <p
        class="mt-1 text-xs"
        :class="activeIndex === index ? 'text-z-turquoise-600' : 'text-z-gray-700'"
      >
        {{ item.label }}
      </p>
    </button>
  </div>
</template>
