export const esLocale = {
  accounts: {
    partner: 'Socio',
    client: 'Cliente',
  },
  product: {
    condition: {
      isNew: 'Nuevo',
      likeNew: 'Semi nuevo',
      used: 'Usado',
      veryUsed: 'Muy usado',
    },
    weight: {
      lt5: 'Menos de 5 kg',
      gt5Lt10: 'Entre 5 y 10 kg',
      gt10Lt20: 'Entre 10 y 20 kg',
      gt20: 'Más de 20 kg',
    },
    status: {
      pending: 'En revisión',
      active: 'Publicado',
      paused: 'Pausado',
      deleted: 'Eliminado',
    },
    shippingMethod: {
      clientAccount: {
        clientPickup: 'Tú debes retirarlo',
        partnerDelivery: 'Te lo entrega el socio',
      },
      partnerAccount: {
        clientPickup: 'El cliente lo retira',
        partnerDelivery: 'Tú lo entregas',
      },
    },
  },
  productBooking: {
    status: {
      awaitingApproval: 'Solicitado',
      partnerApproved: 'Esperando pago',
      partnerRejected: 'Rechazado',
      paid: 'Reservado',
      delivered: 'Entregado',
      complete: 'Completado',
    },
  },
  notification: {
    kind: {
      awaitingApproval: 'Nueva solicitud de arriendo',
      partnerApproved: 'Solicitud aceptada',
      clientApproved: 'Contrapropuesta aceptada',
      partnerRejected: 'Solicitud rechazada',
      clientRejected: 'Contrapropuesta rechazada',
      autoRejected: 'Solicitud rechazada automáticamente',
      delivered: 'Producto entregado',
      completed: 'Devolución exitosa',
      awaitingApprovalReminder: 'Recordatorio de solicitud de arriendo',
    },
  },
  bankAccount: {
    kind: {
      savingsAccount: 'Cuenta de ahorro',
      checkingAccount: 'Cuenta corriente',
      sightAccount: 'Cuenta vista',
    },
  },
  frequentlyAskedQuestion: {
    section: {
      general: 'Preguntas generales',
      partner: 'Preguntas de socios',
      client: 'Preguntas de clientes',
    },
  },
  newProductForm: {
    step1: {
      title: 'Nombre del producto',
      description: 'Para comenzar, ingresa el nombre de tu producto',
      tip: 'Escoge el nombre preciso para que la comunidad lo encuentre fácilmente.',
    },
    step2: {
      title: 'Entrega y devolución',
      description: '¿Cómo deseas realizar la entrega y devolución de tu producto?',
      tip: 'Puedes ofrecer ambas alternativas y dejar que el cliente escoja.',
      addressTitle: '¿Dónde está ubicado el producto?',
      addressTipTitle: 'En caso de retiro por cliente...',
      addressTip: 'La dirección será compartida con él luego de que haya pagado la reserva.',
    },
    step3: {
      title: 'Detalles del producto',
      description: 'Entréganos más características de tu producto',
    },
    step4: {
      title: 'Descripción',
      description: 'Describe lo mejor que puedas tu producto.',

    },
    step5: {
      title: 'Precio y garantía de arriendo',
      description: '¡Estás a un paso de hacer zirkular tus productos!',
    },
    summary: {
      description: 'Revisa la información que publicarás.',
    },
    successModal: {
      title: '¡Excelente!',
      description: 'Hemos recibido tu producto de forma exitosa.',
      tip: 'Verificaremos tu publicación y te notificaremos vía correo electrónico en un máximo de 24 horas.',
    },
  },
};
