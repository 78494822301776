<script setup lang="ts">
import { useField } from 'vee-validate';
import { computed, watch } from 'vue';
import { useCurrencyInput, type CurrencyDisplay } from 'vue-currency-input';

interface Props {
  id?: string,
  modelValue?: string | number,
  placeholder?: string,
  label: string,
  name: string,
  required?: boolean
  error?: boolean
}
const props = withDefaults(defineProps<Props>(), {
  id: undefined,
  placeholder: undefined,
  modelValue: undefined,
});

const emit = defineEmits<{(e: 'update:modelValue', value: number | string): void}>();

const showLabelInside = computed(() => !!props.modelValue);

const { inputRef } = useCurrencyInput({
  currency: 'CLP',
  locale: 'es-CL',
  currencyDisplay: 'narrowSymbol' as CurrencyDisplay,
  useGrouping: true,
  hideCurrencySymbolOnFocus: false,
  hideGroupingSeparatorOnFocus: false,
  hideNegligibleDecimalDigitsOnFocus: false,
}, true);

const {
  meta,
  value: inputValue,
  errorMessage,
  handleBlur,
  handleChange,
} = useField(props.name, undefined, {
  initialValue: props.modelValue,
});

watch(inputValue, (newVal) => {
  if (newVal === props.modelValue) {
    return;
  }
  emit('update:modelValue', newVal);
});
watch(
  () => props.modelValue,
  (newModel) => {
    if (newModel) {
      if (newModel === inputValue.value) {
        return;
      }
      inputValue.value = newModel;
    }
  },
);
</script>
<template>
  <div class="relative flex">
    <base-label
      v-if="showLabelInside"
      :label="label"
      :name="name"
      :required="required"
      class="absolute ml-3 mt-1 text-xs text-z-gray-500"
    >
      <slot name="label" />
    </base-label>
    <input
      ref="inputRef"
      type="text"
      :placeholder="placeholder"
      class="h-14 w-full rounded border border-z-gray-200 text-gray-900 outline-none
          placeholder:text-z-gray-900 read-only:bg-z-gray-100 read-only:text-z-gray-600 focus:border-z-gray-600
          disabled:border-z-gray-600 disabled:bg-z-gray-100 disabled:text-z-gray-600"
      :class="{
        'p-3': !showLabelInside,
        'px-3 pb-0 pt-3': showLabelInside,
        'border-z-red': error || (!!errorMessage && meta.touched),
      }"
      @input="handleChange"
      @blur="handleBlur"
    >
  </div>
</template>
