<script setup lang="ts">
import { currency } from '@/utils/currency';
import { camelCase } from 'lodash';
import { format } from 'date-fns';

interface Props {
  products: Product[]
}
defineProps<Props>();

function handleBack() {
  window.history.back();
}
</script>
<template>
  <the-layout
    :active-menu="3"
    hide-navbar
  >
    <div class="bg-white md:mx-auto md:my-6 md:h-[calc(100vh-72px-24px-24px)] md:max-w-md md:overflow-y-scroll md:rounded">
      <the-titlebar
        title="Mi historial de tienda"
        @back="handleBack"
      />
      <div class="flex flex-col">
        <div class="flex flex-col items-center space-y-6 px-6 py-4">
          <img
            src="@/assets/icons/store-alt.svg"
            class="h-16 w-16"
          >
          <p class="text-center">
            Comparte tu tienda y aumenta tus ingresos
          </p>
          <base-button
            variant="secondary"
            class="w-full space-x-2"
          >
            <img src="@/assets/icons/share-rectangle.svg">
            <p>Compartir mi tienda</p>
          </base-button>
        </div>
        <p class="px-6 py-2 text-xl font-medium text-z-gray-800">
          Productos que han sido arrendados
        </p>
        <div class="divide-y-2 divide-z-gray-100 border-b-2 border-z-gray-100">
          <a
            v-for="product in products"
            :key="product.id"
            class="flex w-full space-x-4 px-6 py-4"
            :href="`/perfil/productos/${product.id}/historial`"
          >
            <img
              :src="product.productPictures[0].picture.webpSm.url"
              class="h-24 w-24 rounded-lg border border-gray-100 object-cover"
            >
            <div class="flex w-full flex-col space-y-3 text-start">
              <div class="flex w-full items-center justify-between">
                <p class="w-fit rounded bg-z-turquoise-50 px-2 py-0.5 text-xs text-z-turquoise-600">
                  {{ $t(`product.condition.${ camelCase(product.condition) }`) }}
                </p>
                <p class="text-xs text-z-gray-900">
                  {{ format(new Date(product.createdAt), "d 'de' MMM") }}
                </p>
              </div>
              <p class="line-clamp-1 font-medium">
                {{ product.name }}
              </p>
              <p class="line-clamp-2 text-sm text-z-gray-800">
                {{ product.description }}
              </p>
              <div class="text-xs leading-[18px]">
                <p class="font-bold text-z-turquoise-600">
                  {{ currency(product.dailyPrice) }}
                </p>
                <p class="text-z-gray-900">
                  Alquilado: {{ product.productBookingsCount }} {{ product.productBookingsCount > 1 ? 'veces' : 'vez' }}
                </p>
              </div>
            </div>
          </a>
        </div>
      </div>
    </div>
  </the-layout>
</template>
