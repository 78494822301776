<script setup lang="ts">
import { computed, onMounted, ref } from 'vue';

interface Props {
  type: 'alert' | 'notice' | 'error',
  message: string,
}

const props = defineProps<Props>();

const show = ref(false);
const duration = 3000;

onMounted(() => {
  show.value = true;
  setTimeout(() => {
    show.value = false;
  }, duration);
});

const typeClasses = computed(() => {
  const classes = {
    alert: 'bg-z-yellow-400 text-black',
    notice: 'bg-z-turquoise-600 text-white',
    error: 'bg-z-red text-white',
  };

  return classes[props.type];
});
</script>
<template>
  <Transition>
    <div
      v-if="show"
      class="mx-auto flex h-8 w-full flex-col items-center justify-center text-center text-xs shadow-sm"
      :class="typeClasses"
    >
      <p>{{ message }}</p>
    </div>
  </Transition>
</template>
<style scoped>
.v-enter-active,
.v-leave-active {
  transition: 0.5s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
  transform: translateY(-32px)
}
</style>
