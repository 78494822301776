<script setup lang="ts">
</script>
<template>
  <the-layout hide-navbar>
    <div class="flex flex-col items-center p-6 md:mx-auto md:my-6 md:max-w-md md:rounded md:bg-white">
      <img
        src="@/assets/icons/warning-alt-2.svg"
        class="w-20"
      >
      <p class="mt-2 text-center font-medium text-z-gray-900">
        La confirmación de tu pago ha fallado, inténtalo nuevamente.
      </p>
      <p />
      <base-button
        href="/perfil/solicitudes_reservas/cliente"
        class="mt-6 w-full"
      >
        Ir a mis arriendos
      </base-button>
      <base-button
        href="/"
        variant="secondary"
        class="mt-4 w-full"
      >
        Volver al inicio
      </base-button>
    </div>
  </the-layout>
</template>
