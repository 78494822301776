function generateURL(file:File) {
  const fileSrc = URL.createObjectURL(file);
  setTimeout(() => {
    URL.revokeObjectURL(fileSrc);
  });

  return fileSrc;
}

export { generateURL };
