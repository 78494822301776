<script setup lang="ts">
import { formatDistanceToNow } from 'date-fns';

interface Props {
  productRequests?: ProductRequest[]
}

defineProps<Props>();

function handleBack() {
  window.location.href = '/menu';
}
</script>
<template>
  <the-layout hide-navbar>
    <div class="relative bg-white md:mx-auto md:my-6 md:h-[calc(100vh-72px-24px-24px)] md:max-w-md md:overflow-y-scroll md:rounded">
      <the-titlebar
        title="Necesidades de nuestros clientes"
        no-border
        @back="handleBack"
      />
      <div class="flex w-full flex-col px-6 py-3">
        <div class="flex w-full space-x-2 rounded-lg bg-z-gray-100 px-3 py-2">
          <img
            src="@/assets/icons/search.svg"
            class="h-6"
          >
          <input
            placeholder="Buscar"
            class="w-full bg-z-gray-100 outline-none placeholder:text-z-gray-600"
          >
        </div>
      </div>
      <div
        v-if="productRequests && productRequests.length > 0"
        class="grid grid-cols-2 gap-2 p-6"
      >
        <a
          v-for="productRequest in productRequests"
          :key="productRequest.id"
          class="flex flex-col rounded-lg border border-z-gray-100 p-2"
          :href="`/necesidades_clientes/${productRequest.id}`"
        >
          <img
            :src="productRequest.picture.webpMd.url"
            class="aspect-square size-full rounded object-cover"
          >
          <p class="mt-3 line-clamp-1 shrink-0 text-sm">
            {{ productRequest.name }}
          </p>
          <p class="mt-1 line-clamp-1 shrink-0 text-xs text-z-gray-700">
            Hace {{ formatDistanceToNow(new Date(productRequest.createdAt as unknown as string)) }}
          </p>
        </a>
      </div>
      <div
        v-else
        class="flex flex-col items-center space-y-6 px-6 py-4"
      >
        <img src="@/assets/icons/task-search.svg">
        <p>Aún no hay solicitudes.</p>
      </div>
      <div class="fixed bottom-16 w-full flex-col bg-white px-6 py-2 md:bottom-0 md:max-w-md">
        <base-button
          class="w-full"
          type="button"
          href="/necesidades_clientes/crear"
        >
          Agregar solicitud
        </base-button>
      </div>
    </div>
  </the-layout>
</template>
