<script setup lang="ts">
import { rutFormat } from 'rut-helpers';
import { computed, inject, ref } from 'vue';
import * as usersApi from '@/api/users';
import successModal from './success-modal.vue';
import ProfileNewBankAccountForm from './profile/profile-new-bank-account-form.vue';

const user = inject<User>('user');

const currentStep = ref(0);

const rut = ref<string>('');
const phoneNumber = ref<string>('');
const birthDate = ref<string>('');

const error = ref(false);
const loading = ref(false);

const STEPS = [
  { title: 'Verificación de identidad', progressBarClass: 'col-span-1' },
  { title: 'Cuenta bancaria', progressBarClass: 'col-span-2' },
];

function handleStepBack() {
  currentStep.value--;
}

function handleNextStep() {
  currentStep.value++;
}

function handleRutInput() {
  error.value = false;
  rut.value = rutFormat(rut.value);
}

const showSuccessModal = ref(false);
function toggleSuccessModal() {
  showSuccessModal.value = !showSuccessModal.value;
}

const queryParams = new URLSearchParams(window.location.search);
const from = queryParams.get('from');
const redirectTo = queryParams.get('redirect_to');

const showBankAccountStep = computed(() => from === 'awaiting_approval');

function handleVerify() {
  const formValues = {
    rut: rut.value,
    phoneNumber: phoneNumber.value,
    birthDate: birthDate.value,
  };
  loading.value = true;
  usersApi.update(user?.id ?? 0, formValues).then(() => {
    setTimeout(() => {
      loading.value = false;
      if (showBankAccountStep.value) {
        handleNextStep();
      } else {
        toggleSuccessModal();
      }
    // eslint-disable-next-line no-magic-numbers
    }, 2000);
  });
}

function goBack() {
  window.history.back();
}

function handleSuccess() {
  window.location.href = redirectTo ?? '/';
}
</script>
<template>
  <the-titlebar
    title="Verifica tu cuenta"
    class="md:rounded"
    @back="goBack"
  />
  <div
    v-if="showBankAccountStep"
    class="sticky top-16 z-10"
  >
    <div
      class="grid h-0.5 w-full shrink-0 grid-cols-2 bg-z-turquoise-100"
    >
      <div
        class="h-full w-full bg-z-turquoise-600"
        :class="STEPS[currentStep].progressBarClass"
      />
    </div>
    <div class="relative flex h-[84px] shrink-0 flex-col items-center justify-center bg-z-turquoise-50 text-z-turquoise-600 shadow-sm">
      <button
        v-if="currentStep != 0"
        class="absolute left-24"
        @click="handleStepBack"
      >
        <img
          src="@/assets/icons/chevron-left-turquoise.svg"
          class="h-8"
        >
      </button>
      <p class="font-semibold">
        {{ currentStep + 1 }} de {{ STEPS.length }}
      </p>
      <p>{{ STEPS[currentStep].title }}</p>
    </div>
  </div>
  <div class="mt-4">
    <div
      v-if="currentStep === 0"
      class="mx-6 flex flex-col space-y-6"
    >
      <base-input
        v-model="rut"
        name="rut"
        label="RUT"
        placeholder="RUT"
        :error="error"
        @update:model-value="handleRutInput"
      />
      <base-input
        v-model="phoneNumber"
        name="phoneNumber"
        label="Número de teléfono"
        placeholder="Número de teléfono"
        type="tel"
        minlength="9"
        maxlength="9"
        :error="error"
      >
        <template #before>
          <div class="flex h-14 w-14 items-center justify-center rounded border-y border-l border-z-gray-200 bg-z-gray-100 px-3 py-4">
            +56
          </div>
        </template>
      </base-input>
      <base-input
        v-model="birthDate"
        type="date"
        name="birthDate"
        label="Fecha de nacimiento"
        placeholder="Fecha de nacimiento"
        label-outside
      />
    </div>
    <template v-else-if="currentStep === 1">
      <profile-new-bank-account-form
        button-label="Verificar"
        :rut="rut"
        @success="toggleSuccessModal"
      />
    </template>
  </div>
  <div
    v-if="currentStep === 0"
    class="fixed bottom-0 w-full px-6 py-4"
  >
    <base-button
      class="w-full"
      :disabled="!rut || !birthDate || !phoneNumber || loading"
      @click="handleVerify"
    >
      <img
        v-if="loading"
        src="@/assets/icons/loading-alt.svg"
        class="w-10 animate-spin-slow"
      >
      <p v-else>
        {{ showBankAccountStep && currentStep === 0 ? 'Continuar' : 'Verificar' }}
      </p>
    </base-button>
  </div>
  <success-modal
    :open="showSuccessModal"
    description="Su cuenta ha sido verificada exitosamente."
    @confirm="handleSuccess"
  />
</template>
