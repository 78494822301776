<script setup lang="ts">
import { inject, ref } from 'vue';
import theNavbarSearch from './the-navbar-search.vue';
import theNavbarMenu from './the-navbar-menu.vue';
import notificationDropdown from './notification-dropdown.vue';

interface Props {
  hideRibbon?: boolean
}

defineProps<Props>();
const user = inject<User>('user');

const searchFocus = ref(false);
</script>
<template>
  <div class="sticky top-0 z-20 flex w-full flex-col">
    <div
      v-if="!user?.partnerAccountId && !hideRibbon"
      class="flex h-10 items-center justify-center space-x-2 bg-z-turquoise-600 text-xs text-white"
    >
      <p>Únete a la nueva forma de arrendar</p>
      <a
        href="/productos/crear"
        class="flex rounded bg-white px-2 py-0.5 text-z-turquoise-600"
      >
        ¡Hazte socio!
        <img
          src="@/assets/icons/chevron-right.svg"
          alt="Ícono de un chevron hacia la derecha"
        >
      </a>
    </div>
    <div class="flex h-16 w-full items-center justify-between space-x-2 border-b border-z-gray-100 bg-white p-4 md:h-[72px] md:space-x-8 md:px-[12%]">
      <div class="flex w-full items-center space-x-2 md:space-x-8">
        <TransitionGroup>
          <a
            v-if="!searchFocus"
            href="/menu"
            class="shrink-0 md:hidden"
          ><img
            src="@/assets/icons/menu.svg"
            class="w-6"
          ></a>
          <a
            v-if="!searchFocus"
            href="/"
            class="min-w-fit"
            aria-label="Ir a la página principal de Zirkular"
          >
            <img
              src="@/assets/images/zirkular-logo.png"
              class="w-[72px] md:w-[93px]"
              alt="Logo de Zirkular"
            >
          </a>
        </TransitionGroup>
        <the-navbar-search
          @focusin="searchFocus = true"
          @focusout="searchFocus = false"
        />
      </div>
      <div class="flex shrink-0 items-center space-x-2 md:space-x-8">
        <notification-dropdown
          v-if="user"
        />
        <the-navbar-menu />
      </div>
    </div>
  </div>
</template>
<style scoped>
.v-enter-active,
.v-leave-active {
  transition: opacity 0.1s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
</style>
