<script setup lang="ts">
import { computed, useAttrs } from 'vue';

interface Props {
  disabled?: boolean;
  variant?: 'primary' | 'secondary' | 'danger';
  size?: 'sm' | 'md'
  notification?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  disabled: false,
  variant: 'primary',
  size: 'md',
  notification: false,
});

const attrs = useAttrs();
const currentTag = computed(() => (attrs.href ? 'a' : 'button'));

const variants = {
  primary: 'bg-z-turquoise-600 text-white',
  secondary: 'border border-z-turquoise-600 text-z-turquoise-600 hover:text-white hover:bg-z-turquoise-600',
  danger: 'border border-z-red bg-white text-z-red hover:bg-z-red hover:text-white',
};

const sizes = {
  sm: 'h-10',
  md: 'h-12',
};

const dynamicClasses = computed(() => [variants[props.variant], sizes[props.size]].join(' '));
</script>
<template>
  <component
    :is="currentTag"
    class="relative flex items-center justify-center rounded text-center transition disabled:bg-z-gray-500"
    :class="dynamicClasses"
    :disabled="disabled"
  >
    <div
      v-if="notification"
      class="absolute -right-1 -top-1 h-4 w-4 rounded-full bg-amber-400 shadow-sm "
    />
    <slot />
  </component>
</template>
