<script setup lang="ts">
import { productBookingsApi } from '@/api/product-bookings';
import { loadMercadoPago } from '@mercadopago/sdk-js';
import { computed, inject, onBeforeMount, ref } from 'vue';
import { add, formatDistanceToNowStrict } from 'date-fns';

interface Props {
  productBooking: ProductBooking
}

const user = inject<User>('user');
const props = defineProps<Props>();

const skipPayment = import.meta.env.VITE_SKIP_PAYMENT === 'true';

onBeforeMount(async () => {
  await loadMercadoPago();
  const mp = new window.MercadoPago(import.meta.env.VITE_MERCADOPAGO_PUBLIC_KEY);
  const bricksBuilder = mp.bricks();
  bricksBuilder.create('wallet', `wallet_container_${props.productBooking.id}`, {
    initialization: {
      preferenceId: props.productBooking.mercadopagoPreferenceId,
    },
    customization: {
      hideValueProp: true,
    },
  });
});

const loading = ref(false);
const error = ref(false);

const paymentTimeLimit = inject<number>('paymentTimeLimit');

// eslint-disable-next-line vue/return-in-computed-property
const remainingTime = computed(() => formatDistanceToNowStrict(add(
  new Date(props.productBooking.updatedAt),
  { hours: paymentTimeLimit },
)));

const showCancelModal = ref(false);

function toggleCancelModal() {
  showCancelModal.value = !showCancelModal.value;
}

async function cancelBooking() {
  try {
    loading.value = true;
    await productBookingsApi.update(props.productBooking.id, { canceled: true, canceledBy: 'client' })
      .then(() => {
        window.location.reload();
      });
  } catch (e) {
    error.value = true;
  } finally {
    loading.value = false;
  }
}

const showVerifyModal = ref(false);
function toggleVerifyModal() {
  showVerifyModal.value = !showVerifyModal.value;
}

function handleSkip() {
  if (user?.verified) {
    productBookingsApi.update(props.productBooking.id, { currentStatus: 'paid' })
      .then(() => {
        window.location.replace('#reservados');
        window.location.reload();
      });
  } else {
    toggleVerifyModal();
  }
}
</script>
<template>
  <div class="flex flex-col">
    <base-tip
      title="Tiempo restante"
      variant="bold"
    >
      Te quedan <span class="font-semibold">{{ remainingTime }}</span> para pagar esta reserva. Cumplido el tiempo, se cancelará automáticamente.
    </base-tip>
    <div
      :id="`wallet_container_${productBooking.id}`"
    />
    <base-button
      v-if="skipPayment"
      size="sm"
      class="mt-3"
      @click="handleSkip"
    >
      Omitir pago (ambiente de prueba)
    </base-button>
    <base-button
      variant="danger"
      size="sm"
      class="mt-3"
      @click="toggleCancelModal"
    >
      Cancelar reserva
    </base-button>
    <base-modal :open="showCancelModal">
      <div class="relative flex w-full flex-col items-center">
        <button
          class="absolute right-0 top-0"
          @click="toggleCancelModal"
        >
          <img src="@/assets/icons/close.svg">
        </button>
        <img
          src="@/assets/icons/warning-alt-2.svg"
          class="h-20 w-20"
        >
        <p class="mt-2 text-center text-lg font-bold text-z-gray-900">
          ¿Estás seguro que deseas cancelar tu solicitud?
        </p>
        <base-button
          class="mt-4 w-full"
          variant="danger"
          @click="cancelBooking"
        >
          Cancelar solicitud
        </base-button>
      </div>
    </base-modal>
  </div>
  <base-modal :open="showVerifyModal">
    <div class="flex w-full flex-col items-center">
      <img
        src="@/assets/icons/information-circle.svg"
        class="h-20 w-20"
      >
      <p class="mt-2 text-lg font-bold text-z-gray-900">
        Antes de ello...
      </p>
      <p class="mt-2 text-center text-z-gray-800">
        Por motivos de seguridad, debes verificar tu identidad.
      </p>
      <base-button
        href="/perfil/verificar?from=payment&redirect_to=/perfil/solicitudes_reservas/cliente"
        class="mt-4 w-full"
      >
        <p>
          Verificar
        </p>
        <img
          src="@/assets/icons/arrow-small-right.svg"
          class="ml-2"
        >
      </base-button>
    </div>
  </base-modal>
</template>
