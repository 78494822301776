<script setup lang="ts">
import { addressesApi } from '@/api/addresses';
import { inject, ref } from 'vue';

interface Props {
  address: AddressData
}

const props = defineProps<Props>();

const user = inject<User>('user');

const place = ref<google.maps.places.PlaceResult>();
const errorEmpty = ref(false);
const error = ref(false);
const alias = ref(props.address.alias);

const showSuccessModal = ref(false);
function toggleSuccessModal() {
  showSuccessModal.value = !showSuccessModal.value;
}

function updateAddress() {
  if (place.value) {
    try {
      const addressData = {
        street: '',
        streetNumber: '',
        commune: '',
        region: '',
        latitude: 0.0,
        longitude: 0.0,
        alias: alias.value,
      };

      addressData.latitude = place.value.geometry?.location?.lat() ?? 0.0;
      addressData.longitude = place.value.geometry?.location?.lng() ?? 0.0;

      place.value.address_components?.forEach((addressComponent) => {
        switch (addressComponent.types[0]) {
        case 'street_number':
          addressData.streetNumber = addressComponent.long_name;
          break;
        case 'route':
          addressData.street = addressComponent.long_name;
          break;
        case 'locality':
          addressData.commune = addressComponent.long_name;
          break;
        case 'administrative_area_level_1':
          addressData.region = addressComponent.long_name;
          break;
        default:
          // Do nothing
        }
      });

      addressesApi.update(props.address.id, { ...addressData, userId: user?.id }).then(() => toggleSuccessModal());
    } catch (e) {
      error.value = true;
    }
  } else {
    errorEmpty.value = true;
  }
}

function handleBack() {
  window.history.back();
}

</script>
<template>
  <the-layout
    :active-menu="3"
    hide-navbar
  >
    <div class="relative bg-white md:mx-auto md:my-6 md:h-[calc(100vh-72px-24px-24px)] md:max-w-md md:overflow-y-scroll md:rounded">
      <the-titlebar
        title="Mis direcciones"
        @back="handleBack"
      />
      <div class="flex flex-col space-y-4 p-6">
        <base-input
          v-model="alias"
          name="alias"
          placeholder="Casa, oficina, etc. (opcional)"
          label="Casa, oficina, etc. (opcional)"
        />
        <base-google-autocomplete
          v-model="place"
          name="place"
          placeholder="Ingresa una dirección"
          :initial-value="address.displayName"
          :error="errorEmpty"
        />
      </div>
      <div class="fixed bottom-16 w-full px-6 py-2 md:bottom-8 md:max-w-md">
        <base-button
          class="w-full"
          @click="updateAddress"
        >
          Guardar
        </base-button>
      </div>
      <base-modal :open="showSuccessModal">
        <div class="flex w-full flex-col items-center">
          <img
            src="@/assets/icons/check-circle.svg"
            class="h-20 w-20"
          >
          <p class="mt-4 font-medium text-z-gray-900">
            Excelente!
          </p>
          <p class="mt-6 text-center text-z-gray-800">
            Hemos registrado tu cuenta de forma exitosa.
          </p>
          <base-button
            class="mt-4 w-full"
            :disabled="!place"
            @click="handleBack()"
          >
            Aceptar
          </base-button>
        </div>
      </base-modal>
    </div>
  </the-layout>
</template>
