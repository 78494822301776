<script setup lang="ts">
import { productBookingReturnsApi } from '@/api/product-booking-returns';
import { ref } from 'vue';

interface Props {
  open: boolean;
  productBookingReturnId:number;
  chatId:number;
}
const props = defineProps<Props>();

const picture = ref();

const loading = ref(false);

const showAwaitingMessage = ref(false);

const showNoPictureConfirmation = ref(false);
function toggleNoPictureConfirmation() {
  showNoPictureConfirmation.value = !showNoPictureConfirmation.value;
}

function handleReturn() {
  loading.value = true;
  productBookingReturnsApi.update(
    props.productBookingReturnId, { status: 'ongoing' }, picture.value)
    .then(() => {
      showAwaitingMessage.value = true;
      loading.value = false;
    });
}

function handleContinue() {
  if (picture.value) {
    handleReturn();
  } else {
    toggleNoPictureConfirmation();
  }
}
</script>
<template>
  <base-modal
    :open="open"
  >
    <div
      v-if="showAwaitingMessage"
      class="flex w-full flex-col items-center"
    >
      <img
        src="@/assets/icons/information-circle.svg"
        class="w-24 "
      >
      <p class="mt-2 font-bold text-z-gray-900">
        A la espera del socio
      </p>
      <p class="mt-2 text-center text-z-gray-800">
        Para que se complete la devolución, el socio debe confirmar el estado y conformidad del producto.
      </p>
      <base-button
        class="mt-8 w-full"
        :href="`/chats/${chatId}`"
      >
        Ir al chat
      </base-button>
    </div>
    <div
      v-else-if="showNoPictureConfirmation"
      class="flex w-full flex-col items-center"
    >
      <img
        src="@/assets/icons/information-circle.svg"
        class="w-20 self-center"
      >
      <p class="mt-4 text-center text-z-gray-900">
        Zirkular te recomienda tomar una foto para respaldar tu devolución y el estado en que devuelves el producto.
      </p>
      <base-button
        class="mt-3 w-full"
        @click="toggleNoPictureConfirmation"
      >
        Volver atrás
      </base-button>
      <base-button
        variant="secondary"
        class="mt-3 w-full"
        @click="handleReturn"
      >
        Continuar sin foto
      </base-button>
    </div>
    <div
      v-else
      class="flex w-full flex-col items-center"
    >
      <p class="w-full font-bold text-z-gray-900">
        Foto del producto
      </p>
      <p class="mt-2 text-sm text-z-gray-800">
        Toma una foto del producto para confirmar el estado del producto devuelto.
      </p>
      <base-drag-and-drop
        v-model="picture"
        name="picture"
        class="mt-4 w-full"
        wide
      />
      <base-button
        class="mt-4 w-full"
        :disabled="loading"
        @click="handleContinue"
      >
        <img
          v-if="loading"
          src="@/assets/icons/loading-alt.svg"
          class="w-10 animate-spin-slow"
        >
        <p v-else>
          Continuar
        </p>
      </base-button>
    </div>
  </base-modal>
</template>
