<script setup lang="ts">
import { bankAccountsApi } from '@/api/bank-accounts';
import { bankEntitiesApi } from '@/api/bank-entities';
import { inject, onBeforeMount, ref } from 'vue';
import { number, object, string } from 'yup';
import { rutFormat, rutValidate } from 'rut-helpers';

interface Props {
  bankAccount?: BankAccount
  buttonLabel?: string
  rut?: string
}

const props = withDefaults(defineProps<Props>(), {
  buttonLabel: 'Guardar',
  bankAccount: undefined,
  rut: '',
});

const bankEntityOptions = ref<BankEntity[]>([{ id: 0, name: 'No hay bancos disponibles' }]);
const kindOptions = [
  { name: 'Cuenta de ahorro', id: 'savings_account' },
  { name: 'Cuenta corriente', id: 'checking_account' },
  { name: 'Cuenta vista', id: 'sight_account' },
];

const user = inject<User>('user');

const name = ref(props.bankAccount?.name ?? '');
const rut = ref(props.rut ?? (props.bankAccount?.rut || user?.rut));
const bankEntityId = ref(props.bankAccount?.bankEntity.id ?? 0);
const kind = ref(props.bankAccount?.kind ?? '0');
const accountNumber = ref(props.bankAccount?.number ?? '');

onBeforeMount(() => {
  bankEntitiesApi.index().then((response) => {
    bankEntityOptions.value = response.data.bankEntities;
  });
});

const formRules = object().shape({
  name: string().required(),
  bankEntityId: number().required('test').min(1),
  kind: string().required('Debes seleccionar un rango')
    .oneOf(kindOptions.map((el) => el.id), 'Debes seleccionar un rango'),
  accountNumber: string().required(),
  rut: string().required('Debes ingresar un rut válido')
    .test((value) => {
      if (value) {
        return rutValidate(value);
      }

      return false;
    }),
});

const loading = ref(false);
const error = ref(false);

const emit = defineEmits<{(e: 'success'): void}>();

function handleBankAccount() {
  const formValues = {
    name: name.value,
    bankEntityId: bankEntityId.value,
    kind: kind.value,
    number: accountNumber.value,
    rut: rut.value,
  };
  try {
    loading.value = true;
    if (props.bankAccount) {
      bankAccountsApi.update(props.bankAccount.id, formValues)
        .then(() => emit('success'));
    } else {
      bankAccountsApi.create(formValues)
        .then(() => emit('success'));
    }
  } catch (e) {
    error.value = true;
  } finally {
    loading.value = false;
  }
}

function handleRutInput() {
  error.value = false;
  rut.value = rutFormat(rut.value);
}
</script>
<template>
  <v-form
    v-slot="{ meta, handleSubmit, errors }"
    class="md:max-w-md"
    :validation-schema="formRules"
  >
    <div class="flex flex-col space-y-4 p-6">
      <base-input
        v-model="name"
        name="name"
        label="Nombre del titular"
        placeholder="Nombre del titular"
        :error="meta.touched && !!errors.name"
      />
      <base-input
        v-model="rut"
        name="rut"
        label="RUT"
        placeholder="RUT"
        :error="meta.touched && !!errors.name"
        disabled
        @update:model-value="handleRutInput"
      />
      <base-select
        v-model="bankEntityId"
        :options="bankEntityOptions"
        name="bankEntityId"
        label="Selecciona un banco"
        placeholder="Selecciona un banco"
        hide-label
        :error="meta.touched && !!errors.bankEntity"
      />
      <base-select
        v-model="kind"
        :options="kindOptions"
        name="kind"
        label="Tipo de cuenta"
        placeholder="Tipo de cuenta"
        hide-label
        :error="meta.touched && !!errors.kind"
      />
      <base-input
        v-model="accountNumber"
        name="accountNumber"
        label="Número de cuenta"
        placeholder="Número de cuenta"
        :error="meta.touched && !!errors.accountNumber"
      />
    </div>
    <div class="fixed bottom-16 w-full px-6 py-4 md:bottom-4 md:max-w-md">
      <base-button
        class="w-full"
        @click="handleSubmit($event, handleBankAccount)"
      >
        {{ buttonLabel }}
      </base-button>
    </div>
  </v-form>
</template>
