<script setup lang="ts">
import { Loader } from '@googlemaps/js-api-loader';
import { onMounted, ref } from 'vue';

interface Props {
  name: string
  placeholder?: string
  error?: boolean
  initialValue?: string
}
const props = withDefaults(defineProps<Props>(), {
  placeholder: '',
  initialValue: undefined,
});

const model = defineModel<google.maps.places.PlaceResult>();
const searchQuery = ref(props.initialValue);

onMounted(async () => {
  const loader = new Loader({
    apiKey: import.meta.env.VITE_GOOGLE_PLACES_API_KEY,
    version: 'beta',
  });
  const Places = await loader.importLibrary('places');
  const placeInput = document.getElementById(props.name);
  const autocomplete = new Places.Autocomplete(placeInput as HTMLInputElement, {
    componentRestrictions: { country: 'cl' },
    types: ['address'],
    fields: [
      'address_components',
      'geometry',
    ],
  });
  autocomplete.addListener('place_changed', () => {
    model.value = autocomplete.getPlace();
  });
});
</script>
<template>
  <base-input
    :id="name"
    v-model="searchQuery"
    :name="name"
    :placeholder="placeholder"
    :label="placeholder"
    :error="error"
  />
</template>
<style>
  .pac-container {
    margin-top: 0.5rem;
  }
</style>
