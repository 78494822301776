<script setup lang="ts">
import { Listbox,
  ListboxButton,
  ListboxOptions,
  ListboxOption } from '@headlessui/vue';
import { useField } from 'vee-validate';
import { watch } from 'vue';

interface Props {
  modelValue?: string | number;
  options: SelectOption[];
  label: string;
  name: string;
  required?: boolean;
  hideLabel?: boolean
  placeholder?: string;
  error?: boolean
  displayKey?: string;
  disabled?:boolean;
  overflow?: 'clamp' | 'wrap'
}

const props = withDefaults(defineProps<Props>(), {
  modelValue: undefined,
  placeholder: undefined,
  displayKey: 'name',
  overflow: 'clamp',
});

defineEmits<{(e: 'update:modelValue', value: string | number): void}>();

const {
  meta,
  value: inputValue,
  handleBlur,
  handleChange,
} = useField(props.name, undefined, {
  initialValue: props.modelValue,
  valueProp: props.modelValue,
});

function handleInputChange(e: string | number | SelectOption) {
  handleChange(e);
}

watch(
  () => props.modelValue,
  (newModel) => {
    if (newModel) {
      if (newModel === inputValue.value) {
        return;
      }
      inputValue.value = newModel;
    }
  },
);
</script>
<template>
  <div class="flex w-full flex-col">
    <base-label
      v-if="!hideLabel"
      :label="label"
      :name="name"
      :required="required"
    />
    <Listbox
      v-slot="{open}"
      v-model="inputValue"
      as="div"
      class="relative"
      :name="name"
      @update:model-value="handleInputChange"
      @blur="handleBlur"
    >
      <base-modal
        v-if="open"
        :open="open"
      >
        <div class="flex w-full flex-col">
          <div class="flex w-full items-center justify-between">
            <p class="font-medium">
              {{ placeholder }}
            </p>
            <ListboxButton
              as="button"
            >
              <img
                src="@/assets/icons/close.svg"
                class="h-5 w-5"
              >
            </ListboxButton>
          </div>
          <ListboxOptions
            as="div"
            class="mt-5 flex flex-col overflow-y-scroll pt-2"
          >
            <slot name="before-options" />
            <ListboxOption
              v-for="option in options"
              :key="option.id"
              :value="option.id"
              as="button"
              class="flex h-16 w-full shrink-0 items-center text-z-gray-900"
            >
              <p
                class="text-start"
                :class="{ 'line-clamp-1': overflow === 'clamp' }"
              >
                {{ option[displayKey] }}
              </p>
            </ListboxOption>
            <slot name="after-options" />
          </ListboxOptions>
        </div>
      </base-modal>
      <ListboxButton
        as="button"
        class="flex h-14 w-full items-center justify-between rounded border bg-white px-3 text-gray-900 outline-none"
        :class="error && meta.touched ? 'border-z-red': 'border-z-gray-200'"
        :disabled="disabled"
      >
        <p>{{ options?.find((option) => option.id === inputValue)?.name || placeholder }}</p>
        <img src="@/assets/icons/chevron-down.svg">
      </ListboxButton>
    </Listbox>
  </div>
</template>
