<script setup lang="ts">
import { differenceInCalendarDays, isToday } from 'date-fns';
import { computed, ref } from 'vue';
import beginReturnModal from '../begin-return-modal.vue';

interface Props {
  productBooking: ProductBooking
}

const props = defineProps<Props>();

const remainingTime = computed(() => differenceInCalendarDays(new Date(props.productBooking.endDate), new Date()));

const returnToday = computed(() => isToday(new Date(props.productBooking.endDate)));

const showBeginReturnModal = ref(false);
function toggleBeginReturnModal() {
  showBeginReturnModal.value = !showBeginReturnModal.value;
}
</script>
<template>
  <div class="flex flex-col">
    <base-tip
      v-if="returnToday"
      title="Hoy es la devolución"
      variant="bold"
    >
      Coordina la devolución del producto con el socio a través del
      <a
        :href="`/chats/${productBooking.chatId}`"
        class="font-medium underline underline-offset-2"
      >
        chat
      </a>.
    </base-tip>
    <base-tip
      v-else
      title="Devolución"
      variant="bold"
    >
      {{ remainingTime === 1 ? `Falta ${remainingTime} día` : `Faltan ${remainingTime} días` }} para la devolución
    </base-tip>
    <base-button
      v-if="!productBooking.productBookingReturn"
      size="sm"
      class="mt-3"
      @click="toggleBeginReturnModal"
    >
      Realizar la devolución
    </base-button>
    <base-button
      v-else-if="productBooking.productBookingReturn?.status === 'awaiting_geolocation'"
      size="sm"
      class="mt-3"
      :href="`/perfil/solicitudes_reservas/${productBooking.id}/ubicacion`"
    >
      Reanudar la devolución
    </base-button>
    <base-button
      v-else-if="productBooking.productBookingReturn?.status === 'ongoing'"
      class="mt-3"
      disabled
      size="sm"
    >
      Esperando al socio
    </base-button>
  </div>
  <begin-return-modal
    v-if="showBeginReturnModal"
    :open="showBeginReturnModal"
    :product-booking-id="productBooking.id"
    :chat-id="productBooking.chatId"
    :address="productBooking.address as AddressData"
    @close="toggleBeginReturnModal"
  />
</template>
