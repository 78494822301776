<script setup lang="ts">
import { useAttrs } from 'vue';

interface Props {
  label: string
  name: string
  required?: boolean
}

const attrs = useAttrs();

defineProps<Props>();
</script>

<template>
  <label
    :for="name"
    v-bind="attrs"
  >
    {{ label }}
    <slot />
    <span
      v-if="required"
      class="text-z-red"
    >
      *
    </span>
  </label>
</template>
