<script setup lang="ts">
import { camelCase, sortBy, reverse } from 'lodash';
import { currency } from '@/utils/currency';
import { computed } from 'vue';
import { formatDate } from '@/utils/format-date';

interface Props {
  product: Product,
  productBookings: ProductBooking[]
}

const props = defineProps<Props>();

const sortedBookings = computed(() => reverse(sortBy(props.productBookings, 'startDate')));

const totalEarnings = computed(() => props.productBookings.reduce((acum, booking) => acum + booking.amount, 0));

function handleBack() {
  window.history.back();
}
</script>
<template>
  <the-layout
    :active-menu="3"
    hide-layout
  >
    <div class="relative bg-white md:mx-auto md:my-6 md:h-[calc(100vh-72px-24px-24px)] md:max-w-md md:overflow-y-scroll md:rounded">
      <div class="flex h-full w-full flex-col bg-white">
        <the-titlebar
          :title="product.name"
          @back="handleBack"
        />

        <p class="mx-6 mb-3 mt-4 self-end rounded bg-z-turquoise-50 px-2 py-0.5 text-xs text-z-turquoise-600">
          {{ $t(`product.condition.${ camelCase(product.condition)}`) }}
        </p>
        <div class="flex flex-col space-y-4 px-6">
          <img
            :src="product.productPictures[0].picture.webpMd.url"
            class="aspect-square w-full rounded-lg border border-z-gray-100 object-cover"
          >
          <div class="grid grid-cols-3 gap-4">
            <img
              v-for="picture in product.productPictures.slice(1)"
              :key="picture.id"
              :src="picture.picture.webpSm.url"
              class="aspect-square w-full rounded-lg border border-z-gray-100 object-cover"
            >
          </div>
          <p class="font-medium">
            {{ product.name }}
          </p>
          <div class="flex space-x-2 rounded-lg bg-z-turquoise-50 p-3">
            <img
              src="@/assets/icons/money-bag.svg"
              class="h-6 w-6"
            >
            <div class="flex flex-col">
              <p class="text-xs">
                Este producto hizo un total de
              </p>
              <p class="text-[28px] font-semibold text-z-turquoise-600">
                {{ currency(totalEarnings) }}
              </p>
            </div>
          </div>
        </div>
        <div class="mt-4 divide-y-2 divide-z-gray-100 border-t-2 border-z-gray-100">
          <div
            v-for="productBooking in sortedBookings"
            :key="productBooking.id"
            class="flex space-x-2 px-6 py-4"
          >
            <img
              :src="productBooking.clientAccount.pictureUrl"
              class="h-6 w-6 rounded-full object-cover"
            >
            <div class="flex flex-col space-y-0.5">
              <p class="text-xs">
                Reservado por {{ productBooking.clientAccount.firstName }}
              </p>
              <p class="text-xs text-z-gray-600">
                {{ formatDate(productBooking.startDate) }}
                - {{ formatDate(productBooking.endDate) }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </the-layout>
</template>
