<script setup lang="ts">
import { productBookingReturnsApi } from '@/api/product-booking-returns';
import { ref } from 'vue';

interface Props {
  open: boolean;
  productBookingId:number;
  address:AddressData;
  chatId:number;
}
const props = defineProps<Props>();

const emit = defineEmits<{
  (e: 'close'): void
}>();

function closeModal() {
  emit('close');
}

const loading = ref(false);

function createReturn() {
  loading.value = true;
  productBookingReturnsApi.create(props.productBookingId)
    .then(() => {
      loading.value = false;
      window.location.href = `/perfil/solicitudes_reservas/${props.productBookingId}/ubicacion`;
    });
}
</script>
<template>
  <base-modal
    :open="open"
  >
    <div

      class="flex w-full flex-col items-center"
    >
      <img
        src="@/assets/icons/information-circle.svg"
        class="w-24 "
      >
      <p class="mt-2 font-bold text-z-gray-900">
        Importante
      </p>
      <p class="mt-2 text-center text-z-gray-800">
        Antes de continuar, debes estar con el socio en el punto de devolución.
      </p>
      <base-button
        class="mt-8 w-full"
        :disabled="loading"
        @click="createReturn"
      >
        <img
          v-if="loading"
          src="@/assets/icons/loading-alt.svg"
          class="w-10 animate-spin-slow"
        >
        <p v-else>
          Realizar la devolución
        </p>
      </base-button>
      <base-button
        variant="danger"
        class="mt-3 w-full"
        @click="closeModal"
      >
        Cancelar
      </base-button>
    </div>
  </base-modal>
</template>
