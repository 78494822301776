<script setup lang="ts">
import { computed, ref } from 'vue';
import { orderBy } from 'lodash';
import { Disclosure, DisclosureButton, DisclosurePanel } from '@headlessui/vue';
import productCard from './product-card.vue';

interface Props {
  products: Product[]
  categories: Category[]
  questions: FrequentlyAskedQuestion[]
}

const props = defineProps<Props>();

const showPopover = ref(true);

function closePopover() {
  showPopover.value = false;
}

const sortedCategories = computed(() => orderBy(props.categories, 'position'));
</script>
<template>
  <the-layout
    :active-menu="0"
  >
    <div class="w-full md:bg-white">
      <vueper-slides
        autoplay
        :arrows="false"
        :bullets="false"
        :slide-ratio="1001/1501"
        :touchable="false"
        class="md:hidden"
      >
        <vueper-slide>
          <template #content>
            <img
              src="@/assets/images/banner1.webp"
              alt="Banner con dos personas dándose la mano. Se lee 'La primera plataforma digital para el arriendo de personas'."
              fetchpriority="high"
            >
          </template>
        </vueper-slide>
        <vueper-slide>
          <template #content>
            <img
              src="@/assets/images/banner2.webp"
              loading="lazy"
              alt="Banner con una persona usando su celular feliz. Se lee 'Sube tus productos antes del lanzamiento.'"
            >
          </template>
        </vueper-slide>
      </vueper-slides>
      <vueper-slides
        autoplay
        :arrows="false"
        :bullets="false"
        :slide-ratio="5/16"
        :touchable="false"
        class="hidden md:block"
      >
        <vueper-slide>
          <template #content>
            <img
              src="@/assets/images/banner-desktop1.webp"
              alt="Banner con dos personas dándose la mano. Se lee 'La primera plataforma digital para el arriendo de personas'."
              fetchpriority="high"
            >
          </template>
        </vueper-slide>
        <vueper-slide>
          <template #content>
            <img
              src="@/assets/images/banner-desktop2.webp"
              loading="lazy"
              alt="Banner con una persona usando su celular feliz. Se lee 'Sube tus productos antes del lanzamiento.'"
            >
          </template>
        </vueper-slide>
      </vueper-slides>
      <div class="m-2 rounded bg-gradient-to-br from-z-pink-1 to-z-pink-2 px-3 py-2 md:mx-[12%]">
        <p class="text-xs font-semibold text-white">
          ¡Hazte socio Zirkular!
        </p>
        <p class="text-xs text-white">
          Sube tus productos antes del 30 de noviembre y disfruta de 10 arriendos sin costo por servicio.
        </p>
      </div>
      <div class="flex h-44 space-x-8 overflow-x-scroll px-6 pt-6 scrollbar-hide">
        <a
          v-for="category in sortedCategories"
          :key="category.id"
          :href="`/categorias/${category.id}`"
          class="flex w-32 flex-col items-center space-y-2"
        >
          <div class="flex h-20 w-20 items-center justify-center rounded-full bg-z-gray-100">
            <img
              :src="category.picture.webpSm.url"
              class="h-full w-full rounded-full object-cover"
              :alt="`Imagen que representa la categoría ${category.name}`"
            >
          </div>
          <p class="text-center text-xs">
            {{ category.name }}
          </p>
        </a>
      </div>
      <div class="px-6 py-8 md:px-[12%]">
        <div class="flex items-center justify-between">
          <p class="text-xl font-medium text-z-gray-800">
            Los más populares
          </p>
          <base-button
            variant="secondary"
            href="/productos"
            class="hidden md:flex md:px-10"
          >
            <p>Ver más</p>
            <img
              src="@/assets/icons/arrow-right.svg"
              class="ml-1 h-6 w-6"
              alt="Ícono de flecha apuntando a la derecha"
            >
          </base-button>
        </div>
        <div
          v-if="products"
          class="mt-4 grid grid-cols-2 gap-4 md:grid-cols-6"
        >
          <product-card
            v-for="product in products"
            :key="product.id"
            :product="product"
            lazy
          />
        </div>
      </div>
      <base-button
        variant="secondary"
        href="/productos"
        class="mx-6 my-4 md:hidden"
      >
        <p>Ver más</p>
        <img
          src="@/assets/icons/arrow-right.svg"
          class="ml-1 h-6 w-6"
          alt="Ícono de flecha apuntando a la derecha"
        >
      </base-button>
      <div class="flex flex-col space-y-4 bg-z-turquoise-50 px-6 py-8 text-gray-800 md:grid md:grid-cols-3 md:space-y-0 md:px-[12%]">
        <div class="flex flex-col items-center space-y-2 px-3 pb-2 pt-3">
          <img
            src="@/assets/icons/ticket.svg"
            class="h-8 w-8"
            alt="Ícono de ticket o dinero"
          >
          <p class="text-center font-medium">
            ¡Convierte tus cosas en dinero!
          </p>
          <p class="text-center text-sm">
            Obtén dinero de manera rápida, cómoda y segura publicando tus artículos contribuyendo
            con una economía circular.
          </p>
        </div>
        <div class="flex flex-col items-center space-y-2 px-3 pb-2 pt-3">
          <img
            src="@/assets/icons/home.svg"
            class="h-8 w-8"
            alt="Ícono de hogar"
          >
          <p class="font-medium">
            ¡Formemos comunidad!
          </p>
          <p class="text-center text-sm">
            Entre más somos, más productos disponibles, todos ganamos y ahorramos. Generemos un entorno colaborativo y amigable junto a <span class="font-medium">Zirkular.</span>
          </p>
        </div>
        <div class="flex flex-col items-center space-y-2 px-3 pb-2 pt-3">
          <img
            src="@/assets/icons/brightness.svg"
            class="h-9 w-9"
            alt="Ícono de sol"
          >
          <p class="font-medium">
            ¡Contribuimos con el medio ambiente!
          </p>
          <p class="text-center text-sm">
            Cada arriendo disminuye la huella de carbono que genera la compra de artículos nuevos y que muchas veces,
            sólo utilizamos una vez.
            <br>Démosle una nueva vida a nuestras pertenencias y aportemos la preservación del medio ambiente
          </p>
        </div>
      </div>
      <div class="flex flex-col md:grid md:grid-cols-2 md:px-[12%]">
        <div class="flex flex-col px-6 pb-4 pt-10 md:order-last md:pt-8">
          <p class="text-xl font-medium text-z-gray-900">
            ¿Qué nos preguntan?
          </p>
          <div
            v-if="questions"
            class="flex flex-col pb-2"
          >
            <Disclosure
              v-for="question in questions"
              :key="question.id"
              v-slot="{ open }"
            >
              <DisclosureButton class="flex items-start justify-between py-5 font-medium text-z-gray-900">
                <p class="text-left">
                  {{ question.question }}
                </p>
                <img
                  src="@/assets/icons/chevron-left.svg"
                  :class="open ? 'rotate-90' : '-rotate-90'"
                  :alt="`Ícono de un chevron hacia ${open ? 'arriba' : 'abajo'}`"
                >
              </DisclosureButton>
              <DisclosurePanel class="mb-6 text-sm text-z-gray-800">
                <p>{{ question.answer }}</p>
              </DisclosurePanel>
            </Disclosure>
          </div>
          <base-button
            variant="secondary"
            class="space-x-1"
            href="/preguntas_frecuentes"
          >
            <p>Ver más</p>
            <img
              src="@/assets/icons/chevron-right.svg"
              alt="Ícono de chevron hacia la derecha"
            >
          </base-button>
        </div>
        <div class="flex flex-col pb-14 pt-8 md:items-center">
          <p class="px-6 text-center text-xl font-medium text-z-gray-800">
            <span class="underline underline-offset-4">Zirkulando</span> tus cosas, <br class="md:hidden">¡todos ganamos!
          </p>
          <img
            src="@/assets/images/products.webp"
            class="md:w-96"
            loading="lazy"
            alt="Fondo circular de color turquesa con una escalera, una parrilla, una tabla de surf y otros objetos caseros"
          >
          <base-button
            href="/productos/crear"
            class="mx-6 mt-4 md:w-fit md:px-6"
          >
            ¡Sube tus productos!
          </base-button>
        </div>
      </div>
      <the-footer />
    </div>
    <div
      v-if="showPopover"
      class="fixed bottom-16 z-10 w-full md:bottom-0 md:right-0 md:max-w-md"
    >
      <div class="m-6 flex rounded bg-white p-4 shadow">
        <div class="flex flex-col space-y-2">
          <p class="text-z-gray-800">
            Convierte tus cosas en dinero. Publica y ofrece a la comunidad
          </p>
          <a
            href="/productos/crear"
            class="inline-flex h-8 w-fit items-center rounded bg-z-turquoise-600 px-6 text-sm text-white"
          >
            Sube tus productos
          </a>
        </div>
        <button
          class="h-6 w-6 shrink-0"
          aria-label="Cerrar"
          @click="closePopover"
        >
          <img
            src="@/assets/icons/close.svg"
            alt="Ícono de cerrar"
          >
        </button>
      </div>
    </div>
  </the-layout>
</template>
