<script setup lang="ts">
import { computed, ref } from 'vue';
import { groupBy } from 'lodash';
import ProfileProductCard from './profile-product-card.vue';

interface Props {
  products: Product[]
}
const props = defineProps<Props>();

function handleBack() {
  window.history.back();
}

const productsGroupedByStatus = computed(() => groupBy(props.products, 'status'));

const activeTab = ref('active');

function setActiveTab(string: 'active' | 'pending') {
  activeTab.value = string;
}
</script>
<template>
  <the-navbar class="hidden md:block" />
  <div class="bg-white md:mx-auto md:my-6 md:h-[calc(100vh-72px-24px-24px)] md:max-w-md md:overflow-y-scroll md:rounded">
    <the-titlebar
      title="Mis productos"
      @back="handleBack"
    />
    <div
      class="grid grid-cols-2"
    >
      <button
        class="h-12 border-b text-sm"
        :class="activeTab === 'active' ? ' border-z-turquoise-600' : 'border-z-gray-200'"
        @click="setActiveTab('active')"
      >
        Activos
      </button>
      <button
        class="h-12 border-b text-sm"
        :class="activeTab === 'pending' ? ' border-z-turquoise-600' : 'border-z-gray-200'"
        @click="setActiveTab('pending')"
      >
        En revisión
      </button>
    </div>
    <div
      class="flex flex-col"
    >
      <div
        v-if="productsGroupedByStatus[activeTab]"
        class="flex min-h-full flex-col divide-y divide-z-gray-100 pb-32"
      >
        <profile-product-card
          v-for="product in productsGroupedByStatus[activeTab]"
          :key="product.id"
          :product="product"
        />
      </div>
      <div
        v-else
        class="mt-4 flex w-full flex-col items-center gap-4 px-6 py-4"
      >
        <img
          src="@/assets/icons/task-search.svg"
          class="h-16 w-16"
        >
        <p>No hay productos aquí</p>
      </div>
    </div>
    <div class="fixed bottom-0 flex w-full flex-col space-y-2 bg-white px-6 py-3 md:bottom-4 md:max-w-md">
      <base-button
        class="space-x-2"
        href="/productos/crear"
      >
        <img
          src="@/assets/icons/plus.svg"
          class="h-6 w-6"
        >
        <p>Subir producto</p>
      </base-button>
    </div>
    <!-- <base-button
      variant="secondary"
      class="space-x-2"
    >
      <img src="@/assets/icons/share-rectangle.svg">
      <p>Compartir mi tienda</p>
    </base-button> -->
  </div>
</template>
