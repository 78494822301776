<script setup lang="ts">
import { useField } from 'vee-validate';
import { computed } from 'vue';

interface Props {
  min?: number;
  max?: number;
  label: string;
  hideLabel?: boolean;
  modelValue?: number
  unit?: string;
  disabled?: boolean;
  name: string;
}

const props = withDefaults(defineProps<Props>(), {
  min: undefined,
  max: undefined,
  modelValue: undefined,
  unit: '',
});
const emit = defineEmits<{(e: 'update:modelValue', value: number): void}>();

const {
  value: inputValue,
  handleBlur,
  handleChange,
} = useField(props.name, undefined, {
  initialValue: props.modelValue,
});

function subtract() {
  if ((props.min && inputValue.value > props.min) || props.min === undefined) {
    handleChange(inputValue.value - 1);
    emit('update:modelValue', inputValue.value - 1);
  }
}
function add() {
  if ((props.max && inputValue.value < props.max) || props.max === undefined) {
    handleChange(inputValue.value + 1);
    emit('update:modelValue', inputValue.value);
  }
}

const isMinDisabled = computed(() => props.disabled || inputValue.value === props.min);
const isMaxDisabled = computed(() => props.disabled || inputValue.value === props.max);
</script>
<template>
  <div>
    <p
      v-if="!hideLabel"
      class="text-z-gray-900"
    >
      {{ label }}
    </p>
    <div
      class="mt-1 flex h-12 items-center justify-between rounded border border-z-gray-100 px-2"
      :class="{ 'bg-z-gray-100': disabled }"
    >
      <div class="flex w-1/3">
        <button
          class="h-8 w-8 rounded text-white"
          type="button"
          :disabled="isMinDisabled"
          :class=" isMinDisabled ? 'bg-z-gray-500': 'bg-z-turquoise-600'"
          @click="subtract"
          @blur="handleBlur"
        >
          <img
            src="@/assets/icons/minus.svg"
            class="mx-auto h-6 w-6"
          >
        </button>
      </div>
      <p :class="{ 'text-z-gray-500': disabled }">
        {{ inputValue }}
      </p>
      <div class="flex w-1/3 items-center justify-end gap-2">
        <p
          v-if="unit"
          class="text-z-gray-500"
        >
          {{ unit }}
        </p>
        <button
          class="h-8 w-8 rounded text-white"
          type="button"
          :disabled="isMaxDisabled"
          :class=" isMaxDisabled ? 'bg-z-gray-500': 'bg-z-turquoise-600'"
          @click="add"
          @blur="handleBlur"
        >
          <img
            src="@/assets/icons/plus.svg"
            class="mx-auto"
          >
        </button>
      </div>
    </div>
  </div>
</template>
