<script setup lang="ts">
import { camelCase } from 'lodash';
import { ref } from 'vue';
import ProfileNewBankAccountForm from './profile-new-bank-account-form.vue';

interface Props {
  bankAccount?: BankAccount;
}
withDefaults(defineProps<Props>(), {
  bankAccount: undefined,
});

function handleBack() {
  window.history.back();
}

const showBankAccountForm = ref(false);
function toggleBankAccountForm() {
  showBankAccountForm.value = !showBankAccountForm.value;
}

const showSuccessModal = ref(false);
function toggleSuccessModal() {
  showSuccessModal.value = !showSuccessModal.value;
}

const redirect = new URLSearchParams(window.location.search).get('redirect');

function handleSuccess() {
  if (redirect === 'true') {
    window.location.href = '/perfil/solicitudes_reservas/socio';
  } else {
    window.location.reload();
  }
}

function maskNumber(string:string) {
  const length = string.length;

  // eslint-disable-next-line no-magic-numbers
  return `${'*'.repeat(length - 3)}${string.substring(string.length - 3)}`;
}
</script>
<template>
  <the-layout
    :active-menu="3"
    hide-navbar
  >
    <div class="relative bg-white md:mx-auto md:my-6 md:h-[calc(100vh-72px-24px-24px)] md:max-w-md md:overflow-y-scroll md:rounded">
      <the-titlebar
        title="Mis datos bancarios"
        @back="handleBack"
      />
      <div
        v-if="!!bankAccount && !showBankAccountForm"
        class="m-6 flex flex-col"
      >
        <div class="flex flex-col space-y-6 rounded-lg bg-z-gray-25 p-4">
          <div class="flex items-center justify-between">
            <p class="rounded bg-z-gray-100 p-2 text-xs">
              {{ bankAccount.bankEntity.name }}
            </p>
            <p class="text-xs">
              {{ maskNumber(bankAccount.number) }}
            </p>
          </div>
          <p class="text-xs">
            {{ bankAccount.name || $t(`bankAccount.kind.${camelCase(bankAccount.kind)}`) }}
          </p>
        </div>
        <button
          class="mt-2 w-fit rounded bg-z-turquoise-600 px-6 py-1.5 text-sm text-white"
          @click="toggleBankAccountForm"
        >
          Cambiar cuenta
        </button>
        <base-tip
          class="mt-2"
          title="¿Deseas eliminar cuenta?"
          variant="bold"
        >
          <template #icon>
            <img src="@/assets/icons/warning.svg">
          </template>
          <p>
            No puedes eliminar una cuenta pero si la puedes cambiar,
            en caso tengas una nueva u otra en donde quieras que tus depósitos lleguen.
          </p>
        </base-tip>
      </div>
      <div
        v-else-if="!showBankAccountForm"
        class="m-6 flex flex-col items-center rounded-lg bg-z-gray-25 p-4"
      >
        <img src="@/assets/icons/card.svg">
        <p class="mt-2 text-z-gray-900">
          No hay una cuenta vinculada
        </p>
        <base-button
          class="mt-6 w-full space-x-2"
          @click="toggleBankAccountForm"
        >
          <p>Vincular banco</p>
          <img src="@/assets/icons/arrow-small-right.svg">
        </base-button>
      </div>
      <profile-new-bank-account-form
        v-if="showBankAccountForm"
        :bank-account="bankAccount"
        class="w-full"
        @success="toggleSuccessModal"
      />
      <base-modal
        :open="showSuccessModal"
      >
        <div class="flex flex-col">
          <img
            src="@/assets/icons/check-circle.svg"
            class="w-20 self-center"
          >
          <p class="mt-4 text-center font-medium text-z-gray-900">
            Excelente!
          </p>
          <p class="mt-6 text-center text-z-gray-800">
            Hemos registrado tu cuenta de forma exitosa
          </p>
          <base-button
            class="mt-4"
            @click="handleSuccess"
          >
            Aceptar
          </base-button>
        </div>
      </base-modal>
    </div>
  </the-layout>
</template>
