function currency(value: number) {
  return new Intl.NumberFormat('es-CL', {
    style: 'currency',
    currency: 'CLP',
    currencyDisplay: 'symbol',
    useGrouping: true,
  }).format(value);
}

export { currency };
