<script setup lang="ts">
import { partnerAccountsApi } from '@/api/partner-accounts';
import { userRatingsApi } from '@/api/user-ratings';
import { onMounted, ref } from 'vue';
import { currency } from '@/utils/currency';
import { formatDate } from '@/utils/format-date';

interface Props {
  partnerAccount: PartnerAccount
}

const props = defineProps<Props>();

const ratings = ref<UserRating[]>();

const products = ref<Product[]>();

onMounted(() => {
  userRatingsApi.index(props.partnerAccount.userId)
    .then(response => {
      ratings.value = response;
    });
  partnerAccountsApi.products(props.partnerAccount.id)
    .then(response => {
      products.value = response;
    });
});

function handleBack() {
  window.history.back();
}
</script>
<template>
  <the-layout hide-navbar>
    <div class="absolute top-0 flex h-full w-full flex-col bg-white md:relative md:mx-auto md:my-6 md:h-[calc(100vh-72px-24px-64px)] md:max-w-md md:overflow-y-scroll md:rounded">
      <the-titlebar :title="`Tienda de ${partnerAccount.firstName}`" @back="handleBack" />
      <div class="mt-4 flex w-full flex-col items-center space-y-4 border-b border-z-gray-200 pb-6">
        <img
          v-if="partnerAccount.pictureUrl"
          :src="partnerAccount.pictureUrl"
          class="h-24 w-24 self-center rounded-full object-cover"
        >
        <img
          v-else
          src="@/assets/icons/default-profile.svg"
          class="h-24 w-24 rounded-full object-cover"
        >
        <div class="flex flex-col items-center">
          <p class="text-lg font-bold text-z-gray-900">
            {{ partnerAccount.firstName }} {{ partnerAccount.lastName }}
          </p>
          <p class="text-sm text-z-gray-600">
            Socio desde {{ formatDate(partnerAccount.createdAt) }}
          </p>
        </div>
        <div class="space-y-2">
          <p class="text-center text-z-gray-900">
            Calificaciones ({{ ratings?.length ?? 0 }})
          </p>
          <base-stars :value="partnerAccount.ratingAverage" />
        </div>
      </div>
      <div class="mt-4 flex w-full flex-col items-center space-y-4 px-6 pb-20">
        <p class="w-full text-left text-lg font-medium text-z-gray-900">
          Productos de {{ partnerAccount.firstName }}
        </p>
        <a
          v-for="product in products"
          :key="product.id"
          class="flex w-full rounded border border-z-gray-200 px-6 py-4"
          :href="`/productos/${product.id}`"
        >
          <img
            :src="product.productPictures[0].picture.webpSm.url"
            class="size-24 rounded-sm object-cover"
            :alt="`Imagen del producto ${product.name}`"
          >
          <div class="ml-3 flex flex-col">
            <p class="line-clamp-1 font-medium">
              {{ product.name }}
            </p>
            <p class="mt-1 text-sm">
              desde <span
                class="font-bold"
                :class="`text-z-turquoise-600`"
              >{{ currency(product.dailyPrice) }}/</span>día
            </p>
          </div>
        </a>
      </div>
    </div>
  </the-layout>
</template>
