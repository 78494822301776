import { api } from './index';

function index() {
  const path = '/api/internal/categories';

  return api({
    method: 'get',
    url: path,
  }).then(response => response.data.categories as Category[]);
}
export { index };
