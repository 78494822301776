<script setup lang="ts">
import { ref } from 'vue';
import supportChatCard from './support-chat-card.vue';

interface Props {
  activeSupportChats: SupportChat[] | null
  resolvedSupportChats: SupportChat[] | null
}

defineProps<Props>();

const activeTab = ref(0);

function setActiveTab(index: number) {
  activeTab.value = index;
}

function handleBack() {
  window.history.back();
}
</script>
<template>
  <the-layout
    :active-menu="3"
    hide-navbar
  >
    <div class="relative bg-white md:mx-auto md:my-6 md:h-[calc(100vh-72px-24px-24px)] md:max-w-md md:overflow-y-scroll md:rounded">
      <the-titlebar
        title="Chats de soporte"
        @back="handleBack"
      />
      <div
        class="grid grid-cols-2"
      >
        <button
          class="h-12 border-b text-sm"
          :class="activeTab === 0 ? ' border-z-turquoise-600' : 'border-z-gray-200'"
          @click="setActiveTab(0)"
        >
          Activos
        </button>
        <button
          class="h-12 border-b text-sm"
          :class="activeTab === 1 ? ' border-z-turquoise-600' : 'border-z-gray-200'"
          @click="setActiveTab(1)"
        >
          Cerrados
        </button>
      </div>
      <div class="flex w-full flex-col divide-y divide-z-gray-100">
        <template v-if="activeTab === 0">
          <support-chat-card
            v-for="supportChat in activeSupportChats"
            :key="supportChat.id"
            :support-chat="supportChat"
          />
        </template>
        <template v-else>
          <support-chat-card
            v-for="supportChat in resolvedSupportChats"
            :key="supportChat.id"
            :support-chat="supportChat"
          />
        </template>
      </div>
    </div>
  </the-layout>
</template>
