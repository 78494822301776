<script setup lang="ts">
import { productBookingsApi } from '@/api/product-bookings';
import { productBookingReturnsApi } from '@/api/product-booking-returns';
import locationRedIcon from '@/assets/icons/location-red.svg';
import defaultProfileIcon from '@/assets/icons/default-profile.svg';
import { useActionCable } from '@/hooks/actioncable';
import { convertKeys } from '@/utils/case-converter';
import { geodistance } from '@/utils/geodistance';

import { computed, inject, onMounted, ref, watch } from 'vue';
import continueReturnModal from './continue-return-modal.vue';

interface UserLocationUpdate {
  location: google.maps.LatLngLiteral
  userType: 'client' | 'partner'
}

interface UserLocationChannelValue {
  type: 'location_update' | 'omit_geolocation'
  data: UserLocationUpdate | boolean
}

interface Props {
  productBooking: ProductBooking
}

const props = defineProps<Props>();
const user = inject<User>('user');

defineEmits<{
  (e: 'close'): void
}>();

const map = ref(null);
const mapOptions = {
  styles: [
    {
      'featureType': 'administrative.land_parcel',
      'elementType': 'labels',
      'stylers': [
        {
          'visibility': 'off',
        },
      ],
    },
    {
      'featureType': 'poi',
      'elementType': 'labels.text',
      'stylers': [
        {
          'visibility': 'off',
        },
      ],
    },
    {
      'featureType': 'poi.attraction',
      'elementType': 'labels.icon',
      'stylers': [
        {
          'visibility': 'off',
        },
      ],
    },
    {
      'featureType': 'poi.business',
      'stylers': [
        {
          'visibility': 'off',
        },
      ],
    },
    {
      'featureType': 'poi.medical',
      'stylers': [
        {
          'visibility': 'off',
        },
      ],
    },
    {
      'featureType': 'poi.park',
      'elementType': 'labels.icon',
      'stylers': [
        {
          'visibility': 'off',
        },
      ],
    },
    {
      'featureType': 'poi.place_of_worship',
      'elementType': 'labels.icon',
      'stylers': [
        {
          'visibility': 'off',
        },
      ],
    },
    {
      'featureType': 'poi.school',
      'stylers': [
        {
          'visibility': 'off',
        },
      ],
    },
    {
      'featureType': 'poi.sports_complex',
      'elementType': 'labels.icon',
      'stylers': [
        {
          'visibility': 'off',
        },
      ],
    },
    {
      'featureType': 'road',
      'elementType': 'labels.icon',
      'stylers': [
        {
          'visibility': 'on',
        },
      ],
    },
    {
      'featureType': 'road.local',
      'elementType': 'labels',
      'stylers': [
        {
          'visibility': 'on',
        },
      ],
    },
    {
      'featureType': 'transit',
      'stylers': [
        {
          'visibility': 'off',
        },
      ],
    },
  ],
  disableDefaultUI: true,
  clickableIcons: false,

};

const userType = computed(() =>
  (props.productBooking.partnerAccount.id === user?.partnerAccountId ? 'partner' : 'client'),
);

const userPicture = computed(() => user?.picture.webpSm.url ?? defaultProfileIcon);

const otherUserType = computed(() => (userType.value === 'partner' ? 'client' : 'partner'));

const otherUserPicture = computed(() => {
  if (otherUserType.value === 'partner') {
    return props.productBooking.partnerAccount.pictureUrl ?? defaultProfileIcon;
  }

  return props.productBooking.clientAccount.pictureUrl ?? defaultProfileIcon;
});

const userLocation = ref<google.maps.LatLngLiteral>();
const otherUserLocation = ref<google.maps.LatLngLiteral>();
const addressLocation = ref({
  lat: props.productBooking.address?.latitude,
  lng: props.productBooking.address?.longitude,
});

const omitGeolocation = ref(props.productBooking.productBookingReturn?.omitGeolocation || false);

const distance = computed(() => {
  if (userLocation.value && otherUserLocation.value) {
    return geodistance(
      userLocation.value?.lat,
      userLocation.value?.lng,
      otherUserLocation.value?.lat,
      otherUserLocation.value?.lng,
    );
  }

  return null;
});

function updateBounds() {
  const bounds = new google.maps.LatLngBounds();
  if (otherUserLocation.value) bounds.extend(otherUserLocation.value);
  if (userLocation.value) bounds.extend(userLocation.value);
  if (addressLocation.value && (!otherUserLocation.value || !userLocation.value)) {
    bounds.extend(addressLocation.value as google.maps.LatLngLiteral);
  }
  map.value?.fitBounds(bounds);
}

const loading = ref(false);
const locationError = ref(0);

function updateLocation(position:GeolocationPosition) {
  loading.value = false;
  locationError.value = 0;
  userLocation.value = { lat: position.coords.latitude, lng: position.coords.longitude };
  let data = {};

  if (userType.value === 'client') {
    data = { clientLocation: userLocation.value };
  } else {
    data = { partnerLocation: userLocation.value };
  }
  if (!omitGeolocation.value) {
    productBookingsApi.update(props.productBooking.id, data);
  }
}

function handleLocationError(error:GeolocationPositionError) {
  locationError.value = error.code;
  loading.value = false;
}

function getUserLocation() {
  loading.value = true;
  navigator.geolocation.watchPosition(updateLocation, handleLocationError);
}

function getOtherUserLocation() {
  productBookingsApi.location(props.productBooking.id, otherUserType.value)
    .then((response) => {
      otherUserLocation.value = response;
    });
}

useActionCable(
  { channel: 'UserLocationChannel', id: props.productBooking.id },
  {
    received(eventNewResource:Record<string, unknown>) {
      const newUserLocationChannelValue =
        convertKeys(eventNewResource, 'camelize') as unknown as UserLocationChannelValue;
      if (newUserLocationChannelValue.type === 'location_update') {
        const newLocationUpdate = newUserLocationChannelValue.data as UserLocationUpdate;

        if (newLocationUpdate.userType === otherUserType.value) {
          otherUserLocation.value = newLocationUpdate.location;
        }
      } else {
        omitGeolocation.value = newUserLocationChannelValue.data as boolean;
      }
    },
  },
);

onMounted(() => {
  getUserLocation();
  getOtherUserLocation();
});

watch(userLocation, () => updateBounds());
watch(otherUserLocation, () => updateBounds());

function handleBack() {
  window.history.back();
}

const showContinueReturnModal = ref(false);
function toggleContinueReturnModal() {
  showContinueReturnModal.value = !showContinueReturnModal.value;
}

function handleOmit() {
  productBookingReturnsApi.update(props.productBooking.productBookingReturn?.id ?? 0, { omitGeolocation: true })
    .then(() => {
      omitGeolocation.value = true;
    })
  ;
}

// eslint-disable-next-line no-magic-numbers
const distanceIsValid = computed(() => distance.value && distance.value <= 0.1);
const canContinueReturn = computed(() => distanceIsValid.value || omitGeolocation.value);
</script>
<template>
  <the-titlebar
    title="Mapa"
    @back="handleBack"
  />
  <div class="relative h-[calc(100vh-64px)] w-full overflow-y-hidden">
    <GMapMap
      ref="map"
      :center="addressLocation"
      :zoom="14"
      :options="mapOptions"
      map-type-id="roadmap"
      class="h-[calc(100%+24px)] w-full"
    >
      <GMapMarker
        v-if="userLocation"
        :position="userLocation"
        :clickable="true"
        :icon="{ url: userPicture, scaledSize: { width: 32, height: 32 } }"
      />
      <GMapMarker
        v-if="otherUserLocation"
        :position="otherUserLocation"
        :clickable="true"
        :icon="{ url: otherUserPicture, scaledSize: { width: 32, height: 32 } }"
      />
      <GMapMarker
        :position="addressLocation"
        :clickable="true"
        :icon="{ url: locationRedIcon, scaledSize: { width: 32, height: 32 } }"
      />
    </GMapMap>
    <div
      class="absolute bottom-0 w-full flex-col space-y-3 bg-white px-6 py-4"
    >
      <p
        v-if="userType === 'client' && omitGeolocation"
        class="text-center text-sm text-z-turquoise-700"
      >
        El socio omitió la georreferencia
      </p>
      <p
        v-else-if="userType === 'client' && !distanceIsValid && !loading"
        class="text-center text-xs text-z-cerise-600"
      >
        Estás muy lejos para continuar la devolución
      </p>
      <base-button
        v-if="userType === 'client'"
        class="w-full"
        size="sm"
        :disabled="!canContinueReturn"
        @click="toggleContinueReturnModal"
      >
        Continuar con la devolución
      </base-button>
      <div v-else>
        <p
          class="text-center text-sm text-z-gray-800"
        >
          Esperando a que el cliente continúe la devolución...
        </p>
        <base-button
          v-if="!omitGeolocation"
          variant="secondary"
          size="sm"
          class="mt-3 w-full"
          @click="handleOmit"
        >
          Omitir georreferenciación
        </base-button>
      </div>
      <base-button
        class="w-full"
        size="sm"
        variant="secondary"
        :href="`/chats/${productBooking.chatId}`"
      >
        Ir al chat
      </base-button>
    </div>
    <div class="absolute top-0 flex w-full flex-col px-6 py-4">
      <div
        class="flex w-full items-center space-x-2 rounded bg-white p-2 shadow"
      >
        <template v-if="loading">
          <img
            src="@/assets/icons/loading.svg"
            class="w-5 animate-spin-slow"
          >
          <p class="text-xs text-z-gray-800">
            Obteniendo ubicación...
          </p>
        </template>
        <template v-else-if="locationError">
          <img
            src="@/assets/icons/warning.svg"
            class="w-5"
          >
          <p class="text-xs text-z-gray-800">
            Tenemos problemas para acceder a tu ubicación
          </p>
          <button
            class="text-xs font-medium text-z-gray-800 underline underline-offset-2"
            @click="getUserLocation"
          >
            Reintentar
          </button>
        </template>
        <template v-else>
          <img
            src="@/assets/icons/location.svg"
            class="w-5"
          >
          <p class="text-xs text-z-gray-800">
            Ubicación compartida con el {{ $t(`accounts.${otherUserType}`) }}
          </p>
        </template>
      </div>
    </div>
  </div>
  <continue-return-modal
    v-if="showContinueReturnModal && productBooking.productBookingReturn"
    :open="showContinueReturnModal"
    :product-booking-return-id="productBooking.productBookingReturn?.id"
    :chat-id="productBooking.chatId"
  />
</template>
