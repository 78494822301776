<script setup lang="ts">
import { computed, ref, watch } from 'vue';
import { productBookingReportsApi } from '@/api/product-booking-reports';
import successModal from '../success-modal.vue';

interface Props {
  productBooking: ProductBooking
  reportedBy: 'client' | 'partner'
  reportStatus: 'paid' | 'delivered'
}

const props = defineProps<Props>();

function handleBack() {
  window.history.back();
}

const ALL_REASON_OPTIONS = {
  client: {
    paid: [
      { name: 'Mal estado del producto', pictureForm: true },
      { name: 'No es el producto que solicité', pictureForm: true },
    ],
    delivered: [],
  },
  partner: {
    paid: [
      { name: 'Cliente no llegó', pictureForm: false },
    ],
    delivered: [
      { name: 'Cliente no está en el lugar', pictureForm: false },
      { name: 'Cliente llegó pero no devolvió el artículo', pictureForm: false },
    ],
  },
};

const reasonOptions = computed(() =>
  ALL_REASON_OPTIONS[props.reportedBy][props.reportStatus].map((reason) => ({ id: reason.name, ...reason })),
);

const selectedReason = ref();
const comment = ref();

const showPictureForm = computed(() => reasonOptions.value.find(
  (reason) => reason.id === selectedReason.value)?.pictureForm,
);

const showSuccessModal = ref(false);
function toggleSuccessModal() {
  showSuccessModal.value = !showSuccessModal.value;
}

const pictures = ref<File[]>([]);

const nonNullPictures = computed(() => pictures.value.filter(el => el !== null && el !== undefined));

const loading = ref(false);

function createReport() {
  const formValues = {
    productBookingId: props.productBooking.id,
    reportedBy: props.reportedBy,
    reason: selectedReason.value,
    comment: comment.value,
    reportType: 'normal',
  } as Partial<ProductBookingReport>;

  loading.value = true;
  productBookingReportsApi.create(formValues, nonNullPictures.value)
    .then(() => {
      loading.value = false;
      toggleSuccessModal();
    });
}

function handleSuccess() {
  const account = props.reportedBy === 'client' ? 'cliente' : 'socio';
  const bookingTab = { paid: 'reservados', delivered: 'entregados', complete: 'completados' };
  window.location.href = `/perfil/solicitudes_reservas/${account}#${bookingTab[props.reportStatus]}`;
}

watch(showPictureForm, (newVal) => {
  if (!newVal) {
    pictures.value = [];
  }
});
</script>
<template>
  <div
    class="md:mx-auto md:my-6 md:max-w-md md:rounded md:bg-white"
  >
    <the-titlebar
      title="Reportar"
      class="md:rounded"
      @back="handleBack"
    />
    <div class="space-y-4 px-6 py-4">
      <p class="font-semibold text-z-gray-900">
        {{ productBooking.product.name }}
      </p>
      <base-select
        v-model="selectedReason"
        name="reason"
        label="Motivo del reporte"
        hide-label
        :options="reasonOptions"
        placeholder="Motivo del reporte"
        required
        overflow="wrap"
      />
      <base-input
        v-model="comment"
        name="comment"
        type="textarea"
        label="Comentario adicional"
        placeholder="Comentario adicional"
      />
    </div>
    <div
      v-if="showPictureForm"
      class="mb-24 px-6"
    >
      <p class="font-medium text-z-gray-900">
        Fotos del producto
      </p>
      <p class="text-sm text-z-gray-800">
        Toma como mínimo 3 fotos para poder analizar de forma correcta el daño
      </p>
      <base-drag-and-drop
        v-for="i in [0,1,2]"
        :key="i"
        v-model="pictures[i]"
        label="Foto de referencia"
        hide-label
        name="picture"
        max="1"
        wide
        class="mt-4"
      />
    </div>
    <div class="fixed bottom-0 w-full border-t bg-white px-6 py-4">
      <base-button
        class="w-full"
        :disabled="!selectedReason || (showPictureForm && nonNullPictures.length !== 3) || loading"
        @click="createReport"
      >
        <img
          v-if="loading"
          src="@/assets/icons/loading-alt.svg"
          class="w-10 animate-spin-slow"
        >
        <p v-else>
          Enviar
        </p>
      </base-button>
    </div>
  </div>
  <success-modal
    :open="showSuccessModal"
    title="Excelente!"
    description="Se ha reportado esta situación al equipo Zirkular y se ha notificado al usuario."
    @confirm="handleSuccess"
  />
</template>
