<script setup lang="ts">
import { ref } from 'vue';
import chatCard from './chat-card.vue';

interface Props {
  chatsWithClients: Chat[] | null
  chatsWithPartners: Chat[] | null
}

defineProps<Props>();

const activeTab = ref(0);

function setActiveTab(index: number) {
  activeTab.value = index;
}
</script>
<template>
  <the-layout
    :active-menu="2"
    hide-navbar
  >
    <div class="relative bg-white md:mx-auto md:my-6 md:h-[calc(100vh-72px-24px-24px)] md:max-w-md md:overflow-y-scroll md:rounded">
      <the-titlebar
        title="Mis chats"
        hide-back
      />
      <div
        class="grid grid-cols-2"
      >
        <button
          class="h-12 border-b text-sm"
          :class="activeTab === 0 ? ' border-z-turquoise-600' : 'border-z-gray-200'"
          @click="setActiveTab(0)"
        >
          con Clientes
        </button>
        <button
          class="h-12 border-b text-sm"
          :class="activeTab === 1 ? ' border-z-turquoise-600' : 'border-z-gray-200'"
          @click="setActiveTab(1)"
        >
          con Socios
        </button>
      </div>
      <div
        v-if="activeTab === 0"
        class="flex w-full flex-col divide-y divide-z-gray-100"
      >
        <chat-card
          v-for="chat in chatsWithClients"
          :key="chat.id"
          :chat="chat"
          type="partner"
        />
      </div>
      <div
        v-if="activeTab === 1"
        class="flex w-full flex-col divide-y divide-z-gray-100"
      >
        <chat-card
          v-for="chat in chatsWithPartners"
          :key="chat.id"
          :chat="chat"
          type="client"
        />
      </div>
    </div>
  </the-layout>
</template>
