<script setup lang="ts">
import { formatDate } from '@/utils/format-date';
import { formatDistanceToNowStrict,

} from 'date-fns';
import { computed } from 'vue';

interface Props {
  supportChat: SupportChat
}

const props = defineProps<Props>();

const lastMessage = computed(() => props.supportChat.supportChatMessages.at(-1));
</script>
<template>
  <a
    class="flex w-full space-x-6 bg-white px-6 py-4"
    :href="`/soporte/${supportChat.id}`"
  >
    <img
      :src="supportChat.productBooking.productPictures[0].picture.webpSm.url"
      class="h-12 w-12 shrink-0 object-cover"
    >
    <div class="flex w-full flex-col">
      <div class="flex justify-between">
        <p class="font-medium text-z-gray-800">
          {{ supportChat.productBooking.product.name }}
          ({{ formatDate(supportChat.productBooking.startDate) }}
          - {{ formatDate(supportChat.productBooking.endDate) }})
        </p>
        <p
          v-if="lastMessage"
          class="shrink-0 font-light text-z-gray-500"
        >
          {{ formatDistanceToNowStrict(new Date(lastMessage?.createdAt)) }}
        </p>
      </div>
      <p
        v-if="lastMessage"
        class="text-sm font-light text-z-gray-500"
      >
        {{ lastMessage?.content }}
      </p>
    </div>
  </a>
</template>
