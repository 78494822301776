<script setup lang="ts">
import { useStore } from '@/store/store';
import { ref, computed } from 'vue';
import { string, object, array } from 'yup';
import NewProductPictureInfoModal from './new-product-picture-info-modal.vue';

interface Props {
  summaryMode?: boolean
}

defineProps<Props>();

const emit = defineEmits<{
  (e: 'continue', value: { newDescription:string, newPictures:File[]}): void
}>();

const store = useStore();

const description = ref(store.productFormData?.description ?? '');
const pictures = ref(store.productFormData?.pictures ?? [null, null, null]);
const showPictureModal = ref(false);

const showPictureErrors = ref(false);
function togglePictureErrors() {
  showPictureErrors.value = !showPictureErrors.value;
}

const formRules = object().shape({
  description: string().required('Debes ingresar una descripción'),
  pictures: array().required().compact()
  // eslint-disable-next-line no-magic-numbers
    .min(3),
});

function handleContinue() {
  emit('continue', { newDescription: description.value, newPictures: pictures.value as File[] });
}

const nonNullPictures = computed(() => pictures.value.filter(el => el !== null && el !== undefined));

function togglePictureModal() {
  showPictureModal.value = !showPictureModal.value;
}

</script>
<template>
  <p class="text-center text-lg font-medium leading-6 text-z-gray-800">
    {{ $t('newProductForm.step4.description') }}
  </p>
  <v-form
    v-slot="{ meta, handleSubmit, errors }"
    :validation-schema="formRules"
  >
    <div class="mb-16 flex flex-col space-y-6 pb-4 md:mb-0">
      <div
        v-if="meta.touched && (!!errors.description || (showPictureErrors && !!errors.pictures))"
        class="flex h-12 items-center justify-center rounded-lg bg-z-cerise-50 px-4 py-2 text-center text-sm text-z-red"
      >
        Ingresa los datos para continuar
      </div>
      <div class="flex flex-col space-y-2">
        <base-input
          v-model="description"
          name="description"
          placeholder="Describe tu producto"
          label="Describe tu producto"
          type="textarea"
          :error="meta.touched && !!errors.description"
        />
      </div>
      <div>
        <p class="font-medium text-z-gray-900">
          Fotografías del producto
        </p>
        <p class="mt-2 text-sm text-z-gray-800">
          Como mínimo sube 3 imágenes en formato .jpg o .png
        </p>
        <base-tip
          title="¿Cómo subir mis fotos?"
          class="mt-4"
        >
          <p>Te dejamos ciertos tips que te van a ayudar a subir fotos de forma correcta.</p>
          <button
            class="mt-2 text-z-yellow-500 underline underline-offset-2"
            type="button"
            @click="togglePictureModal"
          >
            Saber más
          </button>
        </base-tip>
      </div>
      <div class="mt-1 grid grid-cols-2 gap-4">
        <base-drag-and-drop
          v-for="i in Math.max(3, nonNullPictures.length + 1)"
          :key="i"
          v-model="pictures[i-1]"
          :name="`pictures[${i-1}]`"
          :error="i <= 3 && showPictureErrors && !!errors.pictures"
        />
      </div>
      <base-button
        class="fixed bottom-0 gap-2"
        type="button"
        @click="togglePictureErrors(); handleSubmit($event, handleContinue)"
      >
        {{ summaryMode ? 'Ver resumen' : 'Continuar' }}
        <img src="@/assets/icons/arrow-small-right.svg">
      </base-button>
    </div>
  </v-form>
  <new-product-picture-info-modal
    :is-open="showPictureModal"
    @close="togglePictureModal"
  />
</template>
