import { api } from './index';

export const addressesApi = {
  get(id: number) {
    const path = `/api/internal/addresses/${id}`;

    return api({
      method: 'get',
      url: path,
    });
  },
  index(userId: number) {
    const path = `/api/internal/users/${userId}/addresses`;

    return api({
      method: 'get',
      url: path,
    });
  },
  create(data: Partial<AddressFormData>) {
    const path = '/api/internal/addresses';

    return api({
      method: 'post',
      url: path,
      data,
    });
  },
  update(id: number, data: Partial<AddressFormData>) {
    const path = `/api/internal/addresses/${id}`;

    return api({
      method: 'patch',
      url: path,
      data,
    });
  },
  delete(id: number) {
    const path = `/api/internal/addresses/${id}`;

    return api({
      method: 'delete',
      url: path,
    });
  },
};
