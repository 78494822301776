<script setup lang="ts">
import { useMutation } from '@tanstack/vue-query';
import formatDistanceToNow from 'date-fns/formatDistanceToNow';
import { notificationsApi } from '@/api/notifications';
import defaultNotificationImagePath from '@/assets/icons/notification-gray.svg';
import { onMounted } from 'vue';

interface Props {
  notification: UserNotification
  mode?: 'menu' | 'floating'
}

const props = withDefaults(defineProps<Props>(), {
  mode: 'menu',
});

const emit = defineEmits<{
  (e: 'delete'): void,
  (e: 'read'): void,
  (e: 'hide'): void,
}>();

const { mutate: deleteNotification } = useMutation(() => notificationsApi.delete(props.notification.id), {
  onSuccess: () => {
    emit('delete');
  },
});

const { mutate: markAsRead } = useMutation(() => notificationsApi.update(props.notification.id, { read: true }), {
  onSuccess: () => {
    emit('read');
  },
});

function redirect() {
  if ((window.location.pathname.includes('solicitudes_reservas/socio') &&
  props.notification.onClickPath.includes('solicitudes_reservas/socio')) ||
  (window.location.pathname.includes('solicitudes_reservas/cliente') &&
    props.notification.onClickPath.includes('solicitudes_reservas/cliente'))) {
    window.location.replace(props.notification.onClickPath);
    window.location.reload();
  } else { window.location.assign(props.notification.onClickPath); }
}

function handleClick() {
  if (!props.notification.read) {
    markAsRead();
  }
  if (props.notification.onClickPath) {
    redirect();
  }
}

function handleClose() {
  if (props.mode === 'menu') {
    deleteNotification();
  } else {
    emit('hide');
  }
}

onMounted(() => {
  if (!props.notification.read && props.mode === 'menu') {
    markAsRead();
  }
});
</script>
<template>
  <div
    class="relative flex border-b border-z-gray-100 px-6 py-4"
    :class="{ 'bg-z-turquoise-50': !notification.read }"
  >
    <button
      class="flex h-full w-full items-start space-x-4"
      @click="handleClick()"
    >
      <img
        :src="notification.imagePath ?? defaultNotificationImagePath"
        class="h-12 w-12 shrink-0 rounded-sm object-cover"
      >
      <div class="flex flex-col items-start pr-4">
        <div class="flex items-center space-x-1">
          <div
            v-if="!notification.read"
            class="h-2 w-2 rounded-full bg-z-turquoise-600/40"
          />
          <p
            class="text-left text-xs font-medium text-z-gray-900"
          >
            {{ notification.title }}
          </p>
        </div>
        <p class="text-left text-xs text-z-gray-800">
          {{ notification.message }}
        </p>
        <p class="mt-4 text-xs text-z-gray-600">
          hace {{ formatDistanceToNow(new Date(notification.createdAt)) }}
        </p>
      </div>
    </button>
    <button
      class="absolute right-4 top-1.5 p-2"
      aria-label="Eliminar notificación"
      @click="handleClose()"
    >
      <img
        src="@/assets/icons/close.svg"
        class="h-4 w-4 opacity-70"
      >
    </button>
  </div>
</template>
