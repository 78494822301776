<script setup lang="ts">
import { inject, ref } from 'vue';
import { productBookingDeliveriesApi } from '@/api/product-booking-deliveries';
import { supportChatsApi } from '@/api/support-chats';
import profileProductBookingCard from './profile/profile-product-booking-card.vue';

interface Props {
  productBooking: ProductBooking
  open?: boolean
  skipAccept?: boolean
}

const props = defineProps<Props>();

defineEmits<{
  (e: 'success'): void
}>();

const user = inject<User>('user');

const picture = ref();
const loading = ref(false);

const showPictureUpload = ref(props.skipAccept);
function togglePictureUpload() {
  showPictureUpload.value = !showPictureUpload.value;
}

const showSuccess = ref(false);
function toggleSuccess() {
  showSuccess.value = !showSuccess.value;
}
const showNoPictureConfirmation = ref(false);
function toggleNoPictureConfirmation() {
  showNoPictureConfirmation.value = !showNoPictureConfirmation.value;
}

function handleDeliver() {
  loading.value = true;
  productBookingDeliveriesApi.update(
    props.productBooking.productBookingDelivery?.id ?? 0, { status: 'complete' }, picture.value)
    .then(() => {
      toggleSuccess();
      loading.value = false;
    });
}

function handleContinue() {
  if (picture.value) {
    handleDeliver();
  } else {
    toggleNoPictureConfirmation();
  }
}

function handleSupportChat() {
  if (props.productBooking.supportChatId) {
    window.location.href = `/soporte/${props.productBooking.supportChatId}`;
  } else {
    const formValues = {
      productBookingId: props.productBooking.id,
      userId: user?.id ?? 0,
    };

    supportChatsApi.create(formValues)
      .then((res) => {
        window.location.href = `/soporte/${res.data.supportChat.id}`;
      });
  }
}
</script>
<template>
  <base-modal
    :open="open"
  >
    <div
      v-if="showSuccess"
      class="flex w-full flex-col items-center"
    >
      <img
        src="@/assets/icons/check-circle.svg"
        class="w-20 self-center"
      >
      <p class="mt-4 text-center font-medium text-z-gray-900">
        Excelente!
      </p>
      <p class="mt-6 text-center text-z-gray-800">
        Se ha completado la entrega del producto {{ productBooking.product.name }}
      </p>
      <base-button
        class="mt-4 w-full"
        @click="$emit('success')"
      >
        Aceptar
      </base-button>
    </div>
    <div
      v-else-if="showNoPictureConfirmation"
      class="flex w-full flex-col items-center"
    >
      <img
        src="@/assets/icons/information-circle.svg"
        class="w-20 self-center"
      >
      <p class="mt-4 text-center text-z-gray-900">
        Zirkular te recomienda tomar una foto para respaldar el estado en que recibes el producto.
      </p>
      <base-button
        class="mt-3 w-full"
        @click="toggleNoPictureConfirmation"
      >
        Volver atrás
      </base-button>
      <base-button
        variant="secondary"
        class="mt-3 w-full"
        @click="handleDeliver"
      >
        Continuar sin foto
      </base-button>
    </div>
    <div
      v-else-if="showPictureUpload"
      class="flex w-full flex-col items-center"
    >
      <p class="w-full font-bold text-z-gray-900">
        Foto del producto
      </p>
      <p class="mt-2 text-sm text-z-gray-800">
        Toma una foto del producto para que tengas respaldo de su estado.
      </p>
      <base-drag-and-drop
        v-model="picture"
        name="picture"
        class="mt-4 w-full"
        wide
      />
      <base-button
        class="mt-4 w-full"
        :disabled="loading"
        @click="handleContinue"
      >
        <img
          v-if="loading"
          src="@/assets/icons/loading-alt.svg"
          class="w-10 animate-spin-slow"
        >
        <p v-else>
          Continuar
        </p>
      </base-button>
    </div>
    <div
      v-else
      class="flex w-full flex-col items-center"
    >
      <img
        src="@/assets/icons/information-circle.svg"
        class="w-24 "
      >
      <p class="mt-2 font-bold text-z-gray-900">
        Alerta de entrega
      </p>
      <p class="mt-2 text-center text-z-gray-800">
        El socio ha comenzado el proceso de entrega.
      </p>
      <profile-product-booking-card
        :product-booking="productBooking"
        account-type="client"
        hide-tags
      />
      <p class="text-center text-z-gray-800">
        Te recomendamos que revises el producto antes de aceptarlo.
      </p>
      <base-button
        class="mt-8 w-full"
        size="sm"
        @click="togglePictureUpload"
      >
        Aceptar la entrega
      </base-button>
      <base-button
        class="mt-3 w-full"
        variant="danger"
        size="sm"
        :href="`/perfil/solicitudes_reservas/${productBooking.id}/reportar`"
      >
        Reportar
      </base-button>
      <base-button
        class="mt-3 w-full"
        variant="danger"
        size="sm"
        @click="handleSupportChat"
      >
        Contactar a soporte
      </base-button>
    </div>
  </base-modal>
</template>
