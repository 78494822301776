import { api } from './index';

function index(categoryId:number) {
  const path = `/api/internal/categories/${categoryId}/subcategories`;

  return api({
    method: 'get',
    url: path,
  });
}
export { index };
