<script setup lang="ts">
import { differenceInCalendarDays, isToday } from 'date-fns';
import { computed } from 'vue';

interface Props {
  productBooking: ProductBooking
}

const props = defineProps<Props>();

const remainingTime = computed(() => differenceInCalendarDays(new Date(props.productBooking.endDate), new Date()));

const returnToday = computed(() => isToday(new Date(props.productBooking.endDate)));

</script>
<template>
  <div class="flex flex-col">
    <base-tip
      v-if="returnToday"
      title="Hoy es la devolución"
      variant="bold"
    >
      Coordina la devolución del producto con el cliente a través del
      <a
        :href="`/chats/${productBooking.chatId}`"
        class="font-medium underline underline-offset-2"
      >
        chat
      </a>.
    </base-tip>
    <base-tip
      v-else
      title="Devolución"
      variant="bold"
    >
      {{ remainingTime === 1 ? `Falta ${remainingTime} día` : `Faltan ${remainingTime} días` }} para la devolución
    </base-tip>
  </div>
</template>
