<script setup lang="ts">
import { computed, inject, ref } from 'vue';
import { Popover, PopoverButton, PopoverPanel } from '@headlessui/vue';
import userUrl from '@/assets/icons/user.svg';
import defaultProfileUrl from '@/assets/icons/default-profile.svg';
import { currency } from '@/utils/currency';
import storeIconUrl from '@/assets/icons/store.svg';
import menuUserIconUrl from '@/assets/icons/menu-user.svg';
import bookingsIconUrl from '@/assets/icons/bookings.svg';
import profileMenuItem from './profile-menu-item.vue';

interface Props {
  activeItem?: number;
}
const props = withDefaults(defineProps<Props>(), {
  activeItem: -1,
});

const user = inject<User>('user');

const MENUS = [
  {
    name: 'Mi tienda',
    icon: storeIconUrl,
    subitems: [
      { name: 'Solicitudes y reservas', path: '/perfil/solicitudes_reservas/socio' },
      { name: 'Mis productos', path: '/perfil/productos' },
      { name: 'Ver mi tienda', path: `/socio/${user?.partnerAccountId}` },
    ],
    partnerOnly: true,
  },
  {
    name: 'Mis arriendos',
    icon: bookingsIconUrl,
    subitems: [
      { name: 'Solicitudes y reservas', path: '/perfil/solicitudes_reservas/cliente' },
      { name: 'Mi historial', path: '' },
    ],
  },
  {
    name: 'Mis datos',
    icon: menuUserIconUrl,
    subitems: [
      { name: 'Mi cuenta', path: '/perfil/cuenta' },
      { name: 'Mis direcciones', path: '/perfil/direcciones' },
      { name: 'Mis datos bancarios', path: '/perfil/datos_bancarios', partnerOnly: true },
    ],
  },
];

const filteredMenus = computed(() => {
  let finalMenus = MENUS;
  if (!user?.partnerAccountId) {
    finalMenus = finalMenus.filter((menu) => !menu.partnerOnly);
  }

  return finalMenus;
});
const activeIndex = ref(props.activeItem);

function goToHome() {
  if (window.location.pathname !== '/') {
    window.location.href = '/';
  }
}

function goToPartnerStart() {
  const pathnames = ['/productos/crear', '/como_subir'];
  if (!pathnames.includes(window.location.pathname)) {
    window.location.href = '/como_subir';
  }
}

function goToChats() {
  if (window.location.pathname !== '/chats') {
    window.location.href = '/chats';
  }
}

function goToNewProductRequest() {
  if (window.location.pathname !== '/necesidades_clientes') {
    window.location.href = '/necesidades_clientes/crear';
  }
}

function goToProductRequests() {
  if (window.location.pathname !== '/necesidades_clientes') {
    window.location.href = '/necesidades_clientes';
  }
}

const MENU_ITEMS = [
  {
    name: 'Inicio',
    action: goToHome,
  },
  {
    name: 'Sube tus productos',
    action: goToPartnerStart,
  },
  {
    name: '¿No encuentras lo que buscas?',
    action: goToNewProductRequest,
  },
  {
    name: 'Necesidades de nuestros clientes',
    action: goToProductRequests,
  },
  {
    name: 'Chats',
    action: goToChats,
  },
];
</script>
<template>
  <div class="hidden shrink-0 items-center space-x-6 text-xs text-z-gray-900 md:flex">
    <button
      v-for="(item, index) in MENU_ITEMS"
      :key="index"
      class="flex items-center"
      @click="activeIndex = index; item.action()"
    >
      <p class="transition hover:text-z-turquoise-700">
        {{ item.name }}
      </p>
    </button>
    <Popover
      v-if="user"
      v-slot="{ open }"
      class="relative"
    >
      <PopoverButton

        class="flex items-center space-x-2 rounded-full border py-1 pl-3 pr-1 focus:outline-none"
        :class="{
          'border-white': !open,
          'border-z-gray-200 bg-z-gray-50': open
        }"
      >
        <p class="transition hover:text-z-turquoise-700">
          Mi perfil
        </p>
        <img
          :src="user.picture.webpSm.url || defaultProfileUrl"
          class="aspect-square w-6 rounded-full border border-z-gray-200 object-cover p-0.5"
        >
      </PopoverButton>
      <PopoverPanel class="absolute right-0 top-10 w-96 rounded border border-z-gray-100 bg-white text-sm shadow-md">
        <div class="flex flex-col divide-y divide-z-gray-100 border-b border-z-gray-100">
          <div
            v-if="user?.partnerAccountId"
            class="grid grid-cols-2 gap-2 px-6 py-4"
          >
            <div class="rounded-lg border border-z-gray-100 bg-gray-50 px-4 py-3 text-z-gray-800">
              <p class="text-xs">
                Total ganado
              </p>
              <p class="mt-1 text-lg font-semibold">
                {{ currency(0) }}
              </p>
            </div>
            <div class="rounded-lg border border-z-gray-100 bg-gray-50 px-4 py-3 text-z-gray-800">
              <p class="text-xs">
                Ganancia del mes
              </p>
              <p class="mt-1 text-lg font-semibold">
                {{ currency(0) }}
              </p>
            </div>
          </div>
          <profile-menu-item
            v-for="menuItem, index in filteredMenus"
            :key="index"
            :menu-item="menuItem"
          />
        </div>
        <div
          class="flex flex-col"
        >
          <a
            class="flex justify-between px-6 py-5"
            href="/soporte"
          >
            <div class="flex space-x-2">
              <img
                src="@/assets/icons/help-alt.svg"
                class="w-6"
              >
              <p class="text-z-gray-900">
                Chats de soporte
              </p>
            </div>
          </a>
        </div>
        <div
          class="flex flex-col"
        >
          <a
            class="flex justify-between px-6 py-5"
            href="/usuarios/cerrar_sesion"
          >
            <div class="flex space-x-2">
              <img
                src="@/assets/icons/logout.svg"
                class="w-6"
              >
              <p class="text-z-gray-900">
                Cerrar sesión
              </p>
            </div>
          </a>
        </div>
      </PopoverPanel>
    </Popover>
    <a
      v-else
      href="/usuarios/iniciar_sesion"
      class="flex items-center space-x-2 rounded-full border border-z-gray-200 bg-z-gray-25 py-1 pl-3 pr-1"
    >
      <p>Iniciar sesión</p>
      <img
        :src="userUrl"
        class="w-6 rounded-full border border-z-gray-200 bg-white"
        alt="Ícono de usuario genérico"
      >
    </a>
  </div>
</template>
