<script setup lang="ts">
import { computed, inject, onMounted, ref } from 'vue';
import { camelCase } from 'lodash';
import { addressesApi } from '@/api/addresses';

interface Props {
  open: boolean
  shippingMethods: string[]
}
defineProps<Props>();

const user = inject<User>('user');

const selectedMethod = ref();

const addressId = ref();

const emit = defineEmits<{
  (e: 'update:shippingMethod', value: string): void,
  (e: 'update:addressId', value: number): void,
}>();

const addresses = ref<AddressData[]>();
const addressOptions = computed(() => addresses.value?.map((address) => (
  { id: address.id, name: address.displayName, alias: address.alias }
)));

const showNewAddressForm = ref(false);
function toggleNewAddressForm() {
  showNewAddressForm.value = !showNewAddressForm.value;
}

function getAddresses() {
  addressesApi.index(user?.id ?? 0).then((response) => {
    addresses.value = response.data.addresses;
  });
}

onMounted(() => {
  getAddresses();
});

function handleNewAddress() {
  getAddresses();
  toggleNewAddressForm();
}

function handleConfirm() {
  emit('update:shippingMethod', selectedMethod.value);
  if (addressId.value) {
    emit('update:addressId', addressId.value);
  }
}

const place = ref<google.maps.places.PlaceResult>();
const errorEmpty = ref(false);
const error = ref(false);
const alias = ref('');

function createAddress() {
  if (place.value) {
    try {
      const addressData = {
        street: '',
        streetNumber: '',
        commune: '',
        region: '',
        latitude: 0.0,
        longitude: 0.0,
        alias: alias.value,
      };

      addressData.latitude = place.value.geometry?.location?.lat() ?? 0.0;
      addressData.longitude = place.value.geometry?.location?.lng() ?? 0.0;

      place.value.address_components?.forEach((addressComponent) => {
        switch (addressComponent.types[0]) {
        case 'street_number':
          addressData.streetNumber = addressComponent.long_name;
          break;
        case 'route':
          addressData.street = addressComponent.long_name;
          break;
        case 'locality':
          addressData.commune = addressComponent.long_name;
          break;
        case 'administrative_area_level_1':
          addressData.region = addressComponent.long_name;
          break;
        default:
          // Do nothing
        }
      });

      addressesApi.create({ ...addressData, userId: user?.id }).then(() => handleNewAddress());
    } catch (e) {
      error.value = true;
    }
  } else {
    errorEmpty.value = true;
  }
}
</script>
<template>
  <base-modal
    :open="open"
  >
    <div class="flex w-full flex-col space-y-4">
      <p class="font-bold text-z-gray-900">
        Tipo de entrega
      </p>
      <div class="space-y-2">
        <div
          v-for="shippingMethod in shippingMethods"
          :key="shippingMethod"
          class="relative flex space-x-3 text-z-gray-800"
        >
          <v-field
            v-model="selectedMethod"
            name="shippingMethod"
            type="radio"
            :value="shippingMethod"
            class="peer mt-0.5 h-5 w-5 shrink-0 appearance-none rounded-full border-2 border-z-turquoise-600 checked:bg-z-turquoise-600"
          />
          <img
            src="@/assets/icons/check.svg"
            class="pointer-events-none absolute -left-2.5 top-[5px] w-[15px] opacity-0 peer-checked:opacity-100"
          >
          <img
            src="@/assets/icons/check.svg"
            class="pointer-events-none absolute left-[-9px] top-[5px] w-[15px] opacity-0 peer-checked:opacity-100"
          >
          <p>{{ $t(`product.shippingMethod.clientAccount.${camelCase(shippingMethod)}`) }}</p>
        </div>
      </div>
      <base-tip
        v-if="selectedMethod === 'client_pickup'"
        title="Retiro por cliente"
        variant="bold"
      >
        Te entregaremos la dirección del socio una vez que haya aceptado tu solicitud.
      </base-tip>
      <div
        v-if="selectedMethod === 'partner_delivery' && !showNewAddressForm"
        class="space-y-4 rounded bg-z-turquoise-50 px-4 py-3"
      >
        <p class="font-medium text-z-gray-900">
          Dirección
        </p>
        <div class="flex max-h-48 flex-col space-y-4 overflow-y-scroll">
          <div
            v-for="addressOption in addressOptions"
            :key="addressOption.id"
            class="relative flex space-x-3 text-z-gray-800"
          >
            <v-field
              v-model="addressId"
              name="addressId"
              type="radio"
              :value="addressOption.id"
              class="peer mt-0.5 h-5 w-5 shrink-0 appearance-none rounded-full border-2 border-z-turquoise-600 checked:bg-z-turquoise-600"
            />
            <img
              src="@/assets/icons/check.svg"
              class="pointer-events-none absolute -left-2.5 top-[5px] w-[15px] opacity-0 peer-checked:opacity-100"
            >
            <img
              src="@/assets/icons/check.svg"
              class="pointer-events-none absolute left-[-9px] top-[5px] w-[15px] opacity-0 peer-checked:opacity-100"
            >
            <div class="space-y-1">
              <p
                v-if="addressOption.alias"
                class="w-fit rounded bg-z-turquoise-100 p-2 text-xs text-z-turquoise-900"
              >
                {{ addressOption.alias }}
              </p>
              <p class="line-clamp-1">
                {{ addressOption.name }}
              </p>
            </div>
          </div>
        </div>
        <button
          type="button"
          class="h-8 w-full rounded border border-z-turquoise-600 text-center text-sm text-z-turquoise-600"
          @click="toggleNewAddressForm"
        >
          Ingresar otra dirección
        </button>
      </div>
      <div
        v-if="showNewAddressForm"
        class="flex flex-col space-y-4"
      >
        <base-input
          v-model="alias"
          name="alias"
          placeholder="Casa, oficina, etc. (opcional)"
          label="Casa, oficina, etc. (opcional)"
        />
        <base-google-autocomplete
          v-model="place"
          name="place"
          placeholder="Ingresa una dirección"
          :error="errorEmpty"
        />
        <base-button
          class="w-full"
          variant="secondary"
          @click="createAddress"
        >
          Guardar dirección
        </base-button>
      </div>
      <base-button
        v-if="!showNewAddressForm"
        class="shrink-0"
        :disabled="!selectedMethod || (selectedMethod === 'partner_delivery' ? !addressId : false)"
        @click="handleConfirm"
      >
        Confirmar
      </base-button>
    </div>
  </base-modal>
</template>
