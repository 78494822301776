<script setup lang="ts">
import { isEmpty } from 'lodash';
import { computed, inject, onMounted, ref, watch } from 'vue';
import { array, string, object, number } from 'yup';
import { useStore } from '@/store/store';
import { addressesApi } from '@/api/addresses';
// import truckIconUrl from '@/assets/icons/truck.svg';
import mapPinIconUrl from '@/assets/icons/map-pin.svg';
import NewProductAddressModal from './new-product-address-modal.vue';

interface Props {
  summaryMode?: boolean
}

defineProps<Props>();

const emit = defineEmits<{
  (e: 'continue', value: { shippingMethodIds: string[], addressId: number }): void
}>();

const shippingMethods = [
  // { id: 'partner_delivery', name: 'Tú entregas en la ubicación del cliente', icon: truckIconUrl },
  { id: 'client_pickup', name: 'El cliente debe retirar en tu dirección', icon: mapPinIconUrl },
];

const store = useStore();

const user = inject<User>('user');

const addresses = ref<AddressData[]>();

const addressOptions = computed(() => addresses.value?.map((address) => (
  { id: address.id, name: address.displayName, alias: address.alias }
)));

const shippingMethodIds = ref<string[]>(store.productFormData?.shippingMethodIds || []);
const addressId = ref(store.productFormData?.addressId || undefined);

const showAddressModal = ref(false);
function toggleAddressModal() {
  showAddressModal.value = !showAddressModal.value;
}

const formRules = object().shape({
  shippingMethodIds: array().min(1).of(string().required())
    .required(),
  addressId: number().required().moreThan(0),
});

function handleContinue() {
  const values = {
    shippingMethodIds: shippingMethodIds.value ?? 0,
    addressId: addressId.value ?? 0,
  };
  emit('continue', values);
}

function getAddresses() {
  addressesApi.index(user?.id ?? 0).then((response) => {
    addresses.value = response.data.addresses;
  });
}

onMounted(() => {
  getAddresses();
});

watch(addresses, () => {
  if (addressId.value === undefined && addresses.value !== undefined) {
    addressId.value = addressOptions.value?.at(0)?.id;
  }
});

function handleNewAddress() {
  getAddresses();
  toggleAddressModal();
}

const showErrors = ref(false);
function handleInvalidSubmit() {
  showErrors.value = true;
}

function selectShippingMethod(shippingMethodId:string) {
  const idx = shippingMethodIds.value.findIndex((sm) => sm === shippingMethodId);

  if (idx === -1) {
    shippingMethodIds.value.push(shippingMethodId);
  } else {
    shippingMethodIds.value.splice(idx, 1);
  }
}
</script>
<template>
  <p class="text-center text-lg font-medium leading-6 text-z-gray-800">
    {{ $t('newProductForm.step2.description') }}
  </p>
  <v-form
    v-slot="{ handleSubmit, errors, meta }"
    :validation-schema="formRules"
    @invalid-submit="handleInvalidSubmit"
  >
    <div class="mb-16 flex flex-col space-y-6 pb-3 md:mb-0">
      <div
        v-if="!isEmpty(errors) && meta.touched && showErrors"
        class="flex h-12 items-center justify-center rounded-lg bg-z-cerise-50 px-4 py-2 text-center text-sm text-z-red"
      >
        Ingresa los datos para continuar
      </div>
      <div class="flex flex-col space-y-3.5">
        <div
          v-for="shippingMethod in shippingMethods"
          :key="shippingMethod.id"
          class="flex flex-col space-y-2 rounded-lg bg-z-turquoise-50 p-4"
          :class="{ 'border border-z-red': errors.shippingMethodIds && meta.touched && showErrors }"
        >
          <div class="flex items-center space-x-2">
            <div class="relative flex">
              <v-field
                v-model="shippingMethodIds"
                name="shippingMethodIds"
                type="checkbox"
                :value="shippingMethod.id"
                class="peer h-5 w-5 shrink-0 appearance-none rounded-[3px] border-2 border-z-turquoise-600 bg-none checked:bg-z-turquoise-600"
              />
              <img
                src="@/assets/icons/check.svg"
                class="pointer-events-none absolute left-0 top-0 opacity-0 peer-checked:opacity-100"
              >
            </div>
            <img :src="shippingMethod.icon">
            <button
              type="button"
              @click="selectShippingMethod(shippingMethod.id)"
            >
              {{ shippingMethod.name }}
            </button>
          </div>
        </div>
        <div
          class="flex flex-col space-y-2 rounded-lg bg-z-gray-50 p-4"
        >
          <div class="relative flex items-center space-x-2">
            <div class="h-5 w-5 shrink-0 rounded-[3px] border-2 border-z-gray-600" />
            <img src="@/assets/icons/truck.svg">
            <p>Tú entregas en la ubicación del cliente <span class="text-sm italic text-z-gray-700">(Próximamente)</span></p>
          </div>
        </div>
      </div>
      <!-- <base-tip>
        {{ $t('newProductForm.step2.tip') }}
      </base-tip> -->
      <div
        class="space-y-4 rounded bg-z-turquoise-50 px-4 py-3"
        :class="{ 'border border-z-red': !!errors.addressId && meta.touched && showErrors }"
      >
        <p class="font-medium text-z-gray-900">
          {{ $t('newProductForm.step2.addressTitle') }}
        </p>
        <div class="flex flex-col space-y-4">
          <div
            v-for="addressOption in addressOptions"
            :key="addressOption.id"
            class="relative flex space-x-3 text-z-gray-800"
          >
            <v-field
              v-model="addressId"
              name="addressId"
              type="radio"
              :value="addressOption.id"
              class="peer mt-0.5 h-5 w-5 shrink-0 appearance-none rounded-full border-2 border-z-turquoise-600 checked:bg-z-turquoise-600"
            />
            <img
              src="@/assets/icons/check.svg"
              class="pointer-events-none absolute -left-2.5 top-[5px] w-[15px] opacity-0 peer-checked:opacity-100"
            >
            <img
              src="@/assets/icons/check.svg"
              class="pointer-events-none absolute left-[-9px] top-[5px] w-[15px] opacity-0 peer-checked:opacity-100"
            >
            <div class="space-y-1">
              <p
                v-if="addressOption.alias"
                class="w-fit rounded bg-z-turquoise-100 p-2 text-xs text-z-turquoise-900"
              >
                {{ addressOption.alias }}
              </p>
              <p class="line-clamp-1">
                {{ addressOption.name }}
              </p>
            </div>
          </div>
        </div>
        <button
          type="button"
          class="h-8 w-full rounded border border-z-turquoise-600 text-center text-sm text-z-turquoise-600"
          @click="toggleAddressModal"
        >
          Ingresar nueva dirección
        </button>
      </div>
      <base-tip :title="$t('newProductForm.step2.addressTipTitle')">
        <template #icon>
          <img src="@/assets/icons/pin.svg">
        </template>
        {{ $t('newProductForm.step2.addressTip') }}
      </base-tip>
      <base-button
        class="gap-2"
        type="button"
        @click="handleSubmit($event, handleContinue)"
      >
        {{ summaryMode ? 'Ver resumen' : 'Continuar' }}
        <img src="@/assets/icons/arrow-small-right.svg">
      </base-button>
    </div>
  </v-form>
  <new-product-address-modal
    :is-open="showAddressModal"
    @close="toggleAddressModal"
    @success="handleNewAddress"
  />
</template>
