<script setup lang="ts">
import { ref } from 'vue';

interface Props {
  categories: Category[]
}

defineProps<Props>();

const showCategories = ref(false);

function toggleCategories() {
  showCategories.value = !showCategories.value;
}
</script>
<template>
  <the-layout
    hide-navbar
    :active-menu="0"
  >
    <the-titlebar
      title="Menu"
      :hide-back="!showCategories"
      @back="toggleCategories"
    />
    <div
      v-if="showCategories"
      class="flex w-full flex-col"
    >
      <a
        v-for="category in categories"
        :key="category.id"
        class="flex w-full items-center px-6 py-5"
        :href="`/categorias/${category.id}`"
      >
        {{ category.name }}
      </a>
    </div>
    <div
      v-else
      class="flex w-full flex-col"
    >
      <button
        class="flex w-full items-center justify-between px-6 py-5"
        @click="toggleCategories"
      >
        <div class="flex space-x-2">
          <img
            src="@/assets/icons/bag.svg"
            class="h-6"
          >
          <p class="text-z-gray-900">
            Categorías
          </p>
        </div>
        <img
          src="@/assets/icons/chevron-right.svg"
          class="h-5"
        >
      </button>
      <a
        class="flex w-full items-center space-x-2 px-6 py-5"
        href="/necesidades_clientes/crear"
      >
        <img
          src="@/assets/icons/task-search.svg"
          class="h-6"
        >
        <p class="text-z-gray-900">
          ¿No encuentras lo que buscas?
        </p>
      </a>
      <a
        class="flex w-full items-center space-x-2 px-6 py-5"
        href="/necesidades_clientes"
      >
        <img
          src="@/assets/icons/box.svg"
          class="h-6"
        >
        <p class="text-z-gray-900">
          Necesidades de nuestros clientes
        </p>
      </a>
    </div>
  </the-layout>
</template>
